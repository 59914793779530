import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/state';

//Component
import { GridContainer, GridItem } from '../../components';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

//Helper
import { getVerbiage, getName } from '../../utils/stringHelper';

import { User } from '../../models';

interface IProps {
  requireGuest?: 'all' | 'mainGuest' | 'none' | number;
  requireInput?: 'email' | 'mobileNo' | 'both' | 'none';
  onCompleted: (isCompleted: boolean) => void;
}

export const MarketingConsent: React.FC<IProps> = ({ requireGuest, requireInput, onCompleted }) => {
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedReservation } = useSelector((state: RootState) => state.reservationState);

  const generateUserMarketingConsent = (guest: User, allowDelete: boolean) => {
    const [checked, setChecked] = React.useState(false);
    const [mobileChecked, setMobileChecked] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
    };

    const handleMobileCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setMobileChecked(event.target.checked);
    };

    return (
      <GridItem xs={12} key={guest.id}>
        <GridContainer spacing={2} direction="row">
          <GridItem xs={12}>
            <Typography color="textPrimary" align="left" variant="subtitle1">
              <b>{guest != null ? getName(guest) : ''}</b>
            </Typography>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChange} name="check" color="primary" />}
              label={`${getVerbiage(verbiages, 'lbl024')}`}
            />
            <TextField fullWidth disabled={!checked} placeholder="johndoe@example.com" variant="outlined" />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox checked={mobileChecked} onChange={handleMobileCheckChange} name="check" color="primary" />
              }
              label={`${getVerbiage(verbiages, 'lbl025')}`}
            />
            <TextField fullWidth disabled={!mobileChecked} placeholder="+6588293920" variant="outlined" />
          </GridItem>
        </GridContainer>
      </GridItem>
    );
  };

  return (
    <GridContainer spacing={2} direction="column" style={{ minHeight: '60vh', padding: '18px' }}>
      <GridItem xs={12}>
        <Typography color="textPrimary" align="left" variant="body2">
          {`${getVerbiage(verbiages, 'lbl163')}`}
        </Typography>
      </GridItem>

      {selectedReservation?.guest != null ? generateUserMarketingConsent(selectedReservation.guest, false) : null}
      {selectedReservation?.sharedReservations != null && selectedReservation?.sharedReservations.length > 0
        ? selectedReservation?.sharedReservations.map((sharedReservation) => {
            return generateUserMarketingConsent(sharedReservation.guest, true);
          })
        : null}
    </GridContainer>
  );
};
