//Component
import { GridContainer, GridItem } from '../../components';
import Typography from '@material-ui/core/Typography';

//Icon

//Helper
import { defaultConfig } from 'themes';
import { Container } from '@material-ui/core';

export const Product = () => {
  return (
    <Container
      style={{
        minHeight: '100%',
        minWidth: '100%',
        backgroundPosition: 'center',
        overflow: 'hidden',
        left: 0,
        top: 0,
        position: 'absolute',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${defaultConfig.backgroundImage})`
      }}
    >
      <GridContainer
        direction="column"
        alignItems="center"
        justifyContent="space-evenly"
        style={{ minHeight: '80vh' }}
      >
        <GridItem xs={12}>
          <Typography color="textPrimary" align="center" variant="h3">
            {`Welcome to ARIA`}
          </Typography>
        </GridItem>
      </GridContainer>
    </Container>
  );
};
