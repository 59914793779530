import React from 'react';

// @material-ui/core components
import AppBar, { AppBarProps } from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { GridContainer, GridItem } from 'components';

//https://material-ui.com/components/app-bar/#fixed-placement

interface IProps extends AppBarProps {
  buttonTitle?: string | undefined;
  disableButton?: boolean | undefined;
  buttonOnClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const BottomButton: React.FC<IProps> = ({ buttonTitle, disableButton = false, buttonOnClick, ...rest }) => {
  return (
    <>
      <AppBar style={{ top: 'auto', bottom: 0 }} position="fixed" {...rest}>
        <Toolbar>
          <GridContainer justifyContent="center">
            <GridItem>
              <Button
                style={{ marginBottom: '12px', marginTop: '12px' }}
                disabled={disableButton}
                size="large"
                color="primary"
                variant="contained"
                onClick={buttonOnClick}
              >
                {buttonTitle}
              </Button>
            </GridItem>
          </GridContainer>
        </Toolbar>
      </AppBar>
    </>
  );
};
