import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from 'reducers';

//Component
import { GridContainer, GridItem } from '../../components';
import Typography from '@material-ui/core/Typography';
import AccountBalance from '@material-ui/icons/AccountBalance';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { green } from '@material-ui/core/colors';

//Helper
import { Box, CircularProgress, Fab } from '@material-ui/core';
// import { updatePaymentCompletion } from 'reducers/payment';
import { completedPaymentForSelectedReservation, reservationPreRegistered } from 'reducers/reservation';
import { getVerbiage } from 'utils/stringHelper';
import { constructPreRegisterReservationModel } from 'utils/applicationHelper';
import { updatePaymentCompletionAPI } from 'services/paymentService';
import { ARIA_RESERVATION_ALREADY_PREREGISTERED, ARIA_SuccessCode } from 'models';
import { preRegisterAPI } from 'services/reservationService';
import { PAYMENT_CODE_AUTHORISED } from 'models/backend/constant/Payment';
import { postErrorLog } from 'services/errorLogService';

interface IProps {
  onCompleted: (isCompleted: boolean) => void;
  onError: (isError: boolean) => void;
}

export const ProcessingPayment: React.FC<IProps> = ({ onCompleted, onError }) => {
  const dispatch = useDispatch();
  const [searchParams, _] = useSearchParams();

  // const { reservationState, paymentState } = useSelector((state: RootState) => state);
  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedReservation } = useSelector((state: RootState) => state.reservationState);
  const { paymentResponse } = useSelector((state: RootState) => state.paymentState);

  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const setErrorState = () => {
    setIsError(true);
    onError(true);
    setIsLoading(false);
  };

  React.useEffect(() => {
    const updateSecurePayment = async () => {
      console.log('updateSecurePayment');

      setIsError(false);
      var referenceNo = searchParams.get('ref');
      var redirectResult = searchParams.get('redirectResult');

      /////////////////////////////
      //PreAuth a 3Ds transaction
      /////////////////////////////
      if (referenceNo != null && redirectResult != null) {
        setIsLoading(true);

        //Processing 3Ds Payment
        updatePaymentCompletionAPI({
          captureTransaction: false,
          referenceNo: referenceNo,
          details: { RedirectResult: redirectResult }
        })
          .then((response) => {
            if (response.resultCode === ARIA_SuccessCode) {
              dispatch(completedPaymentForSelectedReservation());

              console.log('preRegister');
              var preRegistrationRequest = constructPreRegisterReservationModel(selectedReservation!!, appConfig!!);

              preRegisterAPI(preRegistrationRequest).then((response) => {
                if (
                  response.resultCode == ARIA_SuccessCode ||
                  response.resultCode == ARIA_RESERVATION_ALREADY_PREREGISTERED
                ) {
                  dispatch(reservationPreRegistered(response.result));
                } else {
                  setErrorState();
                }
              });
            } else {
              setErrorState();
            }
          })
          .catch((err) => {
            postErrorLog({
              confirmationNo: selectedReservation?.confirmationNo,
              errorMessage: JSON.stringify(err)
            });
            setErrorState();
          });

        /////////////////////////////
        //PreAuth a direct transaction
        /////////////////////////////
      } else if (paymentResponse != undefined && paymentResponse.code === PAYMENT_CODE_AUTHORISED) {
        setIsLoading(true);

        dispatch(completedPaymentForSelectedReservation());
        console.log('preRegister');
        var preRegistrationRequest = constructPreRegisterReservationModel(selectedReservation!!, appConfig!!);

        preRegisterAPI(preRegistrationRequest).then((response) => {
          if (
            response.resultCode == ARIA_SuccessCode ||
            response.resultCode == ARIA_RESERVATION_ALREADY_PREREGISTERED
          ) {
            dispatch(reservationPreRegistered(response.result));
          } else {
            setErrorState();
          }
        }).catch((err) => {
          postErrorLog({
            confirmationNo: selectedReservation?.confirmationNo,
            errorMessage: JSON.stringify(err)
          })
          setErrorState();
        });
      }
    };

    updateSecurePayment();
  }, []);

  React.useEffect(() => {
    console.log('preregisteredCompleted');

    const preregisteredCompleted = async () => {
      if (selectedReservation != undefined) {
        if (selectedReservation.isPreRegistered) {
          setIsLoading(false);
          onCompleted(true);
        }
      }
    };

    preregisteredCompleted();
  }, [selectedReservation]);

  return (
    <GridContainer
      spacing={3}
      alignContent="center"
      justifyContent="center"
      direction="row"
      style={{ paddingTop: '32px', paddingLeft: '18px', paddingRight: '18px', height: '50vh' }}
    >
      <GridItem>
        <Box sx={{ m: 1, position: 'relative' }}>
          {selectedReservation?.isPreRegistered ? (
            <Fab style={{ backgroundColor: green[500] }}>
              <DoneIcon
                style={{
                  color: 'white'
                }}
              />
            </Fab>
          ) : isError ? (
            <Fab color="secondary">
              <CancelIcon />
            </Fab>
          ) : (
            <Fab color="primary">
              <AccountBalance />
            </Fab>
          )}

          {isLoading && (
            <CircularProgress
              size={68}
              style={{
                color: green[500],
                position: 'absolute',
                top: -6,
                left: -6,
                zIndex: 1
              }}
            />
          )}
        </Box>
      </GridItem>

      {/* Wording */}
      {selectedReservation?.isPreRegistered ? (
        <GridItem xs={12}>
          <Typography align="center" variant="body1">
            {getVerbiage(verbiages, 'weblbl064')}
          </Typography>
        </GridItem>
      ) : isError ? (
        <GridItem xs={12}>
          <Typography align="center" variant="body1">
            {/* Sorry we are unable to process your payment. Please try again. */}
            {getVerbiage(verbiages, 'weblbl062')}
          </Typography>
        </GridItem>
      ) : (
        // : reservationServiceError != undefined ? (
        //   <GridItem xs={12}>
        //     <Typography align="center" variant="body1">
        //       {/* Please do not turn off the application while we are processing your payment. */}
        //       {getVerbiage(verbiages, 'weblbl063')}
        //     </Typography>
        //   </GridItem>
        // )
        <GridItem xs={12}>
          <Typography align="center" variant="body1">
            {/* Please do not turn off the application while we are processing your payment. */}
            {getVerbiage(verbiages, 'weblbl063')}
          </Typography>
        </GridItem>
      )}
    </GridContainer>
  );
};
