import { OCRDocumentRequest, OCRDocumentResponseModel } from "models";
import { getApiKeyForHotel, getToken, privateHttpClient } from "utils/httpClient";

export const orcIdentityDocumentAPI = async (
    request: OCRDocumentRequest
  ): Promise<OCRDocumentResponseModel> => {
    const { data } = await privateHttpClient.post(
      '/api/Identity/ReadDocument',
      {
        ...request
      },
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
          ApiKey: getApiKeyForHotel()
        }
      }
    );
      
    return data;
  };