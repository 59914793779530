import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/state';

//Component
import { CustomButton, DynamicTypography, GridContainer, GridItem } from '../../components';

//Helper
import { getVerbiage } from '../../utils/stringHelper';

import { checkInAPI, generateOTPPinAPI, verifyOTPPinAPI } from 'services/reservationService';
import { ARIA_SuccessCode, CheckInParameterModel } from 'models';
import { sendToMobileChannelActivateMobileKey } from 'events/messageChannel';
import { createDigitalKeyAPI } from 'services/digitalKeyService';
// import OtpInput from 'react-otp-input-rc-17';
import { Button, TextField } from '@material-ui/core';
import { mobileKeyIssued } from 'reducers/reservation';
import issuedKeyIcon from '../../images/recieved_keys.gif';
import { postErrorLog } from 'services/errorLogService';

interface IProps {
  verifyPin: Boolean;
}

export const RequestMobileKey: React.FC<IProps> = ({ verifyPin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedReservation, completedMobileKeyIssue } = useSelector((state: RootState) => state.reservationState);

  const [pin, setPin] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(false);
  const [hasRequestedForPin, setHasRequestedForPin] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener('message', (event) => {
      var messageFromMobileApp = JSON.parse(event.data);

      if (messageFromMobileApp.mobileKeyIssued) {
        dispatch(mobileKeyIssued());
      }
    });
    return () => window.removeEventListener('message', () => {});
  }, []);

  React.useEffect(() => {
    if (verifyPin && selectedReservation != null) {
      setIsLoading(true);
      if (selectedReservation != null) {
        setIsLoading(true);
        verifyOTPPinAPI({
          guid: selectedReservation.guid,
          pin: pin
        })
          .then((response) => {
            if (response.resultCode === ARIA_SuccessCode) {
              var mainRes: CheckInParameterModel = {
                primaryConfirmationNo: selectedReservation.confirmationNo,
                confirmationNo: selectedReservation.confirmationNo
              };

              var sharerReservations = selectedReservation.sharedReservations?.map((res) => {
                var shareReservation: CheckInParameterModel = {
                  primaryConfirmationNo: selectedReservation.confirmationNo,
                  confirmationNo: res.confirmationNo
                };

                return shareReservation;
              });

              if (sharerReservations == undefined) {
                sharerReservations = [];
              }

              sharerReservations?.unshift(mainRes);

              checkInAPI({
                reservations: sharerReservations
              }).then((response) => {
                if (response.resultCode === ARIA_SuccessCode) {
                  createDigitalKeyAPI({
                    GUID: selectedReservation.guid,
                    IsMaster: true
                  }).then((response) => {
                    if (response.resultCode === ARIA_SuccessCode) {
                      sendToMobileChannelActivateMobileKey(response.result.activationCode);
                      setIsLoading(false);
                    } else {
                      setIsLoading(false);
                      alert(getVerbiage(verbiages, 'webmsg014'));
                      // alert('Oposs... Something when wrong, please try again.');
                    }
                  });
                } else {
                  setIsLoading(false);
                  alert(getVerbiage(verbiages, 'webmsg014'));
                  // alert('Oposs... Something when wrong, please try again.');
                }
              });
            } else {
              setIsLoading(false);
              alert(getVerbiage(verbiages, 'webmsg014'));
              // alert('Oposs... Something when wrong, please try again.');
            }
          })
          .catch((err) => {
            setIsLoading(false);
            alert(getVerbiage(verbiages, 'webmsg014'));
            postErrorLog({
              confirmationNo: selectedReservation?.confirmationNo,
              errorMessage: JSON.stringify(err)
            })
          });
      }
    }
  }, [verifyPin]);

  const handleGenerateOTP = async () => {
    if (selectedReservation != undefined) {
      setIsLoading(true);

      generateOTPPinAPI({
        guid: selectedReservation.guid
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setHasRequestedForPin(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            alert(getVerbiage(verbiages, 'webmsg014'));
            // alert('Oposs... Something when wrong, please try again.');
          }
        })
        .catch((err) => {
          setIsLoading(false);
          alert(getVerbiage(verbiages, 'webmsg014'));
          postErrorLog({
            confirmationNo: selectedReservation?.confirmationNo,
            errorMessage: JSON.stringify(err)
          })
          // alert('Oposs... Something when wrong, please try again.');
        });
    }
  };

  return (
    <GridContainer spacing={3} direction="column" style={{ marginTop: '12px', marginBottom: '24px' }}>
      {/* After Successfully Issue a mobile key */}
      {completedMobileKeyIssue ? (
        <>
          <GridItem xs={12}>
            <DynamicTypography content={`${getVerbiage(verbiages, 'weblbl088')}`} />
          </GridItem>
          <GridItem xs={12} style={{ textAlign: 'center' }}>
            <img src={issuedKeyIcon} alt={issuedKeyIcon} style={{ maxWidth: '50%' }} />
          </GridItem>
          <GridItem xs={12} style={{ textAlign: 'center' }}>
            <CustomButton onClick={()=> {
              navigate(0);
            }}>{`${getVerbiage(verbiages, 'Back to Reservation Info')}`}</CustomButton>
          </GridItem>
        </>
      ) : (
        <>
          <GridItem xs={12}>
            <DynamicTypography content={`${getVerbiage(verbiages, 'weblbl087')}`} />
          </GridItem>

          <GridItem xs={12}>
            <GridContainer spacing={4} alignItems="center" direction="column">
              <GridItem xs={12}>
                <TextField
                  disabled={isLoading}
                  variant="outlined"
                  type={'password'}
                  inputProps={{ inputMode: 'numeric' }}
                  onChange={(e) => setPin(e.currentTarget.value)}
                ></TextField>
              </GridItem>
            </GridContainer>
          </GridItem>

          {!hasRequestedForPin ? (
            <GridItem xs={12} hidden={isLoading}>
              <Button variant="text" style={{ textDecoration: 'underline' }} onClick={handleGenerateOTP}>
                {`${getVerbiage(verbiages, 'webbtn016')}`}
              </Button>
            </GridItem>
          ) : (
            <GridItem xs={12} hidden={isLoading}>
              <DynamicTypography content={`${getVerbiage(verbiages, 'weblbl089')}`}></DynamicTypography>
              <Button variant="text" style={{ textDecoration: 'underline' }} onClick={handleGenerateOTP}>
                {`${getVerbiage(verbiages, 'webbtn017')}`}
              </Button>
            </GridItem>
          )}
        </>
      )}
    </GridContainer>
  );
};
