import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
// import { mockAppConfig } from 'mock/configuration';
import { ARIA_SuccessCode, ResponseModel } from 'models';
import { WebAppConfigResponseModel } from 'models/backend/ConfigurationModel';
import { getAppConfiguration } from 'services/configurationService';
import { RootState } from '../store/state';
// import { defaultTheme } from '../../themes/index'

const initialState: RootState.AppConfigurationState = {
  appConfig: undefined,
  isLoading: false,
  error: undefined
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const getApplicationConfig = createAsyncThunk<
  WebAppConfigResponseModel,
  void,
  { rejectValue: AxiosError<Error> }
>('configuration/getAppConfig', async (input, thunkApi) => {
  try {

    let data: WebAppConfigResponseModel = await getAppConfiguration();
    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const configurationSlice = createSlice({
  name: 'configuration',
  initialState: initialState,
  reducers: {
    //local reducer
    changeTheme(state, action) {
      state.appConfig = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getApplicationConfig.fulfilled, (state, { payload }) => {
      if(payload.resultCode === ARIA_SuccessCode) {
        state.appConfig = payload.result;

        //FOR TESTING ONLY. REMOVE THIS LINE AFTER TESTING
        // state.appConfig = mockAppConfig;
        //FOR TESTING ONLY. REMOVE THIS LINE AFTER TESTING

        state.error = undefined;
      } else {
        const serverError: ResponseModel = {
          resultCode: payload.resultCode,
          resultDescription: payload.resultDescription
        };
        state.appConfig = undefined;
        state.error = serverError;
      }
  
      state.isLoading = false;
    }),
      builder.addCase(getApplicationConfig.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(getApplicationConfig.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? '',
            resultDescription: error?.response?.data.message ?? ''
          };
          
          state.appConfig = undefined;
          state.error = serverError;
          state.isLoading = false;
        }
      });

  }
});

export const {} = configurationSlice.actions;

export default configurationSlice.reducer;
