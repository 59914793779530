import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/state';

//Component
import { GridContainer, GridItem } from '../../components';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import AssignmentIcon from '@material-ui/icons/Assignment';
import EventIcon from '@material-ui/icons/Event';
import KingBedIcon from '@material-ui/icons/KingBed';
import RoomIcon from '@material-ui/icons/Room';

//Helper
import { getVerbiage } from '../../utils/stringHelper';
import { GuestCardList } from 'components/GuestCardList';
import { getTime, getShortDate } from 'utils/dateHelper';
import { sendToMobileMessageChannelCurrentReservation } from 'events/messageChannel';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

interface IProps {}

export const ReservationDetail: React.FC<IProps> = () => {
  const classes = useStyles();

  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { selectedReservation } = useSelector((state: RootState) => state.reservationState);
  const { verbiages } = useSelector((state: RootState) => state.verbiages);

  React.useEffect(() => {
    if (selectedReservation !== undefined) {
      sendToMobileMessageChannelCurrentReservation(selectedReservation.guid);
    }
  }, [selectedReservation]);

  return (
    <>
      <GridContainer
        spacing={4}
        direction="column"
        alignItems="center"
        alignContent="center"
        justifyContent="flex-start"
        style={{ marginTop: '18px', marginBottom: '18px' }}
      >
        <GridItem>
          <Card elevation={3}>
            <CardContent>
              <GridContainer spacing={1}>
                <GridItem xs={12}>
                  <Typography variant="subtitle1" color="textSecondary" className={classes.subtitle}>
                    <AssignmentIcon />
                    {getVerbiage(verbiages, 'weblbl003')}
                  </Typography>
                  <Typography variant="body1">{selectedReservation!.confirmationNo}</Typography>
                </GridItem>
                <GridItem xs={12}></GridItem>
                <GridItem xs={6}>
                  <Typography variant="subtitle1" color="textSecondary" className={classes.subtitle}>
                    <EventIcon />
                    {getVerbiage(verbiages, 'weblbl006')}
                  </Typography>
                  <Typography variant="body1">{getShortDate(selectedReservation!.arrivalDateTime)}</Typography>
                  <Typography variant="body1">{`${getTime(selectedReservation!.arrivalDateTime)} (${
                    selectedReservation!.timezone
                  })`}</Typography>
                </GridItem>
                <GridItem xs={6}>
                  <Typography variant="subtitle1" color="textSecondary" className={classes.subtitle}>
                    <EventIcon />
                    {getVerbiage(verbiages, 'weblbl007')}
                  </Typography>
                  <Typography variant="body1">{getShortDate(selectedReservation!.departureDateTime)}</Typography>
                  <Typography variant="body1">{`${getTime(selectedReservation!.departureDateTime)} (${
                    selectedReservation!.timezone
                  })`}</Typography>
                </GridItem>
                <GridItem xs={12}>
                  <Divider />
                </GridItem>
                <GridItem xs={6}>
                  <Typography variant="subtitle1" color="textSecondary" className={classes.subtitle}>
                    <KingBedIcon />
                    {getVerbiage(verbiages, 'weblbl008')}
                  </Typography>
                  <Typography variant="body1">{selectedReservation!.room.description}</Typography>
                </GridItem>
                {appConfig?.reservationConfig.displayRoomNo ? (
                  <GridItem xs={6}>
                    <Typography variant="subtitle1" color="textSecondary" className={classes.subtitle}>
                      <RoomIcon />
                      {getVerbiage(verbiages, 'weblbl009')}
                    </Typography>
                    <Typography variant="body1">
                      {selectedReservation!.room.number != ''
                        ? selectedReservation!.room.number
                        : getVerbiage(verbiages, 'weblbl010')}
                    </Typography>
                  </GridItem>
                ) : null}
              </GridContainer>
            </CardContent>
          </Card>
        </GridItem>

        {/* guest */}
        <GridItem>
          <GuestCardList />
        </GridItem>
      </GridContainer>
    </>
  );
};
