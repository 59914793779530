import { getApiKeyForHotel, getToken, privateHttpClient } from '../utils/httpClient';

export const getLanguageList = async (languageSettingId: number) => {
  const { data } = await privateHttpClient.get(`/api/languagesetting/${languageSettingId}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      ApiKey: getApiKeyForHotel()
    }
  });

  return data;
};
