//more info can be found here https://www.npmjs.com/package/react-pdf

import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ReactSketchCanvas, CanvasPath, ReactSketchCanvasRef } from 'react-sketch-canvas';
import { Document, Page, pdfjs, DocumentProps } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';

import { PDFDocument } from 'pdf-lib';

import Grid from '@material-ui/core/Grid';
import { GridItem, PdfControlPanel } from '..';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = {
  // border: '0.0625rem solid #3E51B5',
  // borderRadius: '0.25rem'
};

const nonSignatureStyle = {
  zIndex: 0,
  borderColor: 'grey',
  borderStyle: 'solid'
};

const signatureStyle = {
  zIndex: 0,
  borderColor: '#3E51B5',
  borderStyle: 'solid'
};

interface IProps extends DocumentProps {
  filename?: string | undefined;
  fileInBase64?: string | undefined;
}

export const PDFScribble: React.FC<IProps> = ({ filename = 'file.pdf', file, fileInBase64, ...rest }) => {
  const [scale, setScale] = useState(0.5);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [sketchPaths, setSketchPaths] = useState<CanvasPath[][]>(new Array<CanvasPath[]>());

  const [isSignature, setIsSignature] = useState(false);
  const [isHeightChange, setIsHeightChange] = useState(true);

  const [height, setHeight] = useState<string | undefined>(undefined);
  const [width, setWidth] = useState<string | undefined>(undefined);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const [fileSource] = useState(file ? file : `data:application/pdf;base64,${fileInBase64}`);
  const [downloadFileSource, setDownloadFileSource] = useState(file ? file : `data:application/pdf;base64,${fileInBase64}`);

  const canvasRef = React.useRef<ReactSketchCanvasRef>(null);
  const documentRef = React.createRef<HTMLDivElement>();

  const debounced = useDebouncedCallback(
    // function
    async (data) => {
      if (canvasRef.current?.exportImage != null) {
        const _exportImage = canvasRef.current.exportImage;
        const canvasData = await _exportImage('png');

        const oriPdfDoc = await PDFDocument.load(fileSource);

        const pdfDoc = await PDFDocument.load(downloadFileSource);

        const imageBase64 = canvasData.replace(/^data:image\/(png|jpg);base64,/, '');
        const pngImage = await pdfDoc.embedPng(imageBase64);
        pdfDoc.removePage(pageNumber - 1);
        const [existingPage] = await pdfDoc.copyPages(oriPdfDoc, [pageNumber - 1]);
        pdfDoc.insertPage(pageNumber - 1, existingPage);

        const pages = pdfDoc.getPages();
        const editPage = pages[pageNumber - 1];

        editPage.drawImage(pngImage, {
          x: 0,
          y: 0,
          width: editPage.getWidth(),
          height: editPage.getHeight()
        });
        console.log('save');
        const base64String = await pdfDoc.saveAsBase64();
        setDownloadFileSource(`data:application/pdf;base64,${base64String}`);
      }
    },
    // delay in ms
    500
  );

  React.useLayoutEffect(() => {
    setTimeout(() => {
      if (documentRef.current != null) {
        const _docRef = documentRef.current;
        const _docBoundingRect = _docRef.getBoundingClientRect();

        if (isHeightChange) {
          setTop(_docRef.offsetTop);
          setLeft(_docRef.offsetLeft);
          console.log(_docBoundingRect.height.toFixed(0).toString());
          console.log(_docRef.offsetHeight);
          setHeight(`${_docBoundingRect.height.toString()}px`);
          setWidth(`${_docBoundingRect.width.toString()}px`);

          setIsHeightChange(false);

          // console.log(a.top)
        }
      }
    }, 30);
  });

  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    const { numPages } = pdf;
    setNumPages(numPages);
  };

  async function onPageChange(newPageNumber: number) {
    if (canvasRef.current) {
      const _canvas = canvasRef.current;
      const currentPage = newPageNumber - 1;
      const previuosPage = pageNumber - 1;
      //Process canvas
      //1. save the paths
      //2. save the image into the PDF file
      const previousCanvasPaths: CanvasPath[] = await _canvas.exportPaths();
      // var previousScreenCanvasImageData : string | undefined = undefined;
      // if (previousCanvasPaths.length > 0) {
      //   previousScreenCanvasImageData = await _canvas.exportImage('png');
      // }
      _canvas.resetCanvas();
      const currentCanvasPaths: CanvasPath[] = sketchPaths[currentPage];
      if (currentCanvasPaths) {
        _canvas.loadPaths(currentCanvasPaths);
      }
      sketchPaths.splice(previuosPage, 1, previousCanvasPaths);
      setSketchPaths(sketchPaths);
    }
  }

  function onPageZoom(scaleNumber: number) {
    setIsHeightChange(true);

    setScale(scaleNumber);
  }

  return (
    <>
      <>
        <Grid container direction="column" alignItems="center" justifyContent="center" alignContent="center">
          <GridItem xs={12}>
            <PdfControlPanel
              scale={scale}
              iconSize="small"
              setScale={setScale}
              numPages={numPages}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              file={downloadFileSource}
              filename={filename}
              onZoom={onPageZoom}
              onNextPage={onPageChange}
              onClear={() => {
                if (canvasRef.current) canvasRef.current.clearCanvas();
              }}
              onRedo={() => {
                if (canvasRef.current) canvasRef.current.redo();
              }}
              onUndo={() => {
                if (canvasRef.current) canvasRef.current.undo();
              }}
              scribbleOn={isSignature}
              setIsScribble={() => setIsSignature(!isSignature)}
            />
          </GridItem>
          <GridItem style={isSignature ? signatureStyle : nonSignatureStyle}>
            <Document file={fileSource} onLoadSuccess={onDocumentLoadSuccess}>
              <Page inputRef={documentRef} renderMode="svg" pageNumber={pageNumber} scale={scale} />
            </Document>
          </GridItem>
          <GridItem hidden={!isSignature} style={{ zIndex: 1, position: 'absolute', top: top, left: left }}>
            <ReactSketchCanvas
              style={styles}
              ref={canvasRef}
              height={height}
              width={width}
              canvasColor="transparent"
              strokeWidth={3}
              strokeColor="black"
              onChange={(data) => debounced(data)}
              withTimestamp
            />
          </GridItem>
          {/* <GridItem xs={12}>
            <PdfControlPanel
              scale={scale}
              iconSize='small'
              setScale={setScale}
              numPages={numPages}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              file={downloadFileSource}
              filename={filename}
              onZoom={onPageZoom}
              onNextPage={onPageChange}
              onClear={() => {
                if (canvasRef.current) canvasRef.current.clearCanvas();
              }}
              onRedo={() => {
                if (canvasRef.current) canvasRef.current.redo();
              }}
              onUndo={() => {
                if (canvasRef.current) canvasRef.current.undo();
              }}
              scribbleOn={isSignature}
              setIsScribble={() => setIsSignature(!isSignature)}
            />
          </GridItem> */}
        </Grid>
      </>
    </>
  );
};
