import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/state';


// @material-ui/core components
import { GridContainer, GridItem } from '..';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

//Helper
import { getVerbiage } from '../../utils/stringHelper';
import { Reservation } from '../../models';
import { getShortDate } from 'utils/dateHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1)
    },
    media: {
      height: '140px',
      margin: '12px'
    },
    container: {
      minWidth: '280px'
    }
  })
);

interface IProps {
  onClick? : React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const ReservationCardList: React.FC<IProps> = ({onClick}) => {
  const classes = useStyles();
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { reservations } = useSelector((state: RootState) => state.reservationState);

  return (
    <>
      {reservations.map((reservation: Reservation, index: number) => {
        return (
          <Card raised key={index} className={classes.root}>
            <CardActionArea value={index} onClick={onClick}>
              <CardMedia className={classes.media} image={reservation.room.photo} title={reservation.room.description} />
              <CardContent>
                <GridContainer className={classes.container}>
                  <GridItem xs={12}>
                    <Typography gutterBottom variant="h5" color="textPrimary">
                      <b>{reservation.room.description}</b>
                    </Typography>
                    <Typography variant="body2" color="textPrimary" component="p">
                      {`${getVerbiage(verbiages, 'weblbl006')}: ${getShortDate(reservation.arrivalDateTime)}`}
                    </Typography>
                    <Typography variant="body2" color="textPrimary" component="p">
                      {`${getVerbiage(verbiages, 'weblbl007')}: ${getShortDate(reservation.departureDateTime)}`}
                    </Typography>
                  </GridItem>
                  <GridItem xs={9}>
                    <Typography variant="body2" color="textPrimary" component="p">
                      {`${getVerbiage(verbiages, 'weblbl003')}: ${reservation.confirmationNo}`}
                    </Typography>
                  </GridItem>
                  <GridItem xs={3}>
                    <Typography variant="body2" color="textPrimary" align={'right'}>
                      {`${getVerbiage(verbiages, 'webbtn001')}`}
                    </Typography>
                  </GridItem>
                </GridContainer>
              </CardContent>
            </CardActionArea>
          </Card>
        );
      })}
    </>
  );
};
