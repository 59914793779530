import {
  CheckInRequestModel,
  CheckInResponseModel,
  CheckOutReservationRequestModel,
  CheckOutReservationResponseModel,
  GenerateOTPRequestModel,
  GenerateOTPResponseModel,
  GetPreCheckOutBillRequestModel,
  GetReservationRequestModel,
  PreRegistrationRequestModel,
  PreRegistrationResponseModel,
  ReservationResponseModel,
  SignAcknowledgementRequestModel,
  SubscribeRoomRequestModel,
  SubscribeRoomResponseModel,
  VerifyOTPRequestModel,
  VerifyOTPResponseModel,
  VerifyReservationRequestModel
} from 'models';
import { getApiKeyForHotel, getToken, privateHttpClient } from '../utils/httpClient';

export const getReservationAPI = async (request: GetReservationRequestModel): Promise<ReservationResponseModel> => {
  const { data } = await privateHttpClient.get('/api/reservation', {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      ApiKey: getApiKeyForHotel()
    },
    params: {
      ...request
    }
  });

  return data;
};

export const getReservationByEmailAPI = async (request: GetReservationRequestModel) => {
  const { data } = await privateHttpClient.get('/api/reservation', {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      ApiKey: getApiKeyForHotel()
    },
    params: {
      ...request
    }
  });

  return data;
};

export const getReservationByGuidEAPI = async (guid: string): Promise<ReservationResponseModel> => {
  const { data } = await privateHttpClient.get('/api/reservation', {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      ApiKey: getApiKeyForHotel()
    },
    params: {
      Guid: guid
    }
  });

  return data;
};

export const getCheckOutReservationByConfirmationIdAPI = async (
  roomNo: string,
  lastName: string
): Promise<ReservationResponseModel> => {
  const { data } = await privateHttpClient.get('/api/reservation', {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      ApiKey: getApiKeyForHotel()
    },
    params: {
      RoomNo: roomNo,
      LastName: lastName,
      IsCheckOut: true,
      IsSaving: true
    }
  });

  return data;
};

export const verifyReservationAPI = async (request: VerifyReservationRequestModel) => {
  const { data } = await privateHttpClient.put(
    '/api/reservation/verify',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const combineSharerAPI = async (mainConfirmationNo: string, shareProfileId: string) => {
  const { data } = await privateHttpClient.post(
    '/api/reservation/combineshare',
    {
      mainConfirmationNo: mainConfirmationNo,
      shareProfileId: shareProfileId
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const breakSharer = async (mainConfirmationNo: string, shareConfirmationNo: string) => {
  const { data } = await privateHttpClient.post(
    '/api/reservation/breakshare',
    {
      mainConfirmationNo: mainConfirmationNo,
      shareConfirmationNo: shareConfirmationNo
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const updateSignAcknowledgementAPI = async (request: SignAcknowledgementRequestModel) => {
  const { data } = await privateHttpClient.put(
    '/api/reservation/signedacknowledgement',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const preRegisterAPI = async (request: PreRegistrationRequestModel): Promise<PreRegistrationResponseModel> => {
  const { data } = await privateHttpClient.put(
    '/api/reservation/preregister',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const subscribeRoomAPI = async (request: SubscribeRoomRequestModel): Promise<SubscribeRoomResponseModel> => {
  const { data } = await privateHttpClient.put(
    '/api/reservation/subscribeRoom',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const checkInAPI = async (request: CheckInRequestModel): Promise<CheckInResponseModel> => {
  const { data } = await privateHttpClient.put(
    '/api/reservation/checkin',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const getPreCheckOutBillAPI = async (request: GetPreCheckOutBillRequestModel) => {
  const { data } = await privateHttpClient.get('/api/reservation/getprecheckoutbill', {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      ApiKey: getApiKeyForHotel()
    },
    params: {
      ...request
    }
  });

  return data;
};

export const checkOutReservationAPI = async (
  request: CheckOutReservationRequestModel
): Promise<CheckOutReservationResponseModel> => {
  const { data } = await privateHttpClient.put(
    '/api/reservation/CheckOut',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const generateOTPPinAPI = async (
  request: GenerateOTPRequestModel
): Promise<GenerateOTPResponseModel> => {
  const { data } = await privateHttpClient.post(
    '/api/reservation/GenerateOTP',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const verifyOTPPinAPI = async (
  request: VerifyOTPRequestModel
): Promise<VerifyOTPResponseModel> => {
  const { data } = await privateHttpClient.post(
    '/api/reservation/VerifyOTP',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};
