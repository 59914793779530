import { Country, CreateGuestRequestModel, Hotel, ScanDocument, UpdateGuestRequestModel } from 'models';
import { getCountryIdByCode3 } from 'utils/countryHelper';
import { convertStringDateToServerFormat } from 'utils/dateHelper';
import { getFirstNameFromFullname, getLastNameFromFullname } from 'utils/stringHelper';
import { GenderList } from '../constant/Gender';

export function convertToCreateGuestRequestModel(
  documentInfo: ScanDocument,
  _hotel: Hotel,
  _countryList: Country[]
): CreateGuestRequestModel {
  var docImage: string[] = [];
  var _firstName = '';
  var _lastname = '';

  if (documentInfo.Images) {
    docImage = documentInfo.Images;
  }

  if ((documentInfo.FirstName == null || documentInfo.FirstName == undefined || documentInfo.FirstName == "") && documentInfo.LastName) {
    _firstName = getFirstNameFromFullname(documentInfo.LastName);
    _lastname = getLastNameFromFullname(documentInfo.LastName);
  } else {
    if (documentInfo.FirstName != undefined) {
      _firstName = documentInfo.FirstName;
    }
    if (documentInfo.LastName != undefined) {
      _lastname = documentInfo.LastName;
    }
  }

  const guestInfo: CreateGuestRequestModel = {
    firstName: _firstName,
    lastName: _lastname,
    gender: documentInfo.Gender != undefined ? documentInfo.Gender : GenderList[0].value,
    dateOfBirth:
      documentInfo.BirthDate != undefined ? convertStringDateToServerFormat(documentInfo.BirthDate) : undefined,
    documentNo: documentInfo.DocumentNumber ?? '',
    documentType: documentInfo.IDType ?? '',
    documentPhotos: docImage,
    documentExpiryDate:
      documentInfo.ExpirationDate != undefined
        ? convertStringDateToServerFormat(documentInfo.ExpirationDate)
        : undefined,
    // documentIssuedCountryId: getCountryIdByName(documentInfo.issueCountry, _countryList),
    documentIssuedCountryId: getCountryIdByCode3(documentInfo.IssueCountry, _countryList),
    facePhoto: documentInfo.PortraitImage,
    nationality: documentInfo.NationalityLong == null ? undefined : documentInfo.NationalityLong
  };
  return guestInfo;
}

export function convertToUpdateGuestRequestModel(
  documentInfo: ScanDocument,
  _hotel: Hotel,
  _profileId: string,
  _countryList: Country[]
): UpdateGuestRequestModel {
  var docImage;
  var _firstName = '';
  var _lastname = '';

  if (documentInfo.Images) {
    docImage = documentInfo.Images;
  }

  if ((documentInfo.FirstName == null || documentInfo.FirstName == undefined || documentInfo.FirstName == "") && documentInfo.LastName) {
    _firstName = getFirstNameFromFullname(documentInfo.LastName);
    _lastname = getLastNameFromFullname(documentInfo.LastName);
  } else {
    if (documentInfo.FirstName != undefined) {
      _firstName = documentInfo.FirstName;
    }
    if (documentInfo.LastName != undefined) {
      _lastname = documentInfo.LastName;
    }
  }

  const guestInfo: UpdateGuestRequestModel = {
    profileId: _profileId,
    firstName: _firstName,
    lastName: _lastname,
    gender: documentInfo.Gender != undefined ? documentInfo.Gender : GenderList[0].value,
    dateOfBirth:
      documentInfo.BirthDate != undefined
        ? new Date(convertStringDateToServerFormat(documentInfo.BirthDate))
        : undefined,
    documentNo: documentInfo.DocumentNumber ?? '',
    documentType: documentInfo.IDType ?? '',
    documentPhotos: docImage,
    documentExpiryDate:
      documentInfo.ExpirationDate != undefined
        ? convertStringDateToServerFormat(documentInfo.ExpirationDate)
        : undefined,
    // documentIssuedCountryId: getCountryIdByName(documentInfo.issueCountry, _countryList),
    documentIssuedCountryId: getCountryIdByCode3(documentInfo.IssueCountry, _countryList),
    facePhoto: documentInfo.PortraitImage,
    nationality: documentInfo.NationalityLong == null ? undefined : documentInfo.NationalityLong
  };
  return guestInfo;
}
