import React from 'react';
// @material-ui/core components
import Container, { ContainerProps } from '@material-ui/core/Container';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { fetchLanguageList } from 'reducers/language';
import { fetchCountryList } from 'reducers/country';
import { authenticatUser } from 'reducers/account';
import { fetchHotelByOrigin } from 'reducers/hotel';
import { fetchVerbiageList } from 'reducers/verbiages';
import { CircularProgress } from '@material-ui/core';
import { GridContainer, GridItem } from 'components';
import { getApplicationConfig } from 'reducers/configuration';

export enum MainContainerBackDropType {
  light = 'light',
  dark = 'dark'
}

interface IProps extends ContainerProps {
  mode?: MainContainerBackDropType;
  backgroundImage?: string;
}

export const MainContainer: React.FC<IProps> = ({ children, mode, backgroundImage, ...rest }) => {
  backgroundImage = backgroundImage != null ? backgroundImage : '';
  
  const params = useParams();

  const dispatch = useDispatch();

  const { languages, selectedLanguage } = useSelector((state: RootState) => state.languageState);
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { token } = useSelector((state: RootState) => state.account);
  const { countryList } = useSelector((state: RootState) => state.country);

  React.useEffect(() => {
    const authenticate = async () => {
      if (token == undefined) {
        dispatch(authenticatUser());
      } else if (selectedHotel != undefined && selectedHotel.code != params.hotelCode) {
        dispatch(authenticatUser());
      }
    };

    authenticate();
  }, []);

  React.useEffect(() => {
    const fetchLanguaes = async () => {
      if (selectedHotel !== undefined && selectedHotel.id != 0 && languages.length === 0) {
        dispatch(getApplicationConfig());
        dispatch(fetchLanguageList({ languageSettingId: selectedHotel.languageSettingId }));
      }
    };

    fetchLanguaes();
  }, [selectedHotel]);

  React.useEffect(() => {
    const fetchHotel = async () => {
      if (token !== null) {
        countryList.length === 0 && dispatch(fetchCountryList());
        selectedHotel == undefined && dispatch(fetchHotelByOrigin());
      }
    };

    fetchHotel();
  }, [token]);

  React.useEffect(() => {
    const fetchVerbiage = async () => {
      if (
        selectedHotel != undefined &&
        selectedLanguage != undefined &&
        selectedLanguage.code != null &&
        verbiages.length == 0
      ) {
        dispatch(fetchVerbiageList({ request: { languageId: selectedLanguage.id } }));
      }
    };
    fetchVerbiage();
  }, [selectedHotel, selectedLanguage]);

  return (
    <Container
      style={{
        minHeight: '100%',
        minWidth: '100%',
        backgroundPosition: 'center',
        overflow: 'hidden',
        left: 0,
        top: 0,
        position: 'absolute',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundImage})`
      }}
      {...rest}
    >
      {selectedHotel == undefined || appConfig == undefined || verbiages.length <= 0 ? (
        <GridContainer
          direction="row"
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <GridItem>
            <CircularProgress />
          </GridItem>
        </GridContainer>
      ) : (
        children
      )}
    </Container>
  );
};
