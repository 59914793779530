import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/state';

//Component
import { DynamicTypography, GridContainer, GridItem } from '../../components';
import Typography from '@material-ui/core/Typography';

import AdyenCheckout from '@adyen/adyen-web';
import Core from '@adyen/adyen-web/dist/types/core';
import '@adyen/adyen-web/dist/adyen.css';

//Helper
import { getVerbiage, convertNumberToAmount } from '../../utils/stringHelper';
import { PAYMENT_CODE_AUTHORISED } from 'models/backend/constant/Payment';
import { CircularProgress } from '@material-ui/core';
import { getAdyenConfig } from 'utils/applicationHelper';
import { createCheckoutPaymentSessionAPI, createPreAuthPaymentAPI } from 'services/paymentService';
import { ARIA_SuccessCode } from 'models';
import { resetPaymentState, updateCheckoutSession, updatePaymentResponse } from 'reducers/payment';
import { postErrorLog } from 'services/errorLogService';

interface IProps {
  submitPay: Boolean;
  onValidationCompleted: (isCompleted: boolean) => void;
  onResetPayment: () => void;
  onPaymentCompleted: () => void;
}

export const SummaryCharges: React.FC<IProps> = ({
  submitPay,
  onResetPayment,
  onValidationCompleted,
  onPaymentCompleted
}) => {
  const dispatch = useDispatch();

  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { selectedReservation } = useSelector((state: RootState) => state.reservationState);
  const { checkoutSession, paymentResponse } = useSelector((state: RootState) => state.paymentState);

  const paymentContainer = useRef(null);
  const redirectContainer = useRef(null);

  const [data, setData] = React.useState<any | null>(null);
  const [adyenCheckout, setAdyenCheckout] = React.useState<Core | null>(null);

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const createCheckout = async () => {
      if (appConfig != undefined) {
        console.log('createCheckout');

        var config = getAdyenConfig(appConfig);

        //Create Session 1st then create the checkout component
        if (selectedHotel != undefined && selectedReservation != undefined && checkoutSession == undefined) {
          setIsLoading(true);
          createCheckoutPaymentSessionAPI({
            confirmationNo: selectedReservation.confirmationNo
          })
            .then((response) => {
              if (response.resultCode === ARIA_SuccessCode) {
                dispatch(updateCheckoutSession(response.result));
              } else {
                alert(getVerbiage(verbiages, 'webmsg014'));
                // alert('Oposs... Something when wrong, please try again.');
              }

              setIsLoading(false);
            })
            .catch((err) => {
              alert(getVerbiage(verbiages, 'webmsg014'));
              // alert('Oposs... Something when wrong, please try again.');
              setIsLoading(false);
              postErrorLog({
                confirmationNo: selectedReservation?.confirmationNo,
                errorMessage: JSON.stringify(err)
              })
            });

          // dispatch(
          //   createCheckoutPaymentSession({
          //     request: { hotelId: selectedHotel.id, confirmationNo: selectedReservation.confirmationNo }
          //   })
          // );
        } else if (
          selectedHotel != undefined &&
          selectedReservation != undefined &&
          checkoutSession != undefined &&
          paymentContainer != null
        ) {
          const checkout = await AdyenCheckout({
            ...config,
            session: checkoutSession,
            onPaymentCompleted: (response: any, _component: any) => {
              console.log('onPaymentCompleted');
              console.log(response);
            },
            onError: (error: any, _component: any) => {
              console.log('onError');
              console.error(error);
              postErrorLog({
                confirmationNo: selectedReservation?.confirmationNo,
                errorMessage: JSON.stringify(error)
              })
            },
            onChange: (state: any, component: any) => {
              if (state.isValid) {
                setData(state.data);
                onValidationCompleted(true);
              } else {
                onValidationCompleted(false);
              }
            },
            onAdditionalDetails: (state: any, component: any) => {
              console.log('onAdditionalDetails');
              console.log(state);
            },
            //return when submit button is triggered
            onSubmit: async (state: any, component: any) => {
              setIsLoading(true);

              createPreAuthPaymentAPI({
                ...state,
                confirmationNo: selectedReservation?.confirmationNo
              })
                .then((response) => {
                  if (response.resultCode == ARIA_SuccessCode) {
                    console.log('paymentResponse');
                    dispatch(updatePaymentResponse(response.result));
                  } else {
                    setIsLoading(false);
                    alert(getVerbiage(verbiages, 'webmsg014'));
                    // alert('Oposs... Something when wrong, please try again.');
                  }
                })
                .catch((err) => {
                  setIsLoading(false);
                  alert(getVerbiage(verbiages, 'webmsg014'));
                  postErrorLog({
                    confirmationNo: selectedReservation?.confirmationNo,
                    errorMessage: JSON.stringify(err)
                  })
                  // alert('Oposs... Something when wrong, please try again.');
                });
            }
          });

          if (paymentContainer.current) {
            setAdyenCheckout(checkout);
            checkout.create('card').mount(paymentContainer.current);
          }
        }
      }
    };

    createCheckout();
  }, [checkoutSession, paymentContainer]);

  React.useEffect(() => {
    if (adyenCheckout != null && submitPay) {
      console.log('adyenCheckout');
      adyenCheckout.submitPayment(data);
    }
  }, [submitPay]);

  React.useEffect(() => {
    if (paymentResponse != undefined) {
      console.log('paymentResponse');
      if (paymentResponse.code === PAYMENT_CODE_AUTHORISED) {
        // completed payment
        onPaymentCompleted();
      } else if (paymentResponse.action != null) {
        console.log(paymentResponse.action);

        if (
          adyenCheckout != null &&
          redirectContainer != null &&
          paymentResponse.action.method.toLocaleUpperCase() === 'POST'
        ) {
          adyenCheckout.createFromAction(paymentResponse.action).mount('#redirect-container');
        }
      } else {
        //Invalid card, refusal card.
        alert(getVerbiage(verbiages, 'webmsg013'));
        onResetPayment();
        onValidationCompleted(false);
        dispatch(resetPaymentState());
      }
    }
  }, [paymentResponse]);

  return (
    <GridContainer
      spacing={3}
      alignItems="center"
      justifyContent="center"
      direction="column"
      style={{ paddingTop: '18px', paddingLeft: '18px', paddingRight: '18px', marginBottom: '56px' }}
    >
      {adyenCheckout != null ? (
        <>
          <GridItem xs={12}>
            <DynamicTypography
              color="textPrimary"
              align="center"
              variant="subtitle2"
              content={`${getVerbiage(verbiages, 'weblbl060')}`}
            ></DynamicTypography>
          </GridItem>

          <GridItem xs={12}>
            <Typography color="textPrimary" align="center" variant="h4">
              <b>{`${selectedReservation?.currency} ${convertNumberToAmount(selectedReservation?.totalPrice)}`}</b>
            </Typography>
          </GridItem>
        </>
      ) : null}

      {isLoading ? (
        <GridItem>
          <CircularProgress />
        </GridItem>
      ) : (
        <div id="dropin-container">
          <div ref={paymentContainer} className="payment"></div>
        </div>
      )}

      <div id="redirect-container">
        <div ref={redirectContainer} className="redirect"></div>
      </div>
    </GridContainer>
  );
};
