import { Verbiage, User, Country } from '../models';
import {
  ScreenSearchReservation,
  ScreenReservationList,
  ScreenReservationDetail,
  ScreenMarketingConsent,
  ScreenReservationRegistration,
  ScreenReservationPreAuthPayment,
  ScreenReservationRoomAssignment,
  ScreenSearchCheckOutReservation,
  ScreenReservationInvoice,
  ScreenReservationPayment,
  ScreenReservationCheckOutCompleted,
  ScreenReservationPaymentProcessing,
  ScreenReservationCheckIn
} from './constantHelper';

export function getVerbiage(ver: Verbiage[], code: string) {
  if (ver.length == 0 || code == undefined) {
    return '';
  }

  const v = ver.filter((x) => x.code.toLowerCase() === code.toLowerCase())[0];

  return v != null ? v.value : `[${code}]`;
}

export function getName(guest: User): string {
  if (guest == null || guest == undefined) return ' ';

  const fn = guest.firstName !== null && guest.firstName !== undefined ? guest.firstName : '';
  const ln = guest.lastName !== null && guest.lastName !== undefined ? guest.lastName : '';

  return fn != undefined ? ln + ' ' + fn : ln;
}

export function getFirstNameFromFullname(_fullname: string): string {
  const indexOfFirst = _fullname.indexOf(' ');

  return _fullname.substring(indexOfFirst + 1);
}

export function getLastNameFromFullname(_fullname: string): string {
  const indexOfFirst = _fullname.indexOf(' ');

  if (indexOfFirst == -1) {
    return _fullname;
  }

  return _fullname.substring(0, indexOfFirst);
}

export function convertNumberToAmount(amount?: number) {
  var textAmount = Number(amount).toFixed(2);

  return textAmount.toLocaleString();
}

export function isValidateEmail(email: string) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}

export function isValidatePhoneNo(phoneNo: string) {
  var number = phoneNo.replace(' ', '');

  // if (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(number)) {
  if (number.length > 5) {
    return true;
  }
  return false;
}

export function getPhoneCode(phoneNumber: string, countryList: Country[]): string {
  var index = phoneNumber.indexOf(' ');
  var phoneCode = phoneNumber.substring(0, index - 1);

  return phoneCode;
}

export function getPhoneNumber(phoneNumber: string, phoneCodeList: Country[]) {
  var index = phoneNumber.indexOf(' ');
  var number = phoneNumber.substring(index + 1, phoneNumber.length);
  return number;
}

export function convertImageDataURIToBase64(imageURI: string) {
  return imageURI.replace(/^data:image\/[a-z]+;base64,/, '');
}

export function convertImageBase64ToDataURI(image: string) {
  return `data:image/png;base64,${image}`;
}

export function formatCreditCardNumber(cardNumber: string) {
  return cardNumber.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
}

export function formatCreditCardNumberWithoutSpace(cardNumber: string) {
  return cardNumber.replace(/\s/g, '');
}

export function dispayLastFourDigitCreditCardNumber(cardNumber: string) {
  return '********' + cardNumber;
}

export function displayLastFourChar(text: string) {
  let newText = '';
  const replacetext = text.length - 4;

  for (var i = 0; i < replacetext; i++) {
    newText = newText + 'X';
  }

  return newText + text.slice(-4);
}

export function displayFirstFourChar(text: string) {
  let newText = '';
  const replacetext = text.length - 4;

  for (var i = 0; i < replacetext; i++) {
    newText = newText + '*';
  }

  return text.slice(0, 4) + newText;
}

export function getScreenTitle(screenName: string, verbiages: Verbiage[]) {
  var _title = getVerbiage(verbiages, screenName);

  switch (screenName) {
    //CheckIn
    case ScreenSearchReservation:
      _title = getVerbiage(verbiages, 'weblbl082');
      break;
    case ScreenReservationList:
      _title = getVerbiage(verbiages, 'weblbl083');
      break;
    case ScreenReservationDetail:
      _title = getVerbiage(verbiages, 'weblbl052');
      break;
    case ScreenMarketingConsent:
      _title = getVerbiage(verbiages, 'Marketing Consent');
      break;
    case ScreenReservationRegistration:
      _title = getVerbiage(verbiages, 'weblbl053');
      break;
    case ScreenReservationPreAuthPayment:
      _title = getVerbiage(verbiages, 'weblbl054');
      break;
    case ScreenReservationPaymentProcessing:
      _title = getVerbiage(verbiages, 'weblbl061');
      break;
    case ScreenReservationRoomAssignment:
      _title = getVerbiage(verbiages, 'weblbl055');
      break;
    case ScreenReservationCheckIn:
      _title = getVerbiage(verbiages, 'weblbl090');
      break;
    //Checkout
    case ScreenSearchCheckOutReservation:
      _title = getVerbiage(verbiages, 'weblbl056');
      break;
    case ScreenReservationInvoice:
      _title = getVerbiage(verbiages, 'weblbl057');
      break;
    case ScreenReservationPayment:
      _title = getVerbiage(verbiages, 'weblbl058');
      break;
    case ScreenReservationCheckOutCompleted:
      _title = getVerbiage(verbiages, 'weblbl059');
      break;
  }

  return _title;
}
