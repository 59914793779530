import { combineReducers } from 'redux';
import { RootState } from '../store/state';
import hotelReducer from '../reducers/hotel';
import accountReducer from '../reducers/account';
import reservationReducer from './reservation';
import configurationReducer from '../reducers/configuration';
import languageReducer from '../reducers/language';
import paymentReducer from '../reducers/payment';
import countryReducer from './country';
import themeReducer from '../reducers/theme';
import verbiageReducer from '../reducers/verbiages';

export type { RootState };

export const rootReducer = combineReducers<RootState>({
  appConfigState: configurationReducer,
  hotelState: hotelReducer,
  reservationState: reservationReducer,
  languageState: languageReducer,
  country: countryReducer,
  verbiages: verbiageReducer,
  themes: themeReducer,
  account: accountReducer,
  paymentState: paymentReducer
});
