import React from 'react';
// @material-ui/core components
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DynamicTypography } from 'components/DynamicTypography';

interface IProps extends DialogProps {
  title?: string;
  content?: string;
  buttonStyle: 'alert' | 'decision';
  onPositiveButtonClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onNegativeButtonClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const AlertDialog: React.FC<IProps> = ({
  children,
  className,
  title = '',
  content = '',
  buttonStyle,
  onPositiveButtonClick,
  onNegativeButtonClick,
  ...rest
}) => {
  return (
    <Dialog {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DynamicTypography content={content}>
          
        </DynamicTypography>

        {/* <DialogContentText>{content}</DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onPositiveButtonClick} autoFocus>
          {buttonStyle == 'decision' ? 'Yes' : 'Ok'}
        </Button>
        {buttonStyle == 'decision' ? <Button onClick={onNegativeButtonClick}>No</Button> : null}
      </DialogActions>
    </Dialog>
  );
};
