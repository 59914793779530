import React from 'react';
// import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/state';

import {
  resetReservationState,
  createGuestEmail,
  updateGuestEmail,
  checkOutReservation
} from '../../reducers/reservation';
//Component
import { GridContainer, GridItem, MainContainer, NavBar, Stepper } from '../../components';
import { ButtomNavBarButton } from '../../components/BottomNavBarButton';
import Typography from '@material-ui/core/Typography';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { SearchReservation } from './searchReservation';
import { BillingSignature } from '../Checkout/billingSignature';
import { CheckoutComplete } from './checkoutComplete';
//Helper
import { getScreenTitle, getVerbiage, isValidateEmail } from '../../utils/stringHelper';
import {
  ScreenReservationCheckOutCompleted,
  ScreenReservationInvoice,
  ScreenReservationPayment,
  ScreenReservationPaymentProcessing,
  ScreenSearchCheckOutReservation
} from 'utils/constantHelper';
import { resetPaymentState } from 'reducers/payment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CheckoutPayment } from './checkoutPayment';
import { ProcessCheckOutPayment } from './procesCheckOutPayment';
import { constructCheckoutReservationModel } from 'utils/applicationHelper';

export const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, _] = useSearchParams();

  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { selectedReservation } = useSelector((state: RootState) => state.reservationState);
  const [activeStep, setActiveStep] = React.useState(0);
  const [emailForInvoice, setIsEmailInvoiceCompleted] = React.useState('');
  const [completedPayment, setCompletedPayment] = React.useState(false);

  const [submitPay, setSubmitPay] = React.useState(false);
  const [isCreditCardInputValidated, setIsCreditCardInputValidated] = React.useState(false);
  const [isPayingWithPreAuthCard, setIsPayingWithPreAuthCard] = React.useState(false);

  const [totalScreenSteps, setTotalScreenSteps] = React.useState(
    appConfig != undefined ? appConfig?.reservationConfig.checkOutScreenFlow.length : 0
  );

  const [screenFlow, setScreenFlow] = React.useState<string[]>(
    appConfig != undefined ? appConfig?.reservationConfig.checkOutScreenFlow : []
  );

  const [errorPayment, setErrorPayment] = React.useState(false);

  React.useEffect(() => {
    const updateScreenFlow = () => {
      if (appConfig !== undefined) {
        setScreenFlow(appConfig.reservationConfig.checkOutScreenFlow);
        setTotalScreenSteps(appConfig?.reservationConfig.checkOutScreenFlow.length);
      }
    };

    updateScreenFlow();
  }, [appConfig]);

  const handleNext = () => {
    // console.log('handleNext');
    window.scrollTo(0, 0);

    if (screenFlow[activeStep] === ScreenReservationInvoice) {
      if (appConfig != undefined && selectedHotel != undefined && selectedReservation != undefined) {
        //update guest primary email
        if (
          selectedReservation.guest.emails != undefined &&
          selectedReservation.guest.emails.length > 0 &&
          selectedReservation.guest.emails[0] != undefined &&
          selectedReservation.guest.emails[0].contactId != undefined &&
          selectedReservation.guest.emails[0].emailId != undefined
        ) {
          dispatch(
            updateGuestEmail({
              request: {
                profileId: selectedReservation.guest.profileId,
                contactId: selectedReservation.guest.emails[0].contactId!,
                emailId: selectedReservation.guest.emails[0].emailId!,
                email: emailForInvoice
              }
            })
          );
        } else {
          dispatch(createGuestEmail({ email: emailForInvoice }));
        }

        //Checkout when the payment is 0
        if (selectedReservation.totalPrice == 0) {
          var checkOutConfirmationNos = [];
          checkOutConfirmationNos.push(selectedReservation.confirmationNo);

          if (appConfig.reservationConfig.isAddingNewGuestAllow) {
            selectedReservation.sharedReservations?.map((reservation) =>
              checkOutConfirmationNos.push(reservation.confirmationNo)
            );
          }

          dispatch(
            checkOutReservation({
              request: {
                primaryConfirmationNo: selectedReservation.confirmationNo,
                confirmationNos: checkOutConfirmationNos,
                sentEmail: true
              }
            })
          );
          var index = screenFlow.findIndex((e) => e === ScreenReservationCheckOutCompleted);
          setActiveStep(index);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    }
  };

  const handlePay = () => {
    setSubmitPay(true);
  };

  const handleBack = () => {
    window.scrollTo(0, 0);

    if (selectedHotel != undefined) {
      //When clicking back from Invoice screen
      if (screenFlow[activeStep] === ScreenReservationInvoice) {
        dispatch(resetReservationState());
        dispatch(resetPaymentState());
        navigate(`/${selectedHotel.code}/checkout`);
      }

      if (screenFlow[activeStep] === ScreenReservationPayment) dispatch(resetPaymentState());

      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleSearchCheckOutReservationCompleted = () => {
    console.log('handleSearchCheckOutReservationCompleted');
    console.log(selectedReservation);
    if (
      selectedHotel != undefined &&
      appConfig != undefined &&
      selectedReservation != undefined &&
      selectedReservation.isCheckedIn
    ) {
      var _index = activeStep;

      if (searchParams.get('ref')) {
        _index = screenFlow.findIndex((e) => e === ScreenReservationPaymentProcessing);
      } else if (
        selectedReservation.totalPrice <= 0 &&
        selectedReservation.attachments != undefined &&
        selectedReservation.attachments.length == 0
      ) {
        _index = screenFlow.findIndex((e) => e === ScreenReservationCheckOutCompleted);

        var checkOutConfirmationNos = constructCheckoutReservationModel(selectedReservation, appConfig);

        dispatch(
          checkOutReservation({
            request: {
              primaryConfirmationNo: selectedReservation.confirmationNo,
              confirmationNos: checkOutConfirmationNos,
              sentEmail: true
            }
          })
        );
      } else {
        _index = screenFlow.findIndex((e) => e === ScreenReservationInvoice);
      }

      console.log(_index);

      setActiveStep(_index);
    }
  };

  // const handleBackToMainPage = () => {
  //   dispatch(selectReservation(null));
  //   history.back();
  // };

  const onPaymentCompleted = (isCompleted: boolean) => {
    setCompletedPayment(isCompleted);
  };

  const handleOnEmailChange = (isCompleted: string) => {
    setIsEmailInvoiceCompleted(isCompleted);
  };

  return (
    <MainContainer>
      <NavBar />
      {/* Top part */}
      <GridContainer
        direction="column"
        alignItems="center"
        alignContent="center"
        justifyContent="flex-start"
        style={{ height: '90%' }}
      >
        <GridItem xs={12}>
          <LazyLoadImage
            delayMethod="throttle"
            alt="Hotel logo"
            height={96}
            src={appConfig?.hotelConfig.logoUrl}
            width={96}
          />
        </GridItem>
        <GridItem xs={12}>
          <Typography color="textPrimary" align="center" variant="h6">
            <b>{selectedHotel?.name}</b>
          </Typography>
        </GridItem>
        {selectedReservation != undefined ? (
          <GridItem xs={12}>
            <Stepper
              steps={totalScreenSteps}
              activeStep={activeStep}
              title={getScreenTitle(screenFlow[activeStep], verbiages)}
            />
          </GridItem>
        ) : null}
      </GridContainer>

      {/* Checkout Reservation Searching Screen */}
      {screenFlow[activeStep] === ScreenSearchCheckOutReservation ? (
        <SearchReservation
          reservationGuid={searchParams.get('resKey')}
          onCompleted={handleSearchCheckOutReservationCompleted}
        />
      ) : null}

      {/* Reservation Invoice Screen */}
      {screenFlow[activeStep] === ScreenReservationInvoice ? (
        <BillingSignature onEmailChange={handleOnEmailChange} />
      ) : null}
      {screenFlow[activeStep] === ScreenReservationInvoice ? (
        <ButtomNavBarButton
          leftButtonTitle={getVerbiage(verbiages, 'webbtn004')}
          leftButtonOnClick={handleBack}
          rightButtonTitle={getVerbiage(verbiages, 'webbtn005')}
          disableRightButton={!isValidateEmail(emailForInvoice)}
          rightButtonOnClick={handleNext}
        ></ButtomNavBarButton>
      ) : null}

      {/* Reservation Payment Screen */}
      {screenFlow[activeStep] === ScreenReservationPayment ? (
        <CheckoutPayment
          submitPay={submitPay}
          onPayingWithPreAuthCard={(v) => setIsPayingWithPreAuthCard(v)}
          onValidationCompleted={(v) => setIsCreditCardInputValidated(v)}
          onResetPayment={() => setSubmitPay(false)}
          onPaymentCompleted={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
        />
      ) : null}
      {screenFlow[activeStep] === ScreenReservationPayment ? (
        <ButtomNavBarButton
          disableLeftButton={submitPay}
          leftButtonTitle={getVerbiage(verbiages, 'webbtn004')}
          leftButtonOnClick={handleBack}
          disableRightButton={
            isPayingWithPreAuthCard ? false : !isCreditCardInputValidated ? true : submitPay ? true : false
          }
          rightButtonTitle={getVerbiage(verbiages, 'webbtn006')}
          rightButtonOnClick={handlePay}
        ></ButtomNavBarButton>
      ) : null}

      {/* Processing Checkout payment Screen */}
      {screenFlow[activeStep] === ScreenReservationPaymentProcessing ? (
        <ProcessCheckOutPayment
          onCompleted={onPaymentCompleted}
          onError={(e) => {
            setErrorPayment(e);
            setSubmitPay(false);
          }}
        />
      ) : null}
      {screenFlow[activeStep] === ScreenReservationPaymentProcessing && completedPayment ? (
        <ButtomNavBarButton
          rightButtonTitle={getVerbiage(verbiages, 'webbtn005')}
          disableRightButton={!completedPayment}
          rightButtonOnClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
        ></ButtomNavBarButton>
      ) : screenFlow[activeStep] === ScreenReservationPaymentProcessing && errorPayment ? (
        <ButtomNavBarButton
          leftButtonTitle={getVerbiage(verbiages, 'webbtn004')}
          disableLeftButton={!errorPayment}
          leftButtonOnClick={handleBack}
        ></ButtomNavBarButton>
      ) : null}

      {/* Complete Check Out Reservation Screen */}
      {screenFlow[activeStep] === ScreenReservationCheckOutCompleted ? <CheckoutComplete /> : null}
      {/* {screenFlow[activeStep] === ScreenReservationCheckOutCompleted ? (
        <GridContainer justifyContent="center" style={{ marginTop: '32px' }}>
          <CustomButton onClick={handleBackToMainPage}>{'Return to home screen'}</CustomButton>
        </GridContainer>
      ) : null} */}
    </MainContainer>
  );
};
