import React from 'react';
// @material-ui/core components
import MobileStepper from '@material-ui/core/MobileStepper';
import Typography from '@material-ui/core/Typography';

interface IProps {
  title: string;
  steps: number;
  activeStep: number;
}

export const Stepper: React.FC<IProps> = ({ title, steps, activeStep, ...rest }) => {
  return (
    <>
      <MobileStepper
        style={{ justifyContent: 'center'}}
        {...rest}
        steps={steps}
        activeStep={activeStep}
        variant="dots"
        position="static"
        nextButton={null}
        backButton={null}
      />
      <Typography color="textPrimary" align="center" variant="subtitle1">
        {title}
      </Typography>
    </>
  );
};
