import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store/state';
// import { defaultTheme } from '../../themes/index'

const initialState: RootState.ThemeState = {
    theme: "dark"
}

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const themeSlice = createSlice({
  name: 'theme',
  initialState: initialState as RootState.ThemeState,
  reducers: {
    //local reducer
    changeTheme (state, action) {
      state.theme = action.payload
    }
  },
  extraReducers: {}
});

export const { changeTheme } = themeSlice.actions;

export default themeSlice.reducer;
