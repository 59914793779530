import {
    GetDigitalKeyResponse,
    GetDigitalPassRequestModel,
  } from 'models';
  import { getApiKeyForHotel, getToken, privateHttpClient } from 'utils/httpClient';
  
  export const getDigitalPassAPI = async (request: GetDigitalPassRequestModel): Promise<GetDigitalKeyResponse> => {
    const { data } = await privateHttpClient.get(`/api/DigitalPass/${request.GUID}`, {
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
          'ApiKey' : getApiKeyForHotel()
        },
      });
    
  
    return data;
  };