import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';

// @material-ui/core components
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slide,
  TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { TransitionProps } from '@material-ui/core/transitions';
import { BottomButton, DynamicTypography, GridContainer, GridItem, PhoneNoTextInput } from '../../components';

import {
  Reservation,
  CreateHealthDeclarationRequestModel,
  UpdateHealthDeclarationRequestModel,
  ARIA_SuccessCode
} from 'models';
import { getName, getVerbiage, isValidatePhoneNo } from 'utils/stringHelper';
import { updateHealthDeclartion } from 'reducers/reservation';
import { createHealthDeclarationAPI, updateHealthDeclarationAPI } from 'services/healthDeclaration';
import { postErrorLog } from 'services/errorLogService';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
    backgroundColor: theme.palette.primary.main
  },
  title: {
    paddingLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps extends DialogProps {
  updatingGuestReservation: Reservation;
  onCompleted: () => void;
}

export const SafeMeasureDialog: React.FC<IProps> = ({
  updatingGuestReservation,
  open,
  onCompleted,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedReservation, isLoading, reservationServiceError } = useSelector(
    (state: RootState) => state.reservationState
  );

  const [isHealthDeclarationAcknowledge, setIsHealthDeclarationAcknowledge] = React.useState(false);
  const [isHavingFlu, setIsHavingFlu] = React.useState('');
  const [isConfirmCovid, setIsConfirmCovid] = React.useState('');
  const [isCovidCloseContact, setIsCovidCloseContact] = React.useState('');
  // const [isWarnByMOH, setIsWarnByMOH] = React.useState('');

  const [fullName, setFullName] = React.useState('');
  const [fullNameHelpText, setFullNameHelpText] = React.useState<string | null>(null);

  const [emergencyPhoneNo, setEmergencyPhoneNo] = React.useState<string>('');
  const [emergencyPhoneNoHelpText, setEmergencyPhoneNoHelpText] = React.useState<string | null>(null);

  const [disableAcknowledgeButton, setDisableAcknowledgeButton] = React.useState<boolean>(true);

  const [isSubmittingForUpdate, setIsSubmittingForUpdate] = React.useState(false);

  React.useEffect(() => {
    if (updatingGuestReservation !== undefined) {
      if (selectedReservation !== undefined) {
        if (selectedReservation.guest.profileId === updatingGuestReservation.guest.profileId) {
          setFullName(getName(selectedReservation.guest));
        } else {
          if (selectedReservation.sharedReservations !== undefined) {
            var sharerReservation = selectedReservation.sharedReservations.find(
              (x) => x.guest.profileId === updatingGuestReservation.guest.profileId
            );

            if (sharerReservation != undefined) {
              setFullName(getName(sharerReservation.guest));
            }
          }
        }
      }
    }
  }, [selectedReservation]);

  React.useEffect(() => {
    if (open) {
      setIsHealthDeclarationAcknowledge(false);
      setIsHavingFlu('');
      setIsConfirmCovid('');
      setIsCovidCloseContact('');
      // setIsWarnByMOH('');
      setEmergencyPhoneNo('');
    }
  }, [open]);

  React.useEffect(() => {
    if (isSubmittingForUpdate == true && !isLoading && reservationServiceError == undefined) {
      setIsSubmittingForUpdate(false);
      onCompleted();
    }
  }, [isSubmittingForUpdate, isLoading, reservationServiceError]);

  React.useEffect(() => {
    if (
      isHealthDeclarationAcknowledge &&
      isHavingFlu !== '' &&
      isConfirmCovid !== '' &&
      isCovidCloseContact !== ''
      // && isWarnByMOH !== ''
      // && isValidatePhoneNo(emergencyPhoneNo)
    ) {
      setDisableAcknowledgeButton(false);
    } else {
      setDisableAcknowledgeButton(true);
    }
  }, [isHealthDeclarationAcknowledge, isHavingFlu, isConfirmCovid, isCovidCloseContact, emergencyPhoneNo]);

  const handleClose = () => {
    onCompleted();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsHealthDeclarationAcknowledge(event.target.checked);
  };

  const handleIsHavingFluChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsHavingFlu(event.target.value);
  };

  const handleIsConfirmCovidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsConfirmCovid(event.target.value);
  };

  const handleIsCloseCovidCloseContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCovidCloseContact(event.target.value);
  };

  // const handleIsWarnByMOHChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsWarnByMOH(event.target.value);
  // };

  const onAcknowledgeClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (updatingGuestReservation.healthDeclaration == undefined) {
      //if there's no health declaration create a new record
      const requestModel: CreateHealthDeclarationRequestModel = {
        mainConfirmationNo: updatingGuestReservation.confirmationNo,
        profileId: updatingGuestReservation.guest.profileId,
        isStaycation: false,
        employmentPass: false,
        workPermit: false,
        employerContactNo: '',
        employerName: '',
        otherReason: '',
        additionalData: JSON.stringify({
          isHavingFlu: isHavingFlu == 'yes' ? true : false,
          isConfirmCovid: isConfirmCovid == 'yes' ? true : false,
          isCovidCloseContact: isCovidCloseContact == 'yes' ? true : false,
          // isWarnByMOH: isWarnByMOH == 'yes' ? true : false,
          emergencyPhoneNo: emergencyPhoneNo
        })
      };

      createHealthDeclarationAPI(requestModel)
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            dispatch(updateHealthDeclartion(response.result));
            onCompleted();
          } else {
            alert(getVerbiage(verbiages, 'webmsg014'));
            // alert('Oposs... Something when wrong, please try again.');
          }
        })
        .catch((err) => {
          alert(getVerbiage(verbiages, 'webmsg014'));
          postErrorLog({
            confirmationNo: selectedReservation?.confirmationNo,
            errorMessage: JSON.stringify(err)
          })
          // alert('Oposs... Something when wrong, please try again.');
        });

      // dispatch(createHealthDeclarationForGuest({ request: requestModel }));
      // setIsSubmittingForUpdate(true);
    } else {
      const requestModel: UpdateHealthDeclarationRequestModel = {
        id: updatingGuestReservation.healthDeclaration.id,
        mainConfirmationNo: updatingGuestReservation.confirmationNo,
        profileId: updatingGuestReservation.guest.profileId,
        isStaycation: false,
        employmentPass: false,
        workPermit: false,
        employerContactNo: '',
        employerName: '',
        otherReason: '',
        additionalData: JSON.stringify({
          isHavingFlu: isHavingFlu == 'yes' ? true : false,
          isConfirmCovid: isConfirmCovid == 'yes' ? true : false,
          isCovidCloseContact: isCovidCloseContact == 'yes' ? true : false,
          // isWarnByMOH: isWarnByMOH == 'yes' ? true : false,
          emergencyPhoneNo: emergencyPhoneNo
        })
      };

      updateHealthDeclarationAPI(requestModel)
        .then((response) => {
          if(response.resultCode === ARIA_SuccessCode) {
            dispatch(updateHealthDeclartion({...requestModel}));
            onCompleted();
          } else {
            alert(getVerbiage(verbiages, 'webmsg014'));
            // alert('Oposs... Something when wrong, please try again.');
          }
        })
        .catch((err) => {
          alert(getVerbiage(verbiages, 'webmsg014'));
          postErrorLog({
            confirmationNo: selectedReservation?.confirmationNo,
            errorMessage: JSON.stringify(err)
          })
          // alert('Oposs... Something when wrong, please try again.');
        });

      // dispatch(updateHealthDeclarationForGuest({ request: requestModel }));
      // setIsSubmittingForUpdate(true);
    }
  };

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition} {...rest}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton disabled={isLoading} edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {getVerbiage(verbiages, 'weblbl039')}
          </Typography>
          <IconButton disabled={isLoading} edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Toolbar />

      <GridContainer
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ paddingLeft: '24px', paddingRight: '24px', textAlign: 'center' }}
        spacing={3}
      >
        <GridItem xs={12} style={{ marginTop: '16px' }}>
          <Typography align="center" variant="subtitle1">
            <b>{getVerbiage(verbiages, 'weblbl040')}</b>
          </Typography>
        </GridItem>
        <GridItem xs={12}>
          <DynamicTypography
            align="left"
            variant="body2"
            content={`${getVerbiage(verbiages, 'weblbl041')}`}
          ></DynamicTypography>
        </GridItem>

        <GridItem xs={12}>
          <TextField
            disabled
            fullWidth
            error={fullNameHelpText != null}
            helperText={fullNameHelpText}
            id="outlined-required"
            label={`${getVerbiage(verbiages, 'weblbl042')}`}
            variant="outlined"
            value={fullName}
            onChange={(e) => {
              if (e.currentTarget.value == '') {
                setFullNameHelpText(getVerbiage(verbiages, 'employer name cannot be empty'));
              } else {
                setFullNameHelpText(null);
              }

              setFullName(e.currentTarget.value);
            }}
          />
        </GridItem>

        {/* 1st Question */}
        <GridItem xs={12}>
          <DynamicTypography
            align="left"
            variant="body1"
            content={`${getVerbiage(verbiages, 'weblbl043')}`}
          ></DynamicTypography>
          <RadioGroup
            style={{ padding: '8px' }}
            name="isHavingFlu"
            value={isHavingFlu}
            onChange={handleIsHavingFluChange}
          >
            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </GridItem>

        {/* 2nd Question*/}
        <GridItem xs={12}>
          <DynamicTypography
            align="left"
            variant="body1"
            content={`${getVerbiage(verbiages, 'weblbl044')}`}
          ></DynamicTypography>

          <RadioGroup
            style={{ padding: '8px' }}
            name="isConfirmCovid"
            value={isConfirmCovid}
            onChange={handleIsConfirmCovidChange}
          >
            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </GridItem>

        {/* 3rd Question*/}
        <GridItem xs={12}>
          <DynamicTypography
            align="left"
            variant="body1"
            content={`${getVerbiage(verbiages, 'weblbl045')}`}
          ></DynamicTypography>
          <RadioGroup
            style={{ padding: '8px' }}
            name="isCovidCloseContact"
            value={isCovidCloseContact}
            onChange={handleIsCloseCovidCloseContactChange}
          >
            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </GridItem>

        {/* 3rd Question*/}
        {/* <GridItem xs={12} style={{ paddingLeft: '18px', paddingRight: '18px' }}>
          <Typography align="left" variant="body1">
            {getVerbiage(verbiages, '(III) has been issued a health risk warning by the Ministry of Health Singapore')}
          </Typography>
          <RadioGroup
            style={{ padding: '8px' }}
            name="isWarnByMOH"
            value={isWarnByMOH}
            onChange={handleIsWarnByMOHChange}
          >
            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </GridItem> */}

        {/* 3rd Question*/}
        <GridItem xs={12}>
          <DynamicTypography
            align="left"
            variant="body1"
            content={`${getVerbiage(verbiages, 'weblbl046')}`}
          ></DynamicTypography>
          <PhoneNoTextInput
            style={{ marginTop: '18px' }}
            value={emergencyPhoneNo}
            errorHelperText={emergencyPhoneNoHelpText}
            onPhoneNoChange={(number) => {
              if (!isValidatePhoneNo(number)) {
                setEmergencyPhoneNoHelpText(getVerbiage(verbiages, 'webmsg010'));
              } else {
                setEmergencyPhoneNoHelpText(null);
              }
              setEmergencyPhoneNo(number);
            }}
          />
        </GridItem>

        <GridItem xs={12}>
          <DynamicTypography
            align="left"
            variant="body1"
            content={`${getVerbiage(verbiages, 'weblbl047')}`}
          ></DynamicTypography>
        </GridItem>

        <GridItem xs={12}>
          <Accordion style={{ boxShadow: 'none' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-label="Expand">
              <FormControlLabel
                style={{ display: 'table', textAlign: 'start' }}
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <div style={{ display: 'table-cell' }}>
                    <Checkbox checked={isHealthDeclarationAcknowledge} onChange={handleChange} color="primary" />
                  </div>
                }
                label={getVerbiage(verbiages, 'weblbl048')}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary" align="left">
                {getVerbiage(verbiages, 'weblbl049')}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </GridItem>

        <Toolbar />

        <GridItem xs={12}>
          {/* <CustomButton disabled={disableAcknowledgeButton} onClick={onAcknowledgeClicked}>
            {getVerbiage(verbiages, 'Acknowledge')}
          </CustomButton> */}

          <BottomButton
            disableButton={disableAcknowledgeButton}
            buttonOnClick={onAcknowledgeClicked}
            buttonTitle={getVerbiage(verbiages, 'webbtn011')}
          />
        </GridItem>
      </GridContainer>
    </Dialog>
  );
};
