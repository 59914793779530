import { Country } from 'models';

export function getCountryIdByName(_countryName: String, _countryList: Country[]): number {
  if (_countryName == null || _countryName == '' || _countryList.length == 0) return 0;

  const country = _countryList.find((c) => c.name.toLowerCase() === _countryName.toLowerCase());

  if (country == undefined) return 0;

  return country.id;
}

export function getCountryCode3ByName(_countryName: String, _countryList: Country[]): string {
  if (_countryName == null || _countryName == '' || _countryList.length == 0) return "";

  const country = _countryList.find((c) => c.name.toLowerCase() === _countryName.toLowerCase());

  if (country == undefined) return "";

  return country.countryCodeAlpha3;
}

export function getCountryIdByCode3(_countryCode3: String, _countryList: Country[]): number {
  if (_countryCode3 == null || _countryCode3 == '' || _countryList.length == 0) return 0;

  const country = _countryList.find((c) => c.countryCodeAlpha3.toLowerCase() === _countryCode3.toLowerCase());

  if (country == undefined) return 0;

  return country.id;
}

export function getNameByCountryCode3(_countryCode3: String, _countryList: Country[]): string {
  if (_countryCode3 == null || _countryCode3 == '' || _countryList.length == 0) return "";

  const country = _countryList.find((c) => c.countryCodeAlpha3.toLowerCase() === _countryCode3.toLowerCase());

  if (country == undefined) return "";

  return country.name;
}
