import { FaceMatchResult, LivenessResult } from '../models';
import { livenessHttpClient, facialHttpClient } from '../utils/httpClient';

export async function livenessDetection(selfieImage: String) : Promise<LivenessResult> {
  const { data } = await livenessHttpClient.post('/api/v1/liveness', {
    image: selfieImage,
    settings: {
      SubscriptionId: process.env.REACT_APP_SUBSCRIPTION_ID,
      additionalSettings: {
        OS: 'UNKNOWN'
      }
    }
  });

  return data as LivenessResult;
}

export async function faceMatch(imageOne: String, imageTwo: String): Promise<FaceMatchResult> {
  const { data } = await facialHttpClient.post('/api/v1/facematch', {
    data: {
      ImageOne: imageOne,
      ImageTwo: imageTwo
    },
    settings: {
      SubscriptionId: process.env.REACT_APP_SUBSCRIPTION_ID
    }
  });

  return data as FaceMatchResult;
}
