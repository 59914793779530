import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/state';

//Component
import { GridContainer, GridItem, CustomButton, PhoneNoTextInput, DynamicTypography } from '../../components';
import Typography from '@material-ui/core/Typography';
import QRCode from 'qrcode.react';

//Helper
import { getVerbiage, isValidateEmail, isValidatePhoneNo } from '../../utils/stringHelper';
import { IconButton, Snackbar, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ARIA_SuccessCode } from 'models';
import { subscribeRoomForNotification } from 'reducers/reservation';
import { createGuestEmailAPI, updateGuestEmailAPI } from 'services/guestService';
import { generateOTPPinAPI } from 'services/reservationService';
import { getApplicationMode } from 'utils/applicationHelper';
import { MobileApplicationMode } from 'utils/constantHelper';
import { postErrorLog } from 'services/errorLogService';

interface IProps {
  onRequestMobileKey: () => void;
}

export const RegistrationComplete: React.FC<IProps> = ({ onRequestMobileKey }) => {
  const dispatch = useDispatch();

  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { selectedReservation } = useSelector((state: RootState) => state.reservationState);

  const [applicationMode, _] = React.useState(getApplicationMode);

  const [message, setMessage] = React.useState('');

  const [email, setEmail] = React.useState<string>('');
  const [emailHelptext, setEmailHelptext] = React.useState('');

  const [PhoneNo, setPhoneNo] = React.useState<string>('');
  const [phoneNoHelpText, setPhoneNoHelpText] = React.useState<string>('');

  const [hasSubmitNotify, setHasSubmitNotify] = React.useState(false);
  const [changeEmail, setChangeEmail] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const [pin, setPin] = React.useState<string>('');

  React.useEffect(() => {
    const preregisteredCompleted = async () => {
      if (selectedReservation != undefined) {
        if (
          selectedReservation.guest &&
          selectedReservation.guest.emails != undefined &&
          selectedReservation.guest.emails.length > 0 &&
          selectedReservation.guest.emails[0].email != undefined
        ) {
          setEmail(selectedReservation.guest.emails[0].email);
        }

        if (selectedReservation.subscribeEmail) {
          setEmail(selectedReservation.subscribeEmail);
        }
      }
    };

    preregisteredCompleted();
  }, [selectedReservation]);

  const handleNotify = async () => {
    if (appConfig?.reservationConfig.isEmailRequireWhenRoomNotReady) {
      if (!isValidateEmail(email)) {
        setMessage(`${getVerbiage(verbiages, 'webmsg004')}`);
      } else {
        if (selectedHotel != undefined && selectedReservation != undefined) {
          setIsLoading(true);
          if (
            selectedReservation.guest.emails != undefined &&
            selectedReservation.guest.emails.length > 0 &&
            selectedReservation.guest.emails[0] != undefined &&
            selectedReservation.guest.emails[0].contactId != undefined &&
            selectedReservation.guest.emails[0].emailId != undefined
          ) {
            updateGuestEmailAPI({
              profileId: selectedReservation.guest.profileId,
              contactId: selectedReservation.guest.emails[0].contactId!,
              emailId: selectedReservation.guest.emails[0].emailId!,
              email: email
            })
              .then((response) => {
                if (response.resultCode === ARIA_SuccessCode) {
                  setHasSubmitNotify(true);
                  setIsLoading(false);
                } else {
                  setIsLoading(false);
                  setMessage(`${getVerbiage(verbiages, 'webmsg014')}`);
                  // setMessage('Oposs... Something when wrong, please try again.');
                }
              })
              .catch((err) => {
                setIsLoading(false);
                setMessage(`${getVerbiage(verbiages, 'webmsg014')}`);
                postErrorLog({
                  confirmationNo: selectedReservation?.confirmationNo,
                  errorMessage: JSON.stringify(err)
                });
                // setMessage('Oposs... Something when wrong, please try again.');
              });
          } else {
            createGuestEmailAPI({
              profileId: selectedReservation!.guest.profileId,
              email: email
            })
              .then((response) => {
                if (response.resultCode === ARIA_SuccessCode) {
                  dispatch(subscribeRoomForNotification());
                  setHasSubmitNotify(true);
                  setIsLoading(false);
                } else {
                  setIsLoading(false);
                  setMessage(`${getVerbiage(verbiages, 'webmsg014')}`);
                  // setMessage('Oposs... Something when wrong, please try again.');
                }
              })
              .catch((err) => {
                setIsLoading(false);
                setMessage(`${getVerbiage(verbiages, 'webmsg014')}`);
                postErrorLog({
                  confirmationNo: selectedReservation?.confirmationNo,
                  errorMessage: JSON.stringify(err)
                });
                // setMessage('Oposs... Something when wrong, please try again.');
              });
          }
          setChangeEmail(false);
        }
      }
    }
  };

  const handleGenerateOTP = async () => {
    if (selectedReservation != undefined) {
      setIsLoading(true);

      generateOTPPinAPI({
        guid: selectedReservation.guid
      })
        .then((response) => {
          if (response.resultCode === ARIA_SuccessCode) {
            setPin(response.result.pin);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setMessage(`${getVerbiage(verbiages, 'webmsg014')}`);
            // setMessage('Oposs... Something when wrong, please try again.');
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setMessage(`${getVerbiage(verbiages, 'webmsg014')}`);
          postErrorLog({
            confirmationNo: selectedReservation?.confirmationNo,
            errorMessage: JSON.stringify(err)
          });
          // setMessage('Oposs... Something when wrong, please try again.');
        });
    }
  };

  function checkIsReadyToSubmit(): boolean {
    var emailDisable = true;
    // var phoneDisable = true;

    if (appConfig?.reservationConfig.isEmailRequireWhenRoomNotReady && isValidateEmail(email)) {
      emailDisable = false;
    }

    // if(appConfig?.reservationConfig.isPhoneNoRequireWhenRoomNotReady && isValidatePhoneNo(PhoneNo)) {
    //   phoneDisable = false
    // }

    return emailDisable;
  }

  const handleClose = () => {
    setMessage('');
  };

  return (
    <GridContainer
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction="column"
      style={{ paddingTop: '18px' }}
    >
      <GridItem xs={12}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={message !== ''}
          onClose={handleClose}
          autoHideDuration={5000}
          message={message}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </GridItem>

      {/* Room is ready */}
      {selectedReservation !== undefined &&
      selectedReservation.room !== undefined &&
      selectedReservation.room.isRoomReady &&
      selectedReservation.room.number !== '' ? (
        <>
          <GridItem xs={12}>
            <div style={{ padding: '3px', backgroundColor: 'white' }}>
              <QRCode
                value={`${window.location.origin.toLowerCase()}/${selectedHotel?.code}?resKey=${
                  selectedReservation.guid
                }`}
              />
            </div>
            {appConfig?.reservationConfig.displayRoomNo ? (
              <Typography color="textPrimary" align="center" variant="h6">
                <b>{`Room ${selectedReservation.room.number}`}</b>
              </Typography>
            ) : null}
          </GridItem>
          <GridItem xs={12}>
            <Typography color="textPrimary" align="center" variant="subtitle1">
              {/* Your Room is ready */}

              {`${getVerbiage(verbiages, 'weblbl065')}`}
            </Typography>
          </GridItem>

          {appConfig?.webOTPToCollectKeyFromKiosk ? (
            <>
              <GridItem xs={12}>
                {pin != '' ? (
                  <DynamicTypography
                    color="textPrimary"
                    align="center"
                    variant="h6"
                    content={`<b>${pin}</b>`}
                  ></DynamicTypography>
                ) : null}
              </GridItem>
              <GridItem xs={12}>
                <CustomButton disabled={isLoading} onClick={handleGenerateOTP}>
                  {getVerbiage(verbiages, 'webbtn013')}
                </CustomButton>
              </GridItem>
            </>
          ) : null}

          {applicationMode == MobileApplicationMode ? (
            <GridItem xs={12}>
              <CustomButton disabled={!isValidateEmail(email) || changeEmail || isLoading} onClick={onRequestMobileKey}>
                {/* Request Mobile Key */}
                {getVerbiage(verbiages, 'weblbl086')}
              </CustomButton>
            </GridItem>
          ) : null}

          <GridItem xs={12}>
            {/* Please visit one of our key collection terminal at the lobby and select "Collect Key" then scan your QR code above. */}
            <DynamicTypography
              color="textPrimary"
              align="center"
              variant="body1"
              content={`${getVerbiage(
                verbiages,
                applicationMode == MobileApplicationMode ? 'weblbl079' : 'weblbl066'
              )}`}
            ></DynamicTypography>
          </GridItem>

          {/* Email input */}
          {appConfig?.reservationConfig.isEmailRequireWhenRoomNotReady ? (
            <GridItem xs={12}>
              <form noValidate autoComplete="off">
                <TextField
                  required
                  disabled={!changeEmail}
                  error={emailHelptext != ''}
                  helperText={emailHelptext}
                  style={{ width: '80vw' }}
                  id="outlined-required"
                  label={`${getVerbiage(verbiages, 'weblbl004')}`}
                  variant="outlined"
                  value={email}
                  onChange={(e) => {
                    if (e.currentTarget.value == '') {
                      setEmailHelptext(`${getVerbiage(verbiages, 'webmsg003')}`);
                    } else if (!isValidateEmail(e.currentTarget.value))
                      setEmailHelptext(`${getVerbiage(verbiages, 'webmsg004')}`);
                    else {
                      setEmailHelptext('');
                    }
                    setEmail(e.currentTarget.value);
                  }}
                />
              </form>
            </GridItem>
          ) : null}

          {!changeEmail ? (
            <GridItem xs={12}>
              <CustomButton onClick={() => setChangeEmail(true)}>{getVerbiage(verbiages, 'weblbl085')}</CustomButton>
            </GridItem>
          ) : (
            <GridItem>
              <CustomButton disabled={checkIsReadyToSubmit() || isLoading} onClick={handleNotify}>
                {getVerbiage(verbiages, 'webbtn012')}
              </CustomButton>
            </GridItem>
          )}

          <GridItem xs={12} style={{ marginBottom: '48px' }}>
            <Typography color="textPrimary" align="center" variant="body1">
              {`${getVerbiage(verbiages, 'weblbl067')}`}
            </Typography>
          </GridItem>
        </>
      ) : (
        <>
          <GridItem xs={12}>
            <DynamicTypography
              color="textPrimary"
              align="center"
              variant="subtitle1"
              content={
                !hasSubmitNotify ? `${getVerbiage(verbiages, 'weblbl068')}` : `${getVerbiage(verbiages, 'weblbl067')}`
              }
            />
          </GridItem>
          <GridItem xs={12}>
            <DynamicTypography
              color="textPrimary"
              align="center"
              variant="body1"
              content={
                !hasSubmitNotify ? `${getVerbiage(verbiages, 'weblbl069')}` : `${getVerbiage(verbiages, 'weblbl080')}`
              }
            />
          </GridItem>

          {/* Email input */}
          {appConfig?.reservationConfig.isEmailRequireWhenRoomNotReady && !hasSubmitNotify ? (
            <GridItem xs={12}>
              <form noValidate autoComplete="off">
                <TextField
                  required
                  // disabled={isLoading}
                  error={emailHelptext != ''}
                  helperText={emailHelptext}
                  style={{ width: '80vw' }}
                  id="outlined-required"
                  label={`${getVerbiage(verbiages, 'weblbl004')}`}
                  variant="outlined"
                  value={email}
                  onChange={(e) => {
                    if (e.currentTarget.value == '') {
                      setEmailHelptext(`${getVerbiage(verbiages, 'webmsg003')}`);
                    } else if (!isValidateEmail(e.currentTarget.value))
                      setEmailHelptext(`${getVerbiage(verbiages, 'webmsg004')}`);
                    else {
                      setEmailHelptext('');
                    }
                    setEmail(e.currentTarget.value);
                  }}
                />
              </form>
            </GridItem>
          ) : null}

          {/* phone no input */}
          {appConfig?.reservationConfig.isPhoneNoRequireWhenRoomNotReady && !hasSubmitNotify ? (
            <GridItem xs={12}>
              <PhoneNoTextInput
                style={{ marginTop: '18px', width: '80vw' }}
                value={PhoneNo}
                errorHelperText={phoneNoHelpText}
                onPhoneNoChange={(number) => {
                  if (!isValidatePhoneNo(number)) {
                    setPhoneNoHelpText(getVerbiage(verbiages, 'webmsg010'));
                  } else {
                    setPhoneNoHelpText('');
                  }
                  setPhoneNo(number);
                }}
              />
            </GridItem>
          ) : null}

          {/* <GridItem xs={12}>
            <DynamicTypography
              color="textPrimary"
              align="center"
              variant="body1"
              content={`${getVerbiage(verbiages, 'weblbl079')}`}
            ></DynamicTypography>
          </GridItem>
          <GridItem xs={12}>
            <Typography color="textPrimary" align="center" variant="body1">
              {`${getVerbiage(verbiages, 'weblbl067')}`}
            </Typography>
          </GridItem> */}
          {!hasSubmitNotify ? (
            <GridItem style={{ marginBottom: '48px' }}>
              <CustomButton disabled={checkIsReadyToSubmit() || isLoading} onClick={handleNotify}>
                {getVerbiage(verbiages, 'webbtn012')}
              </CustomButton>
            </GridItem>
          ) : null}
        </>
      )}
    </GridContainer>
  );
};
