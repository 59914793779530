import React from 'react';

// @material-ui/core components
import AppBar, { AppBarProps } from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
//https://material-ui.com/components/app-bar/#fixed-placement

interface IProps extends AppBarProps {
  leftButtonTitle?: string | undefined;
  disableLeftButton?: boolean | undefined;
  leftButtonOnClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  rightButtonTitle?: string | undefined;
  disableRightButton?: boolean | undefined;
  rightButtonOnClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const ButtomNavBarButton: React.FC<IProps> = ({
  leftButtonTitle,
  disableLeftButton = false,
  leftButtonOnClick,
  rightButtonTitle,
  disableRightButton = false,
  rightButtonOnClick,
  ...rest
}) => {
  return (
    <>
      <Toolbar style={{ marginTop: '16px' }} />
      <AppBar
        style={{ top: 'auto', bottom: 0 }}
        position={'fixed'}
        {...rest}
      >
        <Toolbar>
          {leftButtonTitle !== undefined ? (
            <Button
              disabled={disableLeftButton}
              size="large"
              onClick={leftButtonOnClick}
              style={{ width: '180px' }}
              startIcon={<ArrowBackIcon />}
            >
              {leftButtonTitle}
            </Button>
          ) : null}

          <Typography color="textPrimary" style={{ flexGrow: 1 }} align="center" />

          {rightButtonTitle !== undefined ? (
            <Button
              disabled={disableRightButton}
              size="large"
              color="primary"
              variant="contained"
              onClick={rightButtonOnClick}
              style={{ width: '180px' }}
              endIcon={<ArrowForwardIcon />}
            >
              {rightButtonTitle}
            </Button>
          ) : null}
        </Toolbar>
      </AppBar>
    </>
  );
};
