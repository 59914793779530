import React from 'react';
// @material-ui/core components
import Grid, { GridProps } from '@material-ui/core/Grid';

interface IProps extends GridProps {}

export const GridContainer: React.FC<IProps> = ({ children, ...rest }) => {
  return (
    <Grid container {...rest}>
      {children}
    </Grid>
  );
};
