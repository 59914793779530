import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage } from '../../reducers/language';
import { RootState } from '../../store/state';

// @material-ui/core components
import { useNavigate } from 'react-router-dom';
import AppBar, { AppBarProps } from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LanguageIcon from '@material-ui/icons/Language';
import HomeIcon from '@material-ui/icons/Home';
import { resetReservationState } from 'reducers/reservation';
import { resetPaymentState } from 'reducers/payment';

//https://material-ui.com/components/app-bar/#fixed-placement

interface IProps extends AppBarProps {
  allowBack?: boolean;
  allowHome?: boolean;
}

export const NavBar: React.FC<IProps> = ({ allowBack = false, allowHome = true, ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { languages, selectedLanguage } = useSelector((state: RootState) => state.languageState);
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (_: React.MouseEvent<HTMLLIElement>, index: Number) => {
    dispatch(selectLanguage(languages[index.valueOf()]));
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGoToHome = () => {
    dispatch(resetReservationState());
    dispatch(resetPaymentState());

    if(selectedHotel != undefined) {
      navigate(`/${selectedHotel.code}`);
    }else {
      navigate('/');
    }
  };

  const handleBack = () => {
    history.back();
  };

  return (
    <>
      <AppBar position='fixed' {...rest}>
        <Toolbar style={{paddingLeft: '4px' , paddingRight: '4px'}}>
          {allowBack && navigate.name !== '/' ? (
            <>
              <IconButton edge="start" aria-label="back" onClick={handleBack}>
                <ArrowBackIosIcon />
              </IconButton>
            </>
          ) : null}

          <Typography color="textPrimary" style={{ flexGrow: 1 }} align="center" />
          {/* Home button */}
          {allowHome && navigate.name != '/' ? (
            <>
              <IconButton onClick={handleGoToHome}>
                <HomeIcon />
              </IconButton>
            </>
          ) : null}

          {/* Language button */}
          {selectedLanguage != null ? (
            <>
              <IconButton onClick={handleMenu}>
                <LanguageIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={open}
                onClose={handleClose}
              >
                {languages.map((option, index) => (
                  <MenuItem
                    key={option.code}
                    disabled={option.code === selectedLanguage.code}
                    selected={option.code === selectedLanguage.code}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option.description}
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : null}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};
