import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from 'reducers';

//Component
import { GridContainer, GridItem } from '../../components';
import Typography from '@material-ui/core/Typography';
import AccountBalance from '@material-ui/icons/AccountBalance';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { green } from '@material-ui/core/colors';

//Helper
import { Box, CircularProgress, Fab } from '@material-ui/core';
import { completedPaymentForSelectedReservation } from 'reducers/reservation';
import { getVerbiage } from 'utils/stringHelper';
import { constructCheckoutReservationModel } from 'utils/applicationHelper';
import { capturePreAuthPaymentAPI, updatePaymentCompletionAPI } from 'services/paymentService';
import { ARIA_SuccessCode } from 'models';
import { checkOutReservationAPI } from 'services/reservationService';
import { PAYMENT_CODE_AUTHORISED } from 'models/backend/constant/Payment';
import { postErrorLog } from 'services/errorLogService';

interface IProps {
  onCompleted: (isCompleted: boolean) => void;
  onError: (isError: boolean) => void;
}

export const ProcessCheckOutPayment: React.FC<IProps> = ({ onCompleted, onError }) => {
  const dispatch = useDispatch();
  const [searchParams, _] = useSearchParams();

  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedReservation } = useSelector((state: RootState) => state.reservationState);
  const { preAuthCardInfo, paymentResponse } = useSelector((state: RootState) => state.paymentState);

  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const setErrorState = () => {
    setIsError(true);
    onError(true);
    setIsLoading(false);
  };

  React.useEffect(() => {
    console.log('updateSecurePayment');

    const updateSecurePayment = async () => {
      var referenceNo = searchParams.get('ref');
      var redirectResult = searchParams.get('redirectResult');

      /////////////////////////////
      //Capture a 3Ds transaction
      /////////////////////////////
      if (
        referenceNo != null &&
        redirectResult != null &&
        selectedReservation != undefined &&
        selectedReservation.isCheckedIn
      ) {
        //Handle check in preauth payment
        console.log('handleCheckInPreAuthPayment');
        setIsLoading(true);

        //Processing 3Ds Payment
        updatePaymentCompletionAPI({
          captureTransaction: true,
          referenceNo: referenceNo,
          details: { RedirectResult: redirectResult }
        })
          .then((response) => {
            if (response.resultCode === ARIA_SuccessCode) {
              console.log('checkOut');
              var checkOutConfirmationNos = constructCheckoutReservationModel(selectedReservation, appConfig!!);

              checkOutReservationAPI({
                primaryConfirmationNo: selectedReservation.confirmationNo,
                confirmationNos: checkOutConfirmationNos,
                sentEmail: true
              })
                .then((response) => {
                  if (response.resultCode == ARIA_SuccessCode) {
                    setIsLoading(false);
                    dispatch(completedPaymentForSelectedReservation());
                    onCompleted(true);
                  } else {
                    setErrorState();
                  }
                })
                .catch((err) => {
                  console.log(err);
                  postErrorLog({
                    confirmationNo: selectedReservation?.confirmationNo,
                    errorMessage: JSON.stringify(err)
                  });
                  setErrorState();
                });
            } else {
              setErrorState();
            }
          })
          .catch((err) => {
            console.log(err);
            postErrorLog({
              confirmationNo: selectedReservation?.confirmationNo,
              errorMessage: JSON.stringify(err)
            });
            setErrorState();
          });

        /////////////////////////////
        //Credit card without 3ds
        /////////////////////////////
      } else if (
        paymentResponse !== undefined &&
        paymentResponse.code === PAYMENT_CODE_AUTHORISED &&
        selectedHotel !== undefined &&
        selectedReservation !== undefined
      ) {
        console.log('capturePreAuthPayment');
        setIsLoading(true);

        capturePreAuthPaymentAPI({
          confirmationNo: selectedReservation.confirmationNo,
          referenceNo: paymentResponse.referenceNo
        })
          .then((response) => {
            if (response.resultCode == ARIA_SuccessCode) {
              console.log('checkOut');
              var checkOutConfirmationNos = constructCheckoutReservationModel(selectedReservation, appConfig!!);

              checkOutReservationAPI({
                primaryConfirmationNo: selectedReservation.confirmationNo,
                confirmationNos: checkOutConfirmationNos,
                sentEmail: true
              })
                .then((response) => {
                  if (response.resultCode == ARIA_SuccessCode) {
                    setIsLoading(false);
                    dispatch(completedPaymentForSelectedReservation());
                    onCompleted(true);
                  } else {
                    setErrorState();
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setErrorState();
                  postErrorLog({
                    confirmationNo: selectedReservation?.confirmationNo,
                    errorMessage: JSON.stringify(err)
                  })
                });
            } else {
              setErrorState();
            }
          })
          .catch((err) => {
            // console.log(err)
            setErrorState();
            postErrorLog({
              confirmationNo: selectedReservation?.confirmationNo,
              errorMessage: JSON.stringify(err)
            })
          });

        /////////////////////////////
        //Using the previous card to capture transaction
        /////////////////////////////
      } else if (
        preAuthCardInfo !== undefined &&
        selectedHotel !== undefined &&
        selectedReservation !== undefined &&
        paymentResponse === undefined
      ) {
        console.log('capturePreAuthPayment');
        setIsLoading(true);

        capturePreAuthPaymentAPI({
          confirmationNo: selectedReservation.confirmationNo,
          referenceNo: preAuthCardInfo.referenceNo
        })
          .then((response) => {
            if (response.resultCode == ARIA_SuccessCode) {
              console.log('checkOut');
              var checkOutConfirmationNos = constructCheckoutReservationModel(selectedReservation, appConfig!!);

              checkOutReservationAPI({
                primaryConfirmationNo: selectedReservation.confirmationNo,
                confirmationNos: checkOutConfirmationNos,
                sentEmail: true
              })
                .then((response) => {
                  if (response.resultCode == ARIA_SuccessCode) {
                    setIsLoading(false);
                    dispatch(completedPaymentForSelectedReservation());
                    onCompleted(true);
                  } else {
                    setErrorState();
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setErrorState();
                  postErrorLog({
                    confirmationNo: selectedReservation?.confirmationNo,
                    errorMessage: JSON.stringify(err)
                  })
                });
            } else {
              setErrorState();
            }
          })
          .catch((err) => {
            // console.log(err)
            setErrorState();
            postErrorLog({
              confirmationNo: selectedReservation?.confirmationNo,
              errorMessage: JSON.stringify(err)
            })
          });
      } else {
        alert('is here!!!');

        postErrorLog({
          confirmationNo: selectedReservation?.confirmationNo,
          errorMessage: JSON.stringify("LOOK INTO THE WEB CODE!!")
        })
      }
    };

    updateSecurePayment();
  }, []);

  // React.useEffect(() => {
  //   const checkPaymentStatus = async () => {
  //     console.log('checkPaymentStatus');

  //     if (
  //       completedPayment &&
  //       appConfig != undefined &&
  //       selectedHotel != null &&
  //       selectedReservation != null &&
  //       selectedReservation.isCheckedIn
  //     ) {
  //       var checkOutConfirmationNos = constructCheckoutReservationModel(selectedReservation, appConfig);
  //       // checkOutConfirmationNos.push(selectedReservation.confirmationNo);

  //       // if (appConfig.reservationConfig.isAddingNewGuestAllow) {
  //       //   selectedReservation.sharedReservations?.map((reservation) =>
  //       //     checkOutConfirmationNos.push(reservation.confirmationNo)
  //       //   );
  //       // }

  //       dispatch(
  //         checkOutReservation({
  //           request: {
  //             hotelId: selectedHotel.id,
  //             primaryConfirmationNo: selectedReservation.confirmationNo,
  //             confirmationNos: checkOutConfirmationNos,
  //             sentEmail: true
  //           }
  //         })
  //       );

  //       onCompleted(true);
  //     } else if (error != null) {
  //       onError(true);
  //     }
  //   };

  //   checkPaymentStatus();
  // }, [completedPayment, error]);

  return (
    <GridContainer
      spacing={3}
      alignContent="center"
      justifyContent="center"
      direction="row"
      style={{ paddingTop: '32px', paddingLeft: '18px', paddingRight: '18px', height: '50vh' }}
    >
      <GridItem>
        <Box sx={{ m: 1, position: 'relative' }}>
          {selectedReservation?.paid ? (
            <Fab style={{ backgroundColor: green[500] }}>
              <DoneIcon
                style={{
                  color: 'white'
                }}
              />
            </Fab>
          ) : isError ? (
            <Fab color="secondary">
              <CancelIcon />
            </Fab>
          ) : (
            <Fab color="primary">
              <AccountBalance />
            </Fab>
          )}

          {isLoading ? (
            <CircularProgress
              size={68}
              style={{
                color: green[500],
                position: 'absolute',
                top: -6,
                left: -6,
                zIndex: 1
              }}
            />
          ) : null}
        </Box>
      </GridItem>

      {/* Wording */}
      {selectedReservation?.paid ? (
        <GridItem xs={12}>
          <Typography align="center" variant="body1">
            {getVerbiage(verbiages, 'weblbl064')}
          </Typography>
        </GridItem>
      ) : isError ? (
        <GridItem xs={12}>
          <Typography align="center" variant="body1">
            {/* Sorry we are unable to process your payment. Please try again. */}
            {getVerbiage(verbiages, 'weblbl062')}
          </Typography>
        </GridItem>
      ) : (
        <GridItem xs={12}>
          <Typography align="center" variant="body1">
            {/* Please do not turn off the application while we are processing your payment. */}
            {getVerbiage(verbiages, 'weblbl063')}
          </Typography>
        </GridItem>
      )}
    </GridContainer>
  );
};
