import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCountryList } from '../services/countryCodeService';
import { RootState } from '../store/state';
import { ResponseModel } from '../models/common/ResponseModel';
import { AxiosError } from 'axios';
import { CountryFilterResponseModel } from 'models/backend/CountryModel';

const initialState: RootState.CountryState = {
  countryList: [],
  selectedCountryCode: undefined,
  error: null,
  isLoading: false
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchCountryList = createAsyncThunk<
  CountryFilterResponseModel,
  void,
  { rejectValue: AxiosError<Error> }
>('countryCode/getCountryCodes', async (_, thunkApi) => {
  try {
    var pageIndex = 1;

    let data: CountryFilterResponseModel = await getCountryList(pageIndex);

    let tempData = data.result.items;

    while(data.result.pageCount > pageIndex) {
      pageIndex++;
      let nextData : CountryFilterResponseModel = await getCountryList(pageIndex);

      tempData.push(...nextData.result.items);
    }

    data.result.items = tempData;

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const countryCodeSlice = createSlice({
  name: 'countryCode',
  initialState,
  reducers: {
    //local reducer
    selectCountryCode(state, action) {
      state.countryList = action.payload;
    },
    resetCountryState() {
      return initialState;
    }
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(fetchCountryList.fulfilled, (state, { payload }) => {
      state.countryList = payload.result.items;

      if (state.selectedCountryCode == null && payload.result.items.length > 0) {
        state.selectedCountryCode = payload.result.items.find((countryCode) => countryCode.phoneCode == '+65');
      }

      const serverError: ResponseModel = {
        resultCode: payload.resultCode,
        resultDescription: payload.resultDescription
      };

      state.error = serverError;
      state.isLoading = false;
    }),
      builder.addCase(fetchCountryList.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchCountryList.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? '',
            resultDescription: error?.response?.data.message ?? ''
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });
  }
});

export const { selectCountryCode, resetCountryState } = countryCodeSlice.actions;

export default countryCodeSlice.reducer;
