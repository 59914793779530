import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/state';

// @material-ui/core components
import TextField from '@material-ui/core/TextField';
import { GridContainer, GridItem } from '../../components';
import { FormHelperText, GridProps } from '@material-ui/core';

import { getPhoneNumber, getVerbiage } from '../../utils/stringHelper';

interface IProps extends GridProps {
  value: string;
  errorHelperText: string | null;
  disabled?: boolean;
  onPhoneNoChange: (value: string) => void;
}

export const PhoneNoTextInput: React.FC<IProps> = ({ onPhoneNoChange, value, errorHelperText, disabled = true, ...rest }) => {
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedCountryCode } = useSelector((state: RootState) => state.country);
  const { countryList } = useSelector((state: RootState) => state.country);

  const [phoneCode] = React.useState(countryList.filter((x) => x.phoneCode != null));

  const [mobileNo, setMobileNo] = React.useState<string>('');
  const [selectCountryCode, setSelectCountryCode] = React.useState<string>(
    selectedCountryCode != undefined ? selectedCountryCode.phoneCode : '+65'
  );

  const handlePhoneCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectCountryCode(event.target.value);
    const number = `${event.target.value} ${mobileNo}`;

    onPhoneNoChange(number);
  };

  const handleMobileNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {    
    setMobileNo(event.target.value);
    const number = `${selectCountryCode} ${event.target.value}`;
    
    onPhoneNoChange(number);

  };

  return (
    <>
      <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start" {...rest}>
        <GridItem xs={3}>
          <TextField
            select
            fullWidth
            value={selectCountryCode}
            onChange={handlePhoneCodeChange}
            SelectProps={{
              native: true
            }}
            variant="outlined"
          >
            {phoneCode.map((country) => (
              <option key={country.id} value={country.phoneCode}>
                {`${country.phoneCode} [${country.name}]`}
              </option>
            ))}
          </TextField>
        </GridItem>
        <GridItem xs={9}>
          <TextField
            disabled={!disabled}
            error={errorHelperText != null}
            variant="outlined"
            fullWidth
            inputMode="numeric"
            label={getVerbiage(verbiages, 'Phone number')}
            value={getPhoneNumber(value, countryList)}
            onChange={handleMobileNoChange}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={2}></GridItem>
        <GridItem xs={10}>
          <FormHelperText disabled={!disabled} error={errorHelperText != null}>
            {errorHelperText}
          </FormHelperText>
        </GridItem>
      </GridContainer>
    </>
  );
};
