import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/state';

//Component
import { DynamicTypography, GridContainer, GridItem } from '../../components';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

//Helper
import { getName, getVerbiage } from '../../utils/stringHelper';

import { Reservation } from '../../models';
import { checkAcknowledgement, uncheckAcknowledgement } from 'reducers/reservation';

interface IProps {
  onCompleted: (isCompleted: boolean) => void;
}

export const RegistrationCard: React.FC<IProps> = ({ onCompleted }) => {
  const dispatch = useDispatch();

  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedReservation } = useSelector((state: RootState) => state.reservationState);

  React.useEffect(() => {
    console.log('signcallback');
    if (
      appConfig != undefined &&
      selectedReservation != undefined &&
      selectedReservation.sharedReservations != undefined
    ) {
      if (appConfig.reservationConfig.isAddingNewGuestAllow) {
        if (
          selectedReservation.signedAcknowledgement &&
          selectedReservation.sharedReservations.filter((res) => res.signedAcknowledgement).length ===
            selectedReservation.sharedReservations.length
        ) {
          onCompleted(true);
        } else {
          onCompleted(false);
        }
      } else {
        if (selectedReservation.signedAcknowledgement) {
          onCompleted(true);
        } else {
          onCompleted(false);
        }
      }
    }
  }, [selectedReservation]);

  const generateRegistrationCardGuestConsent = (reservation: Reservation) => {
    const { guest } = reservation;

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;

      if (checked) {
        await dispatch(checkAcknowledgement(guest.profileId));
      } else {
        await dispatch(uncheckAcknowledgement(guest.profileId));
      }
    };

    return (
      <GridItem xs={12} key={reservation.id}>
        <FormControlLabel
          control={
            <Checkbox
              checked={reservation.signedAcknowledgement}
              onChange={handleChange}
              name="check"
              color="primary"
            />
          }
          label={<b>{reservation != null ? getName(guest) : ''}</b>}
        />
      </GridItem>
    );
  };

  return (
    <GridContainer spacing={3} direction="column" style={{ minHeight: '60vh', padding: '18px' }}>
      <GridItem xs={12}>
        <DynamicTypography
          content={`${getVerbiage(
            verbiages,
            "weblbl050"
          )}`}
        ></DynamicTypography>
      </GridItem>

      <GridItem xs={12}>
        <Typography style={{ whiteSpace: 'pre-line' }} color="textPrimary" align="left" variant="body2">
          {`${getVerbiage(verbiages, 'weblbl051')}`}
        </Typography>
        <GridContainer direction="row">
          {selectedReservation != undefined ? generateRegistrationCardGuestConsent(selectedReservation) : null}
          {appConfig?.reservationConfig.isAddingNewGuestAllow &&
          selectedReservation?.sharedReservations != undefined &&
          selectedReservation?.sharedReservations.length > 0
            ? selectedReservation?.sharedReservations.map((shareReservation) => {
                return generateRegistrationCardGuestConsent(shareReservation);
              })
            : null}
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};
