import { Config, PreRegistrationRequestModel, Reservation } from 'models';
import { ReservationStatusList } from 'models/backend/constant/Reservation';
import {
  ScreenMarketingConsent,
  ScreenReservationList,
  ScreenReservationPreAuthPayment,
  ScreenReservationPaymentProcessing,
  ScreenReservationRegistration,
  ScreenReservationPayment,
  ScreenReservationRoomAssignment,
  ScreenReservationCheckIn,
  MobileApplicationMode
} from './constantHelper';

export function hasReservationCompletedVerification(reservation: Reservation, appConfig: Config): Boolean {
  //if health Declaration Required
  if(appConfig.reservationConfig.isHealthDeclarationRequired) {
    return reservation.verified && reservation.healthDeclaration !== undefined;
  } else {
    return reservation.verified
  }
}

export function hasAllReservtionCompletedVerification(reservation: Reservation, appConfig: Config): Boolean {
  //If adding new guest is allow check all the shared Reservation verification status.
  if (appConfig.reservationConfig.isAddingNewGuestAllow) {
    return (
      reservation != undefined &&
      hasReservationCompletedVerification(reservation, appConfig) &&
      reservation.sharedReservations?.map((shareRes) => hasReservationCompletedVerification(shareRes, appConfig) === true)
        .length === reservation.sharedReservations?.length
    );
  } else {
    return reservation != undefined && hasReservationCompletedVerification(reservation, appConfig);
  }
}

export function setupScreenFlow(
  selectedReservation: Reservation,
  screenFlow: string[],
  Reservations: Reservation[]
): string[] {
  var _screenFlow = screenFlow;

  if (selectedReservation != undefined && screenFlow.length > 0) {
    if (Reservations != undefined && Reservations.length == 1) {
      _screenFlow = _screenFlow.filter((screenName) => screenName !== ScreenReservationList);
    }

    if (selectedReservation.isPreRegistered) {
      _screenFlow = _screenFlow.filter((screenName) => screenName !== ScreenMarketingConsent);
      _screenFlow = _screenFlow.filter((screenName) => screenName !== ScreenReservationRegistration);
      _screenFlow = _screenFlow.filter((screenName) => screenName !== ScreenReservationPreAuthPayment);
      _screenFlow = _screenFlow.filter((screenName) => screenName !== ScreenReservationPaymentProcessing);
    }

    if(selectedReservation.reservationStatus == ReservationStatusList[2].value || 
      selectedReservation.reservationStatus == ReservationStatusList[3].value || 
      selectedReservation.reservationStatus == ReservationStatusList[4].value) {
      _screenFlow = _screenFlow.filter((screenName) => screenName !== ScreenReservationRoomAssignment); 
    }

    if(getApplicationMode() != MobileApplicationMode){
      _screenFlow = _screenFlow.filter((screenName) => screenName !== ScreenReservationCheckIn);
    }
  }

  return _screenFlow;
}

export function setupCheckOutScreenFlow(selectedReservation: Reservation, screenFlow: string[]): string[] {
  var _screenFlow = screenFlow;

  if (selectedReservation != undefined && screenFlow.length > 0) {
    if (selectedReservation.totalPrice === 0) {
      _screenFlow = _screenFlow.filter((screenName) => screenName !== ScreenReservationPayment);
      _screenFlow = _screenFlow.filter((screenName) => screenName !== ScreenReservationPaymentProcessing);
    }
  }

  return _screenFlow;
}

export function constructPreRegisterReservationModel(
  selectedReservation: Reservation,
  appConfig: Config
): PreRegistrationRequestModel {
  var sharerConfirmationNos :string [] =[];

  if(appConfig?.reservationConfig.isAddingNewGuestAllow) {
    sharerConfirmationNos = selectedReservation.sharedReservations?.map(x => x.confirmationNo) ?? []
  }

  var preRegistrationRequest : PreRegistrationRequestModel = {primaryConfirmationNo : selectedReservation.confirmationNo, shareConfirmationNos : sharerConfirmationNos}
  
  return preRegistrationRequest;
}

export function constructCheckoutReservationModel(
  selectedReservation: Reservation,
  appConfig: Config
): string[] {
  var _checkingOutConfirmationNos: string[] = [];

  _checkingOutConfirmationNos.push(selectedReservation.confirmationNo);

  if (appConfig.reservationConfig.isAddingNewGuestAllow) {
    selectedReservation.sharedReservations?.map((reservation) =>
    _checkingOutConfirmationNos.push(reservation.confirmationNo)
    );
  }

  return _checkingOutConfirmationNos;
}

export function getAdyenConfig(appConfig: Config) {

  //https://docs.adyen.com/payment-methods/cards/web-component#include-optional-configuration
  var config = {
    // storePaymentMethod: true,
    paymentMethodsConfiguration: {
      // ideal: {
      //   showImage: true
      // },
      card: {
        showBrandIcon: true,
        hasHolderName: true,
        holderNameRequired: true,
        // billingAddressRequired: true,
        hideCVC: false,
        positionHolderNameOnTop: false
        // autoFocus: false,
        // name: 'Credit or debit card',
        // amount: {
        //   value: 1000, // 10€ in minor units
        //   currency: 'SGD'
        // }
      }
    },
    locale: 'en_US',
    showPayButton: false,
    clientKey: appConfig.adyenPaymentConfig.clientKey, //'test_ROSXAM5WBNDPZNEOXDFMQ4CJMAGHDW4X',
    environment: appConfig.adyenPaymentConfig.environment //'test'
  };

  return config;
}

export function getApplicationMode() {
  return window.sessionStorage.getItem("mode");
}