import { PostErrorLogRequestModel, PostErrorLogResponseModel } from 'models';
import { getApiKeyForHotel, getSessionGuid, getToken, privateHttpClient } from '../utils/httpClient';

export const postErrorLog = async (request: PostErrorLogRequestModel): Promise<PostErrorLogResponseModel> => {
  const { data } = await privateHttpClient.post(
    '/api/ErrorLog',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel(),
        Session: getSessionGuid(),
        Platform: 'Web'
      }
    }
  );

  return data;
};
