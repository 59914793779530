//more info can be found here https://www.npmjs.com/package/react-pdf

import React, { useState } from 'react';
import { Document, Page, pdfjs, DocumentProps } from 'react-pdf';
import { PDFDocumentProxy  } from 'pdfjs-dist/types/src/display/api';

// import ControlPanel from './ControlPanel';

import Grid from '@material-ui/core/Grid';
import { GridItem, PdfControlPanel } from '../../components';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IProps extends DocumentProps {
  filename?: string | undefined;
  fileInBase64?: string | undefined;
}

export const PDFReader: React.FC<IProps> = ({ filename = 'file.pdf', file, fileInBase64, ...rest }) => {
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    const { numPages } = pdf;
    setNumPages(numPages);
  };

  const fileSource = file ? file : `data:application/pdf;base64,${fileInBase64}`;

  return (
    <>
      <Grid container direction="column" alignItems="center" justify="center" alignContent="center">
        <GridItem>
          <PdfControlPanel
            scale={scale}
            iconSize="small"
            setScale={setScale}
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            file={fileSource}
            filename={filename}
          />
        </GridItem>
        <GridItem>
          <Document file={fileSource} onLoadSuccess={onDocumentLoadSuccess} {...rest}>
            <Page pageNumber={pageNumber} scale={scale} />
          </Document>
        </GridItem>
      </Grid>
    </>
  );
};
