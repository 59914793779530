import {
  CreateGuestRequestModel,
  CreateGuestEmailRequestModel,
  UpdateGuestRequestModel,
  UpdateGuestEmailRequestModel,
  UpdateGuestResponseModel,
  CreateGuestEmailResponseModel,
  UpdateGuestEmailResponseModel,
  CreateGuestResponseModel
} from 'models';
import { getApiKeyForHotel, getToken, privateHttpClient } from 'utils/httpClient';

export const createGuestAPI = async (request: CreateGuestRequestModel): Promise<CreateGuestResponseModel> => {
  const { data } = await privateHttpClient.post(
    '/api/Guest',
    { ...request },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const updateGuestAPI = async (request: UpdateGuestRequestModel): Promise<UpdateGuestResponseModel> => {
  const { data } = await privateHttpClient.put(
    '/api/Guest',
    { ...request },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const createGuestEmailAPI = async (
  request: CreateGuestEmailRequestModel
): Promise<CreateGuestEmailResponseModel> => {
  const { data } = await privateHttpClient.post(
    '/api/Guest/Email',
    { ...request },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const updateGuestEmailAPI = async (
  request: UpdateGuestEmailRequestModel
): Promise<UpdateGuestEmailResponseModel> => {
  const { data } = await privateHttpClient.put(
    '/api/Guest/Email',
    { ...request },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};
