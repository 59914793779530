import React from 'react';
// import PDFPrinter from './PDFPrinter';

import { GridContainer, GridItem } from '../../components';
import { IconButton, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBackward,
  faFastBackward,
  faForward,
  faFastForward,
  faSearchMinus,
  faSearchPlus,
  faDownload,
  faUndoAlt,
  faRedoAlt,
  faBroom,
  faSignature
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  pageNumber: number;
  numPages: number;
  setPageNumber: (value: number) => void;
  scale: number;
  setScale: (value: number) => void;
  file: string;
  filename: string;
  iconSize: 'small' | 'medium' | undefined;
  onZoom?: (value: number) => void | undefined;
  onNextPage?: (value: number) => Promise<void> | void | undefined;
  onClear?: React.MouseEventHandler<HTMLElement> | undefined;
  onRedo?: React.MouseEventHandler<HTMLElement> | undefined;
  onUndo?: React.MouseEventHandler<HTMLElement> | undefined;
  setIsScribble?: (value: boolean) => void;
  scribbleOn?: boolean | undefined;
}

export const PdfControlPanel: React.FC<IProps> = ({
  pageNumber,
  numPages,
  setPageNumber,
  scale,
  setScale,
  file,
  filename,
  iconSize = "small",
  onZoom,
  onNextPage,
  onClear,
  onRedo,
  onUndo,
  scribbleOn,
  setIsScribble
}) => {
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const goToFirstPage = () => {
    if (!isFirstPage) {
      setPageNumber(1);
      if (onNextPage) onNextPage(1);
    }
  };
  const goToPreviousPage = () => {
    if (!isFirstPage) {
      setPageNumber(pageNumber - 1);
      if (onNextPage) onNextPage(pageNumber - 1);
    }
  };
  const goToNextPage = () => {
    if (!isLastPage) {
      setPageNumber(pageNumber + 1);
      if (onNextPage) onNextPage(pageNumber + 1);
    }
  };
  const goToLastPage = () => {
    if (!isLastPage) {
      setPageNumber(numPages);
      if (onNextPage) onNextPage(numPages);
    }
  };

  const onPageChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setPageNumber(Number(value));
    if (onNextPage) onNextPage(Number(value));
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
    if (onZoom) onZoom(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
    if (onZoom) onZoom(scale + 0.1);
  };

  const scribble = () => {
    if (setIsScribble) setIsScribble(!scribbleOn);
  };

  return (
    <>
      {numPages == 1 ? null : (
        <GridContainer spacing={2} justifyContent="space-evenly" alignItems="center">
          <GridItem>
            <IconButton size={iconSize} title="First Page" disabled={isFirstPage} onClick={goToFirstPage}>
              <FontAwesomeIcon icon={faFastBackward as IconProp} />
            </IconButton>
          </GridItem>
          <GridItem>
            <IconButton size={iconSize} title="Previous Page" disabled={isFirstPage} onClick={goToPreviousPage}>
              <FontAwesomeIcon icon={faBackward as IconProp} />
            </IconButton>
          </GridItem>
          <GridItem>
            <Typography variant="body1">
              Page{' '}
              <input
                name="pageNumber"
                type="number"
                min={1}
                max={numPages || 1}
                className="p-0 pl-1 mx-2"
                value={pageNumber}
                onChange={onPageChange}
              />{' '}
              of {numPages}
            </Typography>
          </GridItem>
          <GridItem>
            <IconButton size={iconSize} title="Next Page" disabled={isLastPage} onClick={goToNextPage}>
              <FontAwesomeIcon icon={faForward as IconProp} />
            </IconButton>
          </GridItem>
          <GridItem>
            <IconButton size={iconSize} title="Last Page" disabled={isLastPage} onClick={goToLastPage}>
              <FontAwesomeIcon icon={faFastForward as IconProp} />
            </IconButton>
          </GridItem>
        </GridContainer>
      )}
      <GridContainer spacing={2} justifyContent="center" alignItems="center">
        <GridItem>
          <IconButton size={iconSize} title="Zoom Out" disabled={isMinZoom} onClick={zoomOut}>
            <FontAwesomeIcon icon={faSearchMinus as IconProp} />
          </IconButton>
        </GridItem>
        <GridItem>
          <Typography variant="body1">{(scale * 100).toFixed()}%</Typography>
        </GridItem>
        <GridItem>
          <IconButton size={iconSize} title="Zoom In" disabled={isMaxZoom} onClick={zoomIn}>
            <FontAwesomeIcon icon={faSearchPlus as IconProp} />
          </IconButton>
        </GridItem>
        <GridItem>
          <a href={file} download={filename} title="Download Pdf" onClick={() => console.log('downloading....')}>
            <IconButton size={iconSize}>
              <FontAwesomeIcon icon={faDownload as IconProp} />
            </IconButton>
          </a>
        </GridItem>
        {onUndo ? (
          <GridItem>
            <IconButton size={iconSize} title="Undo" onClick={onUndo}>
              <FontAwesomeIcon icon={faUndoAlt as IconProp} />
            </IconButton>
          </GridItem>
        ) : null}
        {onRedo ? (
          <GridItem>
            <IconButton size={iconSize} title="Redo" onClick={onRedo}>
              <FontAwesomeIcon icon={faRedoAlt as IconProp} />
            </IconButton>
          </GridItem>
        ) : null}
        {onClear ? (
          <GridItem>
            <IconButton size={iconSize} title="Clear" onClick={onClear}>
              <FontAwesomeIcon icon={faBroom as IconProp} />
            </IconButton>
          </GridItem>
        ) : null}
        {setIsScribble ? (
          <GridItem>
            <IconButton size={iconSize} color={scribbleOn ? 'primary' : 'default'} title="Signature" onClick={scribble}>
              <FontAwesomeIcon icon={faSignature as IconProp} />
            </IconButton>
          </GridItem>
        ) : null}
      </GridContainer>
    </>
  );
};
