export const DocumentTypeList = [
  {
    value: '',
    label: 'Invalid'
  },
  {
    value: 'id',
    label: 'NRIC'
  },
  {
    value: 'fin',
    label: 'FIN'
  },
  {
    value: 'passport',
    label: 'Passport'
  },
  {
    value: 'unknown',
    label: 'UNKNOWN'
  }
];
