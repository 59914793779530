import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/state';

//Component
import { GridContainer, GridItem, CustomButton } from '../../components';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

//Helper
import { getVerbiage } from '../../utils/stringHelper';
import { getPreCheckOutBill, getReservationWithGuid, updateReservationState } from 'reducers/reservation';
import { ARIA_RESERVATION_NOT_FOUND, ARIA_SuccessCode } from 'models';
import { getCheckOutReservationByConfirmationIdAPI } from 'services/reservationService';
import { postErrorLog } from 'services/errorLogService';

interface IProps {
  reservationGuid: string | null;
  onCompleted: () => void;
}

export const SearchReservation: React.FC<IProps> = ({ children, reservationGuid, onCompleted, ...rest }) => {
  const dispatch = useDispatch();

  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { reservations, selectedReservation } = useSelector((state: RootState) => state.reservationState);

  const [message, setMessage] = React.useState('');

  const [searchName, setSearchName] = React.useState('');
  const [isSearchNameHelptext, setSearchNameHelptext] = React.useState('');

  const [searchRoomNoHelptext, setSearchRoomNoHelptext] = React.useState('');
  const [searchRoomNo, setSearchRoomNo] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(false);

  const setErrorState = () => {
    setMessage('Oposs... Something when wrong, please try again.');
    setIsLoading(false);
  };

  React.useEffect(() => {
    const searchReservation = async () => {
      if (selectedHotel != undefined) {
        if (reservations.length == 0) {
          if (reservationGuid !== null && reservationGuid != '') {
            setIsLoading(true);
            dispatch(getReservationWithGuid({ guid: reservationGuid }));
          }
        }
      }
    };

    searchReservation();
  }, []);

  React.useEffect(() => {
    if (reservations.length == 1 || reservations.length > 0) {
      if (selectedReservation != undefined && selectedHotel != undefined) {
        setIsLoading(true);

        if (selectedReservation.isCheckedIn && !selectedReservation.isCheckedOut) {
          dispatch(
            getPreCheckOutBill({
              request: { confirmationNo: selectedReservation.confirmationNo }
            })
          );
        } else if (selectedReservation.isCheckedOut) {
          setIsLoading(false);
          setMessage("Reservation has already checked out!");
        }
      }
    }
  }, [reservations]);

  React.useEffect(() => {
    if (
      selectedReservation != undefined &&
      selectedReservation.attachments != undefined
      // selectedReservation.attachments.length > 0
    ) {
      onCompleted();
    }
  }, [selectedReservation]);

  const handleSearchReservation = () => {
    if (searchName != '' && searchRoomNo != '') {
      if (selectedHotel !== undefined) {
        setIsLoading(true);

        getCheckOutReservationByConfirmationIdAPI(searchRoomNo, searchName)
          .then((response) => {
            if (response.resultCode === ARIA_SuccessCode) {
              dispatch(updateReservationState(response.result));
            } else {
              if (response.resultCode === ARIA_RESERVATION_NOT_FOUND) {
                setMessage(`${getVerbiage(verbiages, 'webmsg006')}`);
              } else {
                setErrorState();
              }

              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            postErrorLog({
              errorMessage: JSON.stringify(err)
            })
            setErrorState();
          });
      }
    } else {
      if (searchName == '') setSearchNameHelptext(`${getVerbiage(verbiages, 'webmsg001')}`);
      if (searchRoomNo == '') setSearchRoomNoHelptext(`${getVerbiage(verbiages, 'webmsg011')}`);

      setMessage(`${getVerbiage(verbiages, 'webmsg012')}`);
    }
  };

  const handleClose = () => {
    setMessage('');
  };

  return (
    <GridContainer
      spacing={1}
      direction="column"
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      style={{ minHeight: '60vh' }}
    >
      {isLoading ? (
        <GridItem>
          <CircularProgress />
        </GridItem>
      ) : (
        <>
          <GridItem xs={12}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={message !== ''}
              onClose={handleClose}
              autoHideDuration={5000}
              message={message}
              action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            />
          </GridItem>

          <GridItem xs={12}>
            <Typography color="textPrimary" align="center" variant="h6">
              {`${getVerbiage(verbiages, 'weblbl070')}`}
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <TextField
              required
              disabled={isLoading}
              error={isSearchNameHelptext != ''}
              helperText={isSearchNameHelptext}
              style={{ width: '90vw' }}
              id="outlined-required"
              label={`${getVerbiage(verbiages, 'weblbl002')}`}
              variant="outlined"
              value={searchName}
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  handleSearchReservation();
                }
              }}
              onChange={(e) => {
                if (e.currentTarget.value != '') {
                  setSearchNameHelptext('');
                }

                setSearchName(e.currentTarget.value);
              }}
            />
          </GridItem>

          <GridItem xs={12}>
            <Typography color="textPrimary" align="center" variant="h6">
              {'and'}
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <form noValidate autoComplete="off">
              <TextField
                required
                disabled={isLoading}
                error={searchRoomNoHelptext != ''}
                helperText={searchRoomNoHelptext}
                style={{ width: '90vw' }}
                id="outlined-required"
                label={`${getVerbiage(verbiages, 'weblbl071')}`}
                variant="outlined"
                value={searchRoomNo}
                onKeyDown={(e) => {
                  if (e.keyCode == 13) {
                    handleSearchReservation();
                  }
                }}
                onChange={(e) => {
                  if (e.currentTarget.value != '') {
                    setSearchRoomNoHelptext('');
                  }

                  setSearchRoomNo(e.currentTarget.value);
                }}
              />
            </form>
          </GridItem>
          <GridItem xs={12} style={{ marginTop: '32px' }}>
            <CustomButton color="primary" onClick={handleSearchReservation}>{`${getVerbiage(
              verbiages,
              'webbtn003'
            )}`}</CustomButton>
          </GridItem>
        </>
      )}
    </GridContainer>
  );
};
