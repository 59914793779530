import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/state';

//Component
import { DynamicTypography, GridContainer, GridItem } from '../../components';
import Typography from '@material-ui/core/Typography';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Radio,
  CircularProgress,
  FormControlLabel
} from '@material-ui/core';

import AdyenCheckout from '@adyen/adyen-web';
import Core from '@adyen/adyen-web/dist/types/core';
import '@adyen/adyen-web/dist/adyen.css';

//Helper
import { getVerbiage, convertNumberToAmount } from '../../utils/stringHelper';
import { getPreAuthInfo, resetPaymentState, updateCheckoutSession, updatePaymentResponse } from 'reducers/payment';
import { PAYMENT_CODE_AUTHORISED } from 'models/backend/constant/Payment';
import { getAdyenConfig } from 'utils/applicationHelper';
import { createCheckoutPaymentSessionAPI, createPreAuthPaymentAPI } from 'services/paymentService';
import { ARIA_SuccessCode } from 'models';
import { postErrorLog } from 'services/errorLogService';

interface IProps {
  submitPay: Boolean;
  onPayingWithPreAuthCard: (isCompleted: boolean) => void;
  onValidationCompleted: (isCompleted: boolean) => void;
  onResetPayment: () => void;
  onPaymentCompleted: () => void;
}

export const CheckoutPayment: React.FC<IProps> = ({
  submitPay,
  onPayingWithPreAuthCard,
  onValidationCompleted,
  onResetPayment,
  onPaymentCompleted
}) => {
  const dispatch = useDispatch();

  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { selectedReservation } = useSelector((state: RootState) => state.reservationState);
  const { checkoutSession, paymentResponse, preAuthCardInfo } = useSelector((state: RootState) => state.paymentState);

  const paymentContainer = useRef(null);
  const redirectContainer = useRef(null);

  const [isPayingWithPreAuthCardChecked, setIsPayingWithPreAuthCardChecked] = React.useState(false);
  const [isPayingWithNewCardChecked, setIsPayingWithNewCardChecked] = React.useState(false);

  const [data, setData] = React.useState<any | null>(null);
  const [adyenCheckout, setAdyenCheckout] = React.useState<Core | null>(null);

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const searchReservation = async () => {
      if (selectedHotel != undefined && selectedReservation != undefined) {
        dispatch(getPreAuthInfo({ request: { confirmationNo: selectedReservation.confirmationNo } }));
      }
    };

    searchReservation();
  }, []);

  // React.useEffect(() => {
  //   if (preAuthCardInfo != undefined) {
  //     onPayingWithPreAuthCard(true);
  //     setIsPayingWithPreAuthCardChecked(true);
  //   }
  // }, [preAuthCardInfo]);

  React.useEffect(() => {
    console.log('createCheckout');

    const createCheckout = async () => {
      if (appConfig != undefined) {
        var config = getAdyenConfig(appConfig);

        if (selectedHotel != undefined && selectedReservation != undefined && checkoutSession == undefined) {
          setIsLoading(true);
          createCheckoutPaymentSessionAPI({
            confirmationNo: selectedReservation.confirmationNo
          })
            .then((response) => {
              if (response.resultCode === ARIA_SuccessCode) {
                dispatch(updateCheckoutSession(response.result));
              } else {
                alert(getVerbiage(verbiages, 'webmsg014'));
                // alert('Oposs... Something when wrong, please try again.');
              }

              setIsLoading(false);
            })
            .catch((err) => {
              alert(getVerbiage(verbiages, 'webmsg014'));
              postErrorLog({
                confirmationNo: selectedReservation?.confirmationNo,
                errorMessage: JSON.stringify(err)
              })
              // alert('Oposs... Something when wrong, please try again.');
              setIsLoading(false);
            });

          // dispatch(
          //   createCheckoutPaymentSession({
          //     request: { hotelId: selectedHotel.id, confirmationNo: selectedReservation.confirmationNo }
          //   })
          // );
        } else if (
          selectedHotel != undefined &&
          selectedReservation != undefined &&
          checkoutSession != undefined &&
          paymentContainer != null
        ) {
          const checkout = await AdyenCheckout({
            ...config,
            session: checkoutSession,
            onPaymentCompleted: (response: any, _component: any) => {
              console.log('onPaymentCompleted');
              console.log(response);
            },
            onError: (error: any, _component: any) => {
              console.log('onError');
              console.error(error);
              postErrorLog({
                confirmationNo: selectedReservation?.confirmationNo,
                errorMessage: JSON.stringify(error)
              })
            },
            onChange: (state: any, component: any) => {
              if (state.isValid) {
                onValidationCompleted(true);
                setData(state.data);
              } else {
                onValidationCompleted(false);
              }
            },
            onAdditionalDetails: (state: any, component: any) => {
              console.log('onAdditionalDetails');
              console.log(state);
            },
            //return when submit button is triggered
            onSubmit: (state: any, component: any) => {
              setIsLoading(true);

              createPreAuthPaymentAPI({
                ...state,
                confirmationNo: selectedReservation?.confirmationNo
              })
                .then((response) => {
                  if (response.resultCode == ARIA_SuccessCode) {
                    console.log('paymentResponse');
                    dispatch(updatePaymentResponse(response.result));
                  } else {
                    setIsLoading(false);
                    alert(getVerbiage(verbiages, 'webmsg014'));
                    // alert('Oposs... Something when wrong, please try again.');
                  }
                })
                .catch((err) => {
                  setIsLoading(false);
                  alert(getVerbiage(verbiages, 'webmsg014'));
                  postErrorLog({
                    confirmationNo: selectedReservation?.confirmationNo,
                    errorMessage: JSON.stringify(err)
                  })
                  // alert('Oposs... Something when wrong, please try again.');
                });

              // dispatch(
              //   createPreAuthPayment({
              //     request: { ...state, hotelId: selectedHotel.id, confirmationNo: selectedReservation?.confirmationNo }
              //   })
              // );
            }
          });

          if (paymentContainer.current) {
            setAdyenCheckout(checkout);
            checkout.create('card').mount(paymentContainer.current);
          }
        }
      }
    };

    createCheckout();
  }, [dispatch, checkoutSession, selectedHotel, paymentContainer]);

  React.useEffect(() => {
    console.log('adyenCheckout');
    if (submitPay) {
      if (isPayingWithPreAuthCardChecked && selectedHotel != undefined && selectedReservation != undefined) {
        console.log('Paying with Pre Auth Card ');
        onPaymentCompleted();
      } else if (!isPayingWithPreAuthCardChecked && adyenCheckout != null) {
        adyenCheckout.submitPayment(data);
      }
    }
  }, [submitPay]);

  React.useEffect(() => {
    console.log('paymentResponse');

    if (paymentResponse != undefined) {
      if (paymentResponse.code === PAYMENT_CODE_AUTHORISED) {
        // completed payment
        // without 3ds 
        onPaymentCompleted();
      } else if (paymentResponse.action != null) {
        console.log(paymentResponse.action);

        if (
          adyenCheckout != null &&
          redirectContainer != null &&
          paymentResponse.action.method.toLocaleUpperCase() === 'POST'
        ) {
          adyenCheckout.createFromAction(paymentResponse.action).mount('#redirect-container');
        }
      } else {
        //Invalid card, refusal card
        alert(getVerbiage(verbiages, 'webmsg013'));
        onResetPayment();
        onValidationCompleted(false);
        dispatch(resetPaymentState());
      }
    }
  }, [paymentResponse]);

  const handleIsPayingWithPreAuthCardChange = () => {
    // const a = !isPayingWithPreAuthCardChecked;

    if (isPayingWithNewCardChecked) {
      setIsPayingWithNewCardChecked(false);
    }

    if (!isPayingWithPreAuthCardChecked) {
      onPayingWithPreAuthCard(true);
      setIsPayingWithPreAuthCardChecked(true);
    }
  };

  const handleIsPayingWithNewCardChange = () => {
    // const a = !isPayingWithNewCardChecked;

    if (isPayingWithPreAuthCardChecked) {
      setIsPayingWithPreAuthCardChecked(false);
    }

    if (!isPayingWithNewCardChecked) {
      onPayingWithPreAuthCard(false);
      setIsPayingWithNewCardChecked(true);
    }
  };

  return (
    <GridContainer
      spacing={1}
      alignItems="center"
      justifyContent="center"
      direction="row"
      style={{ paddingTop: '24px', paddingLeft: '18px', paddingRight: '18px' }}
    >
      {adyenCheckout != null ? (
        <>
          <GridItem xs={12}>
            <DynamicTypography
              color="textPrimary"
              align="center"
              variant="body2"
              content={`${getVerbiage(verbiages, 'weblbl091')}`}
            ></DynamicTypography>
          </GridItem>

          <GridItem xs={12} style={{ marginTop: '16px', marginBottom: '16px' }}>
            <Typography color="textPrimary" align="center" variant="h4">
              <b>{`${selectedReservation?.currency} ${convertNumberToAmount(selectedReservation?.totalPrice)}`}</b>
            </Typography>
          </GridItem>

          <GridItem xs={12}>
            <Typography color="textSecondary" align="left" variant="subtitle1">
              {`${getVerbiage(verbiages, 'weblbl075')}`}
            </Typography>
          </GridItem>
        </>
      ) : null}

      {preAuthCardInfo !== undefined &&
      preAuthCardInfo.cardType != null &&
      preAuthCardInfo.cardNo != null &&
      !isLoading ? (
        <GridItem xs={12}>
          <Card>
            <GridContainer
              onClick={handleIsPayingWithPreAuthCardChange}
              alignItems="center"
              style={{ height: '66px', paddingLeft: '4px', paddingRight: '4px' }}
            >
              <Radio
                color="primary"
                checked={isPayingWithPreAuthCardChecked}
                onChange={handleIsPayingWithPreAuthCardChange}
              />
              <DynamicTypography
                color="textPrimary"
                align="left"
                variant="body1"
                content={`${preAuthCardInfo.cardType.toLocaleUpperCase()} ${preAuthCardInfo.cardNo}`}
              ></DynamicTypography>
            </GridContainer>
          </Card>
        </GridItem>
      ) : null}

      {isLoading ? (
        <GridItem>
          <CircularProgress />
        </GridItem>
      ) : (
        <GridItem xs={12}>
          <Accordion expanded={isPayingWithNewCardChecked}>
            <AccordionSummary onClick={handleIsPayingWithNewCardChange}>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={handleIsPayingWithNewCardChange}
                onFocus={handleIsPayingWithNewCardChange}
                control={<Radio color="primary" checked={isPayingWithNewCardChecked} />}
                label={`${getVerbiage(verbiages, 'weblbl076')}`}
              />
            </AccordionSummary>
            <AccordionDetails>
              <div id="dropin-container" style={{ margin: '0 auto' }}>
                <div ref={paymentContainer} className="payment"></div>
              </div>
            </AccordionDetails>
          </Accordion>
        </GridItem>
      )}

      <div id="redirect-container">
        <div ref={redirectContainer} className="redirect"></div>
      </div>
    </GridContainer>
  );
};
