import { getApplicationMode } from 'utils/applicationHelper';
import { MobileApplicationMode } from 'utils/constantHelper';
import { BaseMessage, RequestForMobileKeyMessage, ReservationMessage } from './model';

const CLEARRESERVATION = "CLEARRESERVATION";
const CURRENTRESERVATION = "CURRENTRESERVATION";
const ACTIVATEMOBILEKEY = "ACTIVATEMOBILEKEY";

export const sendToMobileMessageClearReservation = () => {
  try {
    if (getApplicationMode() == MobileApplicationMode) {
      var reservationMessage: BaseMessage = {
        type: CLEARRESERVATION
      };

      var jsonMessage = JSON.stringify(reservationMessage);
  
      window.flutter_inappwebview.callHandler("MobileMessageChannel", [jsonMessage]);
    }
  } catch (error) {
  }
};

export const sendToMobileMessageChannelCurrentReservation = (guid: string) => {
  try {
    if (getApplicationMode() == MobileApplicationMode) {
      var reservationMessage: ReservationMessage = {
        type: CURRENTRESERVATION,
        data: {
            guid
        }
      };

      var jsonMessage = JSON.stringify(reservationMessage);

      // window.MobileMessageChannel.postMessage(jsonMessage);
      window.flutter_inappwebview.callHandler("MobileMessageChannel", [jsonMessage]);
    }
  } catch (error) {}
};

export const sendToMobileChannelActivateMobileKey = (activationCode: string) => {
  try {
    if (getApplicationMode() == MobileApplicationMode) {
      var requestForMobileKey: RequestForMobileKeyMessage = {
        type: ACTIVATEMOBILEKEY,
        data: {
          activationCode: activationCode
        }
      };

      var requestInJson = JSON.stringify(requestForMobileKey);
      // window.MobileMessageChannel.postMessage(requestInJson);
      window.flutter_inappwebview.callHandler("MobileMessageChannel", [requestInJson]);
    }
  } catch (error) {}
};
