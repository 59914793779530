import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { RootState } from '../../store/state';

//Component
import { GridContainer, GridItem, MainContainer, NavBar, CustomButton, DynamicTypography } from '../../components';

//Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import CloseIcon from '@material-ui/icons/Close';

//Helper
import { getVerbiage } from '../../utils/stringHelper';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { resetReservationState, updateReservationState } from 'reducers/reservation';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton, Snackbar } from '@material-ui/core';
import { ARIA_NOT_FOUND, ARIA_SuccessCode } from 'models';
import { ReservationStatusList } from 'models/backend/constant/Reservation';
import { getReservationByGuidEAPI } from 'services/reservationService';
import { postErrorLog } from 'services/errorLogService';

export const Main = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, _] = useSearchParams();

  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { selectedReservation } = useSelector((state: RootState) => state.reservationState);

  const [resGuid] = React.useState(searchParams.get('resKey'));
  const [mode] = React.useState(searchParams.get('mode'));
  const [message, setMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const setMode = () => {
      if (mode !== null) {
        console.log('setting Mode');
        window.sessionStorage.setItem('mode', mode);
      }
    };

    setMode();
  }, [mode]);

  React.useEffect(() => {
    const searchReservation = () => {
      if (resGuid !== null && selectedHotel != undefined) {
        console.log('Main-searchReservation');
        setIsLoading(true);

        getReservationByGuidEAPI(resGuid)
          .then((response) => {
            if (response.resultCode === ARIA_SuccessCode) {
              dispatch(updateReservationState(response.result));
            } else if (response.resultCode === ARIA_NOT_FOUND) {
              setMessage(`${getVerbiage(verbiages, 'webmsg006')}`);
            } else {
              alert(getVerbiage(verbiages, 'webmsg014'));
              // alert('Oposs... Something when wrong, please try again.');
            }

            setIsLoading(false);
          })
          .catch((err) => {
            alert(getVerbiage(verbiages, 'webmsg014'));
            postErrorLog({
              errorMessage: JSON.stringify(err)
            })
            // alert('Oposs... Something when wrong, please try again.');
            setIsLoading(false);
          });
      }
    };

    searchReservation();
  }, [resGuid, selectedHotel]);

  React.useEffect(() => {
    const redirectToCheckInOrCheckOut = () => {
      if (selectedReservation !== undefined && selectedHotel !== undefined) {
        console.log('redirectToCheckInOrCheckOut');
        var param = window.location.search;

        if (
          (selectedReservation.isPreRegistered &&
            selectedReservation.isCheckedIn &&
            !selectedReservation.isCheckedOut &&
            selectedReservation.reservationStatus.toUpperCase() == ReservationStatusList[2].value) ||
          (!selectedReservation.isPreRegistered &&
            selectedReservation.isCheckedIn &&
            !selectedReservation.isCheckedOut &&
            selectedReservation.reservationStatus.toUpperCase() == ReservationStatusList[2].value)
        ) {
          navigate(`/${selectedHotel.code}/checkout${param}`);
        } else if (
          (!selectedReservation.isCheckedIn &&
            !selectedReservation.isPreRegistered &&
            !selectedReservation.isCheckedOut) ||
          (!selectedReservation.isCheckedIn && selectedReservation.isPreRegistered && !selectedReservation.isCheckedOut)
        ) {
          navigate(`/${selectedHotel.code}/checkin${param}`);
        } else if (
          selectedReservation.reservationStatus.toUpperCase() == ReservationStatusList[1].value ||
          selectedReservation.reservationStatus.toUpperCase() == ReservationStatusList[6].value
        ) {
          navigate(`/${selectedHotel.code}/checkin${param}`);
        } else {
          setMessage(`${getVerbiage(verbiages, 'webmsg006')}`);
        }
      }
    };

    redirectToCheckInOrCheckOut();
  }, [selectedReservation]);

  const checkInOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (selectedHotel != undefined) {
      dispatch(resetReservationState());
      navigate(`/${selectedHotel.code}/checkin`);
    } else {
      navigate('/');
    }
  };

  const checkOutOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (selectedHotel != undefined) {
      dispatch(resetReservationState());
      navigate(`/${selectedHotel.code}/checkout`);
    } else {
      navigate('/');
    }
  };

  const handleClose = () => {
    setMessage('');
  };

  return (
    <MainContainer backgroundImage={appConfig?.hotelConfig.backgroundImageUrl}>
      {isLoading ? (
        <GridContainer
          direction="row"
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <GridItem>
            <CircularProgress />
          </GridItem>
        </GridContainer>
      ) : (
        <>
          <NavBar />
          <GridItem xs={12}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={message !== ''}
              onClose={handleClose}
              autoHideDuration={5000}
              message={message}
              action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            />
          </GridItem>

          <GridContainer
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="flex-start"
            style={{ minHeight: '85vh' }}
          >
            <GridItem xs={12}>
              <DynamicTypography
                align="center"
                variant="h3"
                style={{ backgroundColor: '#ffffff50' }}
                content={`${getVerbiage(verbiages, 'weblbl001')}`}
              ></DynamicTypography>
            </GridItem>
            <GridContainer spacing={2} direction="column" justifyContent="center" alignContent="center">
              <GridItem xs={12} sm={12} md={3}>
                <CustomButton startIcon={<FontAwesomeIcon icon={faSignInAlt as IconProp} />} onClick={checkInOnClick}>
                  {`${getVerbiage(verbiages, 'webbtn001')}`}
                </CustomButton>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomButton startIcon={<FontAwesomeIcon icon={faSignOutAlt as IconProp} />} onClick={checkOutOnClick}>
                  {`${getVerbiage(verbiages, 'webbtn002')}`}
                </CustomButton>
              </GridItem>
            </GridContainer>
          </GridContainer>
        </>
      )}
      <Outlet />
    </MainContainer>
  );
};
