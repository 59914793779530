import { GetVerbiageRequestModel } from 'models';
import { getApiKeyForHotel, getToken, privateHttpClient } from '../utils/httpClient';

export const getVerbiageList = async (request: GetVerbiageRequestModel, index: number) => {
  const { data } = await privateHttpClient.get('/api/verbiage', {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      ApiKey: getApiKeyForHotel()
    },
    params: {
      filter: `languageId eq ${request.languageId}`,
      pageSize: 100,
      pageIndex: index
    }
  });

  return data;
};
