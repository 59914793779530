import { createTheme } from '@material-ui/core/styles';
import { ThemeConfiguration } from 'models';

// Font Family
// '-apple-system',
// 'BlinkMacSystemFont',
// '"Segoe UI"',
// 'Roboto',
// '"Helvetica Neue"',
// 'Arial',
// 'sans-serif',
// '"Apple Color Emoji"',
// '"Segoe UI Emoji"',
// '"Segoe UI Symbol"',

export function getThemes(themeConfig: ThemeConfiguration) {

  return createTheme({
    palette: {
      type: 'light',
      primary: {
        main: themeConfig.primaryButton
      },
      text: {
        primary: themeConfig.primaryText,
        secondary: themeConfig.secondaryText
      }
    },
    typography: {
        fontFamily: themeConfig.fontFamily
    },
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          boxShadow: 'none',
          backgroundColor: 'white'
        }
      },
      MuiMobileStepper : {
        root: {
          backgroundColor: 'transparent'
        }
      },
    }
  });
}

export const defaultTheme = createTheme({
  // palette: {
  //   type: 'light',
  //   primary: {
  //     main: '#17694B'
  //   },
  //   text: {
  //     primary: '#002627',
  //     secondary: '#00855C'
  //   }
  // },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        boxShadow: 'none',
        backgroundColor: 'white'
      }
    },
    MuiMobileStepper : {
      root: {
        backgroundColor: 'transparent'
      }
    },
  }
});

export const defaultConfig = {
  backgroundImage: 'https://images.unsplash.com/photo-1497262769208-546fd71d72ca?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80'
  // backgroundImage: 'https://images.unsplash.com/photo-1561700398-b25aeb4454fc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80'
  // backgroundImage: 'https://images.unsplash.com/photo-1590520181753-3fff75292722?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80'
  // backgroundImage: 'https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1859&q=80'
};
