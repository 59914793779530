import moment from 'moment';

export function getDate(date: Date) {
  return moment(date).format('DD-MMMM-YYYY');
}

export function getShortDate(date: Date) {
  return moment(date).format('DD-MMM-YYYY');
}

export function getTime(date: Date) {
  return moment(date).format('HH:mm');
}

export function subtractDay(date: Date, day: number) {
  return moment(date).subtract(day, 'day').toDate();
}

export function addDay(date: Date, day: number) {
  return moment(date).add(day, 'day').toDate();
}

export function calculateAge(date: Date) {
  return moment().diff(moment(date, 'DD/MM/YYYY'), 'years');
}

export function convertStringDateToServerFormat(date: Date): Date {
  return new Date(moment(moment(date).format('YYYY-MM-DD')).toLocaleString());
}