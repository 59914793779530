import {
  CreateHealthDeclarationRequestModel,
  CreateHealthDeclarationResponseModel,
  GetHealthDeclarationRequestModel,
  GetHealthDeclarationResponseModel,
  UpdateHealthDeclarationRequestModel,
  UpdateHealthDeclarationResponseModel
} from 'models';
import { getApiKeyForHotel, getToken, privateHttpClient } from 'utils/httpClient';

export const createHealthDeclarationAPI = async (
  request: CreateHealthDeclarationRequestModel
): Promise<CreateHealthDeclarationResponseModel> => {
  const { data } = await privateHttpClient.post(
    '/api/healthDeclaration',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const updateHealthDeclarationAPI = async (
  request: UpdateHealthDeclarationRequestModel
): Promise<UpdateHealthDeclarationResponseModel> => {
  const { data } = await privateHttpClient.put(
    '/api/healthDeclaration',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const getHealthDeclarationAPI = async (
  request: GetHealthDeclarationRequestModel
): Promise<GetHealthDeclarationResponseModel> => {
  const { data } = await privateHttpClient.get(`/api/healthDeclaration/reservation`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      ApiKey: getApiKeyForHotel()
    },
    params: {
      ...request
    }
  });

  return data;
};
