import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getVerbiageList } from '../services/verbiagesService';
import { RootState } from '../store/state';
import { AxiosError } from 'axios';
import { ResponseModel, GetVerbiageResponseModel, GetVerbiageRequestModel } from 'models';

const initialState: RootState.VerbiageState = {
  verbiages: [],
  error: null,
  isLoading: false
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchVerbiageList = createAsyncThunk<
  GetVerbiageResponseModel,
  { request: GetVerbiageRequestModel },
  { rejectValue: AxiosError<Error> }
>('verbiage/getVerbiages', async (input, thunkApi) => {
  const { request } = input;
  try {
    var pageIndex = 1;

    let data : GetVerbiageResponseModel = await getVerbiageList(request, pageIndex);
  
    let tempData = data.result.items;

    while(data.result.pageCount > pageIndex) {
      pageIndex++;
      let nextData : GetVerbiageResponseModel = await getVerbiageList(request, pageIndex);

      tempData.push(...nextData.result.items);

    }

    data.result.items = tempData;

    return data;
  } catch (err) {
    let error = err as AxiosError<Error>; // cast the error for access
    if (!error.response) {
      throw err;
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return thunkApi.rejectWithValue(error);
  }
});

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const verbiageSlice = createSlice({
  name: 'verbiage',
  initialState,
  reducers: {
    //local reducer
    resetVerbiageState() {
      return initialState;
    }
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(fetchVerbiageList.fulfilled, (state, { payload }) => {
      // var a = Object.assign({}, ...payload.Labels.map(({Code, Description}) => ({[Code]: Description})));
      // new Map(payload.Labels.map(x => [x.Code, x.Description]))
      state.verbiages = payload.result.items;

      const serverError: ResponseModel = {
        resultCode: payload.resultCode,
        resultDescription: payload.resultDescription
      };

      state.error = serverError;
      state.isLoading = false;
    }),
      builder.addCase(fetchVerbiageList.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchVerbiageList.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? '',
            resultDescription: error?.response?.data.message ?? ''
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });
  }
});

export const { resetVerbiageState } = verbiageSlice.actions;

export default verbiageSlice.reducer;
