import { AuthenticationResponseModel } from 'models';
import { publicHttpClient } from '../utils/httpClient';

export async function getAuthenticationToken() : Promise<AuthenticationResponseModel> {
  const { data } = await publicHttpClient.post('/api/account/login', {
      username: `${process.env.REACT_APP_API_USERNAME}`,
      password: '123456$dA'
  });
  return data;
};
