import { ARIA_SuccessCode } from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { RootState } from 'reducers';

import { getDigitalPassAPI } from 'services/digitalPassService';
import { postErrorLog } from 'services/errorLogService';

import { MainContainer, GridContainer, GridItem } from '../../components';
// import { CircularProgress } from '@material-ui/core';

export const Walletpass = () => {
  const [searchParams, _] = useSearchParams();
    const navigate = useNavigate();
  //   const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);

  const [resGuid] = React.useState(searchParams.get('resKey'));

  React.useEffect(() => {
    const getDigitalPass = () => {
      if (resGuid !== "" && resGuid !== null && selectedHotel !== undefined) {
        getDigitalPassAPI({ GUID: resGuid })
          .then((response) => {
            if (response.resultCode == ARIA_SuccessCode) {
              // Convert Base64 string to binary data
              const binaryString = atob(response.result.contentBase64);

              // Convert binary data to ArrayBuffer
              const arrayBuffer = new ArrayBuffer(binaryString.length);
              const uint8Array = new Uint8Array(arrayBuffer);
              for (let i = 0; i < binaryString.length; i++) {
                uint8Array[i] = binaryString.charCodeAt(i);
              }

              // Create Blob from ArrayBuffer
              const blob = new Blob([arrayBuffer], { type: response.result.contentType });
              // const blob = new Blob([arrayBuffer], { type: "application/vnd.apple.pkpass" });

              // Create URL from Blob
              const url = URL.createObjectURL(blob);

              // Create anchor element to trigger download
              const a = document.createElement('a');
              a.href = url;
              a.download = response.result.filename;

              // Trigger download
              a.click();

              // Revoke URL to free up memory
              URL.revokeObjectURL(url);
            //   window.open('about:blank', '_self');
            //   window.close();
            } else {
              postErrorLog({
                errorMessage: JSON.stringify(response)
              });
              navigate(`/404`);
            }
          })
          .catch((err) => {
            postErrorLog({
              errorMessage: JSON.stringify(err)
            });
            navigate(`/404`);
          });
      }
    };

    getDigitalPass();
  }, [resGuid, selectedHotel]);

  return (
    <MainContainer>
      <GridContainer
        direction="row"
        spacing={0}
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <GridItem>
          {/* <CircularProgress /> */}
        </GridItem>
      </GridContainer>
    </MainContainer>
  );
};
