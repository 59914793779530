import { getApiKeyForHotel, getToken, privateHttpClient } from '../utils/httpClient';

export const getAppConfiguration = async () => {
  const { data } = await privateHttpClient.get('/api/Configuration/webappconfig', {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      ApiKey: getApiKeyForHotel()
    }
  });
  return data;
};
