export const ReservationStatusList = [
  {
    value: 'DUEIN',
    label: 'Due in'
  },
  {
    value: 'INHOUSE',
    label: 'In House'
  },
  {
    value: 'DUEOUT',
    label: 'Due out'
  },
  {
    value: 'CHECKEDOUT',
    label: 'Checked Out'
  },
  {
    value: 'CANCELLED',
    label: 'Cancelled'
  },
  {
    value: 'RESERVED',
    label: 'Reserved'
  },
  {
    value: 'WALKIN',
    label: 'In House'
  },
];