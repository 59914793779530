import { ScanDocument, DocumentModel, Country } from 'models';
import { getNameByCountryCode3 } from './countryHelper';

export function combineFrontAndBackDocument(
  frontDocumentInfo: ScanDocument,
  backDocumentInfo: ScanDocument
): ScanDocument {
  var front = frontDocumentInfo;
  var back = backDocumentInfo;

  if (front.Address1 == undefined || front.Address1 == null || front.Address1 == '') {
    front.Address1 = back.Address1;
  }

  if (front.Address2 == undefined || front.Address2 == null || front.Address2 == '') {
    front.Address2 = back.Address2;
  }

  if (front.Address3 == undefined || front.Address3 == null || front.Address3 == '') {
    front.Address3 = back.Address3;
  }

  if (front.Address4 == undefined || front.Address4 == null || front.Address4 == '') {
    front.Address4 = back.Address4;
  }

  if (front.AltFirstName == undefined || front.AltFirstName == null || front.AltFirstName == '') {
    front.AltFirstName = back.AltFirstName;
  }

  if (front.AltLanguage == undefined || front.AltLanguage == null || front.AltLanguage == '') {
    front.AltLanguage = back.AltLanguage;
  }

  if (front.AltLastName == undefined || front.AltLastName == null || front.AltLastName == '') {
    front.AltLastName = back.AltLastName;
  }

  if (front.BirthDate == undefined || front.BirthDate == null || front.BirthDate == new Date('0001-01-01')) {
    front.BirthDate = back.BirthDate;
  }

  if (front.City == undefined || front.City == null || front.City == '') {
    front.City = back.City;
  }

  if (front.Country == undefined || front.Country == null || front.Country == '') {
    front.Country = back.Country;
  }

  if (front.Ethnicity == undefined || front.Ethnicity == null || front.Ethnicity == '') {
    front.Ethnicity = back.Ethnicity;
  }

  if (
    front.ExpirationDate == undefined ||
    front.ExpirationDate == null ||
    front.ExpirationDate == new Date('0001-01-01')
  ) {
    front.ExpirationDate = back.ExpirationDate;
  }

  if (front.PortraitImage == undefined || front.PortraitImage == null || front.PortraitImage == '') {
    front.PortraitImage = back.PortraitImage;
  }

  if (front.FirstName == undefined || front.FirstName == null || front.FirstName == '') {
    front.FirstName = back.FirstName;
  }

  if (front.IDNumber == undefined || front.IDNumber == null || front.IDNumber == '') {
    front.IDNumber = back.IDNumber;
  }

  if (front.DocumentNumber == undefined || front.DocumentNumber == null || front.DocumentNumber == '') {
    front.DocumentNumber = back.DocumentNumber;
  }

  if (front.IDType == undefined || front.IDType == null || front.IDType == '') {
    front.IDType = back.IDType;
  }

  if (front.DocumentImage == undefined || front.DocumentImage == null || front.DocumentImage == '') {
    front.DocumentImage = back.DocumentImage;
  }

  if (front.IssueCountry == undefined || front.IssueCountry == null || front.IssueCountry == '') {
    front.IssueCountry = back.IssueCountry;
  }

  if (front.IssueDate == undefined || front.IssueDate == null || front.IssueDate == new Date('0001-01-01')) {
    front.IssueDate = back.IssueDate;
  }

  if (front.LastName == undefined || front.LastName == null || front.LastName == '') {
    front.LastName = back.LastName;
  }

  if (front.MiddleName == undefined || front.MiddleName == null || front.MiddleName == '') {
    front.MiddleName = back.MiddleName;
  }

  if (front.Nationality == undefined || front.Nationality == null || front.Nationality == '') {
    front.Nationality = back.Nationality;
  }

  if (front.NationalityLong == undefined || front.NationalityLong == null || front.NationalityLong == '') {
    front.NationalityLong = back.NationalityLong;
  }

  if (front.PersonalNumber == undefined || front.PersonalNumber == null || front.PersonalNumber == '') {
    front.PersonalNumber = back.PersonalNumber;
  }

  if (front.PlaceOfBirth == undefined || front.PlaceOfBirth == null || front.PlaceOfBirth == '') {
    front.PlaceOfBirth = back.PlaceOfBirth;
  }

  if (front.PlaceOfIssue == undefined || front.PlaceOfIssue == null || front.PlaceOfIssue == '') {
    front.PlaceOfIssue = back.PlaceOfIssue;
  }

  if (front.Gender == undefined || front.Gender == null || front.Gender == '') {
    front.Gender = back.Gender;
  }

  if (front.Title == undefined || front.Title == null || front.Title == '') {
    front.Title = back.Title;
  }

  //Put both images into a list.

  if (front.Images == undefined || front.Images == null) {
    front.Images = [];
  }

  if (front.DocumentImage) {
    front.Images.push(front.DocumentImage);
  }

  if (back.DocumentImage) {
    front.Images.push(back.DocumentImage);
  }

  return front;
}

export function ConvertToScanDocument(document: DocumentModel, _countryList: Country[]): ScanDocument {
  return {
    Address1: document.address1,
    Address2: document.address2,
    Address3: document.address3,
    Address4: document.address4,
    City: document.city,
    Country: document.country,
    Province: document.province,
    State: document.state,
    PostalCode: document.postalCode,
    AltFirstName: document.firstName,
    AltLanguage: document.altLanguage,
    AltLastName: document.altLastName,
    FirstName: document.firstName,
    MiddleName: document.middleName,
    LastName: document.lastName,
    Title: document.title,
    DocumentNumber: document.documentNumber,
    ExpirationDate: document.expirationDate,
    IDNumber: document.idNumber,
    IDType: document.idType,
    IssueCountry: document.issueCountry,
    IssueCountryLong: getNameByCountryCode3(document.issueCountry, _countryList),
    IssueDate: document.issueDate,
    PlaceOfIssue: document.placeOfIssue,
    PlaceOfBirth: document.placeOfBirth,
    BirthDate: document.birthDate,
    Ethnicity: document.ethnicity,
    Gender: document.sex,
    Nationality: document.nationality,
    NationalityLong: document.nationality,
    PersonalNumber: document.personalNumber,
    DocumentImage: document.documentImage,
    PortraitImage: document.portraitImage,
    ResultCode: document.resultCode,
    ResultDescription: document.resultDescription,
    Images: []
  };
}
