import React from 'react';
// @material-ui/core components
import Button, { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    MuiButtonOutlined: {
        borderRadius: 10,
        width: '280px',
        height: '48px',
        textTransform: 'none'
      }
  })
);

interface IProps extends ButtonProps {}

export const CustomButton: React.FC<IProps> = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Button color='primary' variant="contained" className={classes.MuiButtonOutlined + ' ' + className} {...rest}>
      {children}
    </Button>
  );
};
