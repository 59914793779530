import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/state';

//Component
import { GridContainer, GridItem, CustomButton } from '../../components';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

//Helper
import { getVerbiage, isValidateEmail } from '../../utils/stringHelper';
import { getReservationWithEmail, updateReservationState } from 'reducers/reservation';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ARIA_RESERVATION_NOT_FOUND, ARIA_SuccessCode } from 'models';
import { getReservationAPI, getReservationByGuidEAPI } from 'services/reservationService';
import { ReservationStatusList } from 'models/backend/constant/Reservation';
import { postErrorLog } from 'services/errorLogService';

//Mock

interface IProps {
  guid: string | null;
  onCompleted: (isCompleted: boolean) => void;
}

export const SearchReservation: React.FC<IProps> = ({ guid, onCompleted }) => {
  const dispatch = useDispatch();

  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { reservations, selectedReservation, reservationServiceError } = useSelector(
    (state: RootState) => state.reservationState
  );

  const [message, setMessage] = React.useState('');

  const [searchName, setSearchName] = React.useState('');
  const [isSearchNameHelptext, setSearchNameHelptext] = React.useState('');

  const [searchConfirmationNoHelptext, setSearchConfirmationNoHelptext] = React.useState('');
  const [searchConfirmationNo, setSearchConfirmationNo] = React.useState('');

  const [searchEmail, setSearchEmail] = React.useState('');
  const [searchEmailHelptext, setSearchEmailHelptext] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (reservations.length == 1 && reservations.length > 0 && selectedReservation != undefined) {
      setIsLoading(true);
      // if (selectedReservation != undefined && selectedReservation.isCheckedIn) {
      //   //The reservation has already been checked In
      //   setMessage(`${getVerbiage(verbiages, 'webmsg007')}`);
      // } else {
      onCompleted(true);
      // }
    }
  }, [reservations, selectedReservation]);

  React.useEffect(() => {
    const searchReservation = async () => {
      if (selectedHotel != undefined) {
        if (reservations.length == 0) {
          if (guid !== null && guid != '') {
            console.log('searchReservation');
            setIsLoading(true);

            getReservationByGuidEAPI(guid)
              .then((response) => {
                if (
                  response.resultCode === ARIA_SuccessCode &&
                  response.result.length === 1 &&
                  (response.result[0].reservationStatus == ReservationStatusList[0].value ||
                    response.result[0].reservationStatus == ReservationStatusList[1].value ||
                    response.result[0].reservationStatus == ReservationStatusList[5].value ||
                    response.result[0].reservationStatus == ReservationStatusList[6].value)
                ) {
                  dispatch(updateReservationState(response.result));
                  onCompleted(true);
                } else {
                  if (response.resultCode === ARIA_RESERVATION_NOT_FOUND) {
                    setMessage(`${getVerbiage(verbiages, 'webmsg006')}`);
                  } else {
                    setMessage(`${getVerbiage(verbiages, 'webmsg014')}`);
                    // setMessage('Oposs... Something when wrong, please try again.');
                  }

                  setIsLoading(false);
                }
              })
              .catch((err) => {
                setMessage(`${getVerbiage(verbiages, 'webmsg014')}`);
                postErrorLog({
                  errorMessage: JSON.stringify(err)
                })
                // alert('Oposs... Something when wrong, please try again.');
                setIsLoading(false);
              });
          }
        }
      }
    };

    searchReservation();
  }, []);

  React.useEffect(() => {
    if (reservationServiceError != undefined) {
      if (reservationServiceError.resultCode === ARIA_RESERVATION_NOT_FOUND) {
        //reservation not found
        setMessage(`${getVerbiage(verbiages, 'webmsg006')}`);
      }
    }
  }, [reservationServiceError]);

  const handleSearchReservation = async () => {
    if (
      (searchName != '' && searchConfirmationNo != '') ||
      (searchName != '' && searchEmail != '' && isValidateEmail(searchEmail))
    ) {
      if (selectedHotel !== undefined) {
        if (searchEmail != '') {
          //TODO later!!!
          dispatch(getReservationWithEmail({ email: searchEmail, lastName: searchName }));
        } else {
          setIsLoading(true);

          getReservationAPI({
            confirmationNo: searchConfirmationNo,
            lastName: searchName,
            isCheckOut: false,
            isSaving: true
          })
            .then((response) => {
              if (
                response.resultCode === ARIA_SuccessCode &&
                response.result.length === 1 &&
                (response.result[0].reservationStatus == ReservationStatusList[0].value ||
                  response.result[0].reservationStatus == ReservationStatusList[1].value ||
                  response.result[0].reservationStatus == ReservationStatusList[5].value ||
                  response.result[0].reservationStatus == ReservationStatusList[6].value)
              ) {
                dispatch(updateReservationState(response.result));
                onCompleted(true);
              } else {
                if (response.resultCode === ARIA_RESERVATION_NOT_FOUND) {
                  setMessage(`${getVerbiage(verbiages, 'webmsg006')}`);
                } else {
                  setMessage(`${getVerbiage(verbiages, 'webmsg014')}`);
                  // setMessage('Oposs... Something when wrong, please try again.');
                }

                setIsLoading(false);
              }
            })
            .catch((err) => {
              setMessage(`${getVerbiage(verbiages, 'webmsg014')}`);
              postErrorLog({
                errorMessage: JSON.stringify(err)
              })
              // setMessage('Oposs... Something when wrong, please try again.');
              setIsLoading(false);
            });

          // await dispatch(
          //   getCheckInReservationWithConfirmationId({
          //     hotelId: selectedHotel.id,
          //     confirmationNo: searchConfirmationNo,
          //     lastName: searchName
          //   })
          // );
        }
      }
    } else {
      if (searchEmail == '') {
        setSearchEmailHelptext(`${getVerbiage(verbiages, 'webmsg003')}`);
        if (searchName == '') setSearchNameHelptext(`${getVerbiage(verbiages, 'webmsg001')}`);
        if (searchConfirmationNo == '') setSearchConfirmationNoHelptext(`${getVerbiage(verbiages, 'webmsg002')}`);
      } else if (!isValidateEmail(searchEmail)) {
        setSearchEmailHelptext(`${getVerbiage(verbiages, 'webmsg004')}`);
      } else {
        if (searchName == '') setSearchNameHelptext(`${getVerbiage(verbiages, 'webmsg001')}`);
        if (searchConfirmationNo == '') setSearchConfirmationNoHelptext(`${getVerbiage(verbiages, 'webmsg002')}`);
      }

      setMessage(`${getVerbiage(verbiages, 'webmsg005')}`);
    }
  };

  const handleClose = () => {
    setMessage('');
  };

  return (
    <GridContainer
      spacing={2}
      direction="column"
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      style={{ minHeight: '50vh', paddingTop: '18px', marginBottom: '96px'}}
    >
      {isLoading ? (
        <GridItem>
          <CircularProgress />
        </GridItem>
      ) : (
        <>
          <GridItem xs={12}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={message !== ''}
              onClose={handleClose}
              autoHideDuration={5000}
              message={message}
              action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            />
          </GridItem>

          <GridItem xs={12}>
            <Typography color="textPrimary" align="center" variant="body1">
              {`${getVerbiage(verbiages, 'weblbl081')}`}
            </Typography>
          </GridItem>

          {/* <GridItem xs={12}>
            <Typography color="textPrimary" align="center" variant="h6">
              {`${getVerbiage(verbiages, 'weblbl002')}`}
            </Typography>
          </GridItem> */}
          <GridItem xs={12}>
            <form noValidate autoComplete="off">
              <TextField
                required
                disabled={isLoading}
                error={isSearchNameHelptext != ''}
                helperText={isSearchNameHelptext}
                style={{ width: '90vw' }}
                id="outlined-required"
                label={`${getVerbiage(verbiages, 'weblbl002')}`}
                variant="outlined"
                value={searchName}
                onKeyDown={(e) => {
                  if (e.keyCode == 13) {
                    handleSearchReservation();
                  }
                }}
                onChange={(e) => {
                  if (e.currentTarget.value != '') {
                    setSearchNameHelptext('');
                  }

                  setSearchName(e.currentTarget.value);
                }}
              />
            </form>
          </GridItem>

          {/* <GridItem xs={12}>
            <Typography color="textPrimary" align="center" variant="h6">
              {`${getVerbiage(verbiages, 'weblbl003')}`}
            </Typography>
          </GridItem> */}
          <GridItem xs={12}>
            <form noValidate autoComplete="off">
              <TextField
                required
                disabled={isLoading}
                error={searchConfirmationNoHelptext != ''}
                helperText={searchConfirmationNoHelptext}
                style={{ width: '90vw' }}
                id="outlined-required"
                label={`${getVerbiage(verbiages, 'weblbl003')}`}
                variant="outlined"
                value={searchConfirmationNo}
                onKeyDown={(e) => {
                  if (e.keyCode == 13) {
                    handleSearchReservation();
                  }
                }}
                onChange={(e) => {
                  if (e.currentTarget.value != '') {
                    setSearchConfirmationNoHelptext('');
                  }

                  setSearchConfirmationNo(e.currentTarget.value);
                }}
              />
            </form>
          </GridItem>

          <GridItem xs={12} hidden>
            <Typography color="textPrimary" align="center" variant="h6">
              <b>{`${getVerbiage(verbiages, 'weblbl005')}`}</b>
            </Typography>
          </GridItem>
          <GridItem xs={12} hidden>
            <form noValidate autoComplete="off">
              <TextField
                required
                disabled={isLoading}
                error={searchEmailHelptext != ''}
                helperText={searchEmailHelptext}
                style={{ width: '90vw' }}
                id="outlined-required"
                label={`${getVerbiage(verbiages, 'weblbl004')}`}
                variant="outlined"
                value={searchEmail}
                onKeyDown={(e) => {
                  if (e.keyCode == 13) {
                    handleSearchReservation();
                  }
                }}
                onChange={(e) => {
                  if (e.currentTarget.value != '') {
                    setSearchEmailHelptext('');
                  }

                  setSearchEmail(e.currentTarget.value);
                }}
              />
            </form>
          </GridItem>
          <GridItem xs={12} style={{ marginTop: '32px' }}>
            {isLoading ? (
              <CustomButton color="primary" onClick={handleSearchReservation}>
                {<CircularProgress color="inherit" />}
              </CustomButton>
            ) : (
              <CustomButton color="primary" onClick={handleSearchReservation}>{`${getVerbiage(
                verbiages,
                'webbtn003'
              )}`}</CustomButton>
            )}
          </GridItem>
        </>
      )}
    </GridContainer>
  );
};
