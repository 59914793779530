import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/state';

//Component
import { DynamicTypography, GridContainer, GridItem } from '../../components';
import Typography from '@material-ui/core/Typography';

//Helper
import { getVerbiage } from '../../utils/stringHelper';
import { selectReservation } from 'reducers/reservation';

interface IProps {}

export const CheckoutComplete: React.FC<IProps> = ({}) => {
  const dispatch = useDispatch();

  const { verbiages } = useSelector((state: RootState) => state.verbiages);

  React.useEffect(() => {
    const resetReduxState = async () => {
      console.log('resetReduxState');
      dispatch(selectReservation(null));
    };

    resetReduxState();
  }, []);

  return (
    <GridContainer spacing={1} alignItems="center" alignContent="center" direction="row" style={{ minHeight: '60vh' }}>
      <GridItem xs={12}>
        <Typography color="textPrimary" align="center" variant="h6">
          {`${getVerbiage(verbiages, 'weblbl077')}`}
        </Typography>
      </GridItem>

      <GridItem xs={12}>
        <DynamicTypography
          color="textPrimary"
          align="center"
          content={`${getVerbiage(
            verbiages,
            'weblbl084'
          )}`}
        ></DynamicTypography>
      </GridItem>
    </GridContainer>
  );
};
