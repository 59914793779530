import React from 'react';
import { useSelector } from 'react-redux';

//Component
import { DynamicTypography, GridContainer, GridItem, PDFScribble } from '../../components';

//Helper
import { RootState } from 'reducers';
import TextField from '@material-ui/core/TextField';
import { getVerbiage, isValidateEmail } from 'utils/stringHelper';
import Typography from '@material-ui/core/Typography';

interface IProps {
  onEmailChange: (emailAddress: string) => void;
}

export const BillingSignature: React.FC<IProps> = ({ onEmailChange }) => {
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedReservation } = useSelector((state: RootState) => state.reservationState);

  // const [base64Pdf] = React.useState(selectedReservation?.attachments[0].contentString);
  // const [file] = React.useState(`data:application/pdf;base64,${base64Pdf}`);

  const [email, setEmail] = React.useState('');
  const [emailHelptext, setEmailHelptext] = React.useState('');

  React.useEffect(() => {
    const initScreen = () => {
      if (
        selectedReservation !== undefined &&
        selectedReservation.guest.emails != undefined &&
        selectedReservation.guest.emails.length > 0
      ) {
        const email = selectedReservation.guest.emails.filter((email) => email.isPrimary);
        if (email.length > 0 && email[0].email != undefined) {
          setEmail(email[0].email);
          onEmailChange(email[0].email);
        }
      }
    };

    initScreen();
  }, [selectedReservation]);

  return (
    <>
      <GridContainer
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
        style={{ minHeight: '60vh', marginTop: '32px' }}
      >
        {selectedReservation?.totalPrice !== 0 ? (
          <>
            <GridItem>
              <Typography color="textPrimary" align="center" variant="subtitle2">
                {/* Your reservation has and outstanding balance of  */}
                {`${getVerbiage(verbiages, 'weblbl072')}`}
              </Typography>
            </GridItem>

            <GridItem>
              <Typography color="textPrimary" align="center" variant="h6">
                {`${selectedReservation?.currency} ${selectedReservation?.totalPrice}`}
              </Typography>
            </GridItem>
          </>
        ) : null}

        {selectedReservation?.attachments != undefined && selectedReservation.attachments.length > 0
          ? selectedReservation.attachments.map((file, index) => {

              if(file.contentString == null || file.contentString == "") {
                return null
              }

              if (index == 0 || (index > 0 && selectedReservation.isPrintRate)) {
                return (
                  <GridItem key={index}>
                    <PDFScribble file={`data:application/pdf;base64,${file.contentString}`} />
                  </GridItem>
                );
              } else {
                return null;
              }
            })
          : null}

        <GridItem>
          <Typography color="textPrimary" align="center" variant="body2">
            {`${getVerbiage(verbiages, 'weblbl073')}`}
          </Typography>
        </GridItem>
        <GridItem>
          <TextField
            required
            error={emailHelptext != ''}
            helperText={emailHelptext}
            style={{ width: '80vw' }}
            id="outlined-required"
            label={`${getVerbiage(verbiages, 'weblbl004')}`}
            variant="outlined"
            value={email}
            onChange={(e) => {
              if (e.currentTarget.value == '') {
                setEmailHelptext('');
              } else if (!isValidateEmail(e.currentTarget.value)) {
                setEmailHelptext(getVerbiage(verbiages, 'webmsg004'));
              } else {
                setEmailHelptext('');
              }
              setEmail(e.currentTarget.value);
              onEmailChange(e.currentTarget.value);
            }}
          />
        </GridItem>

        <GridItem style={{ marginLeft: '12px', marginRight: '12px' }}>
          {/* By proceeding, I hereby confirm that I have reviewed and verified the invoice details. */}
          <DynamicTypography
            color="textPrimary"
            align="center"
            variant="body2"
            content={`${getVerbiage(verbiages, 'weblbl074')}`}
          ></DynamicTypography>
        </GridItem>
      </GridContainer>
    </>
  );
};
