import axios from 'axios';
import { docscanHttpClient } from '../utils/httpClient';
import { ScanDocument } from '../models';

export async function postDocumentForOCR (image: String) : Promise<ScanDocument> {
  const { data: tokenResponse } = await docscanHttpClient.post('/api/MobileDocScanning/client-register');

  const instance = axios.create({
    //More setting can be found here https://github.com/axios/axios
    baseURL: 'https://docscan.assimilated.com.sg',
    // timeout: 50000,
    headers: {
      'Content-type': 'application/json',
      // Authorization: `Bearer ${tokenResponse.accessToken}`,
      'AccessToken': `${tokenResponse.AccessToken}`
    }
  });

  const response = await instance.post('/api/MobileDocScanning/scan', {
    image: image
  });

  const { data } = response;
  return data as ScanDocument;
};
