import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store/state';
import { ResponseModel } from '../models/common/ResponseModel';
import { AxiosError } from 'axios';
import { ARIA_SuccessCode, GetHotelConfigResponseModel } from 'models';
import { getHotelDetailsByOrigin } from 'services/hotelService';

const initialState: RootState.HotelState = {
  selectedHotel: undefined,
  error: undefined,
  isLoading: false
};

//Actions
//More info here https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchHotelByOrigin = createAsyncThunk<GetHotelConfigResponseModel, void, { rejectValue: AxiosError<Error> }>(
  'hotel/getHotelConfig',
  async (_, thunkApi) => {
    try {
      let data: GetHotelConfigResponseModel = await getHotelDetailsByOrigin();
      return data;
    } catch (err) {
      let error = err as AxiosError<Error>; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return thunkApi.rejectWithValue(error);
    }
  }
);

//Slice
//More info here https://redux-toolkit.js.org/api/createSlice
export const hotelSlice = createSlice({
  name: 'hotel',
  initialState,
  reducers: {
    //local reducer
    selectHotel(state, action) {
      state.selectedHotel = action.payload;
      window.sessionStorage.setItem('key', action.payload.apiKey);
    },
    resetHotelState() {
      window.sessionStorage.setItem('key', '');
      return initialState;
    }
  },
  //services reducers
  extraReducers: (builder) => {
    builder.addCase(fetchHotelByOrigin.fulfilled, (state, { payload }) => {
      if (payload.resultCode === ARIA_SuccessCode) {
        state.selectedHotel = payload.result;
        window.sessionStorage.setItem('key', payload.result.apiKey);

        state.error = undefined;
      } else {
        state.error = { ...payload };
      }

      state.isLoading = false;
    }),
      builder.addCase(fetchHotelByOrigin.pending, (state, _) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchHotelByOrigin.rejected, (state, action) => {
        if (action.payload != undefined) {
          let error: AxiosError<Error> = action.payload;

          const serverError: ResponseModel = {
            resultCode: error?.response?.status.toString() ?? '',
            resultDescription: error?.response?.data.message ?? ''
          };

          state.error = serverError;
          state.isLoading = false;
        }
      });
  }
});

export const { selectHotel, resetHotelState } = hotelSlice.actions;

export default hotelSlice.reducer;
