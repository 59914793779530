import React from 'react';
import { useSelector } from 'react-redux';

// @material-ui/core components
import { Reservation } from 'models';
import { GridItem } from 'components/GridItem';
import { Button, Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import { GridContainer } from 'components/GridContainer';
import { RootState } from 'reducers';
// import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//Helper
import { displayLastFourChar, getVerbiage } from 'utils/stringHelper';
import { hasReservationCompletedVerification } from 'utils/applicationHelper';

interface IProps {
  reservation: Reservation;
  isEditAllow: boolean;
  isDeleteAllow: boolean;
  onCaptureClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onEditClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onDeleteClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const useStyles = makeStyles((theme) => ({
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
}));

export const GuestCard: React.FC<IProps> = ({
  reservation,
  isEditAllow,
  isDeleteAllow,
  onCaptureClick,
  onEditClick,
  onDeleteClick,
  ...rest
}) => {
  const classes = useStyles();
  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { verbiages } = useSelector((state: RootState) => state.verbiages);

  const { id, firstName, lastName, travelDocuments } = reservation.guest;

  return (
    <GridItem xs={12} key={id}>
      <Card elevation={3}>
        <CardContent>
          <GridContainer spacing={1}>
            <GridItem xs={11}>
              <GridContainer spacing={1}>
                {/* Full Name */}
                <GridItem xs={12}>
                  <Typography variant="subtitle1" color="textSecondary">
                    {getVerbiage(verbiages, 'weblbl013')}
                  </Typography>
                  <Typography variant="body1">
                    {firstName != undefined ? lastName + ' ' + firstName : lastName}
                  </Typography>
                </GridItem>
                {/* Document Number */}
                <GridItem xs={12}>
                  <Typography variant="subtitle1" color="textSecondary">
                    {getVerbiage(verbiages, 'weblbl014')}
                  </Typography>
                  <Typography variant="body1">
                    {travelDocuments != undefined && travelDocuments.length > 0 && travelDocuments[0].documentNo
                      ? reservation.isPreRegistered
                        ? displayLastFourChar(travelDocuments[0].documentNo)
                        : travelDocuments[0].documentNo
                      : getVerbiage(verbiages, 'weblbl010')}
                  </Typography>
                </GridItem>
              </GridContainer>
            </GridItem>
            {/* <GridItem xs={2}>
              {isEditAllow ? (
                <Button value={id} onClick={onCaptureClick}>
                  <AssignmentIndIcon />
                </Button>
              ) : null}
              {isEditAllow && hasReservationCompletedVerification(reservation, appConfig!) ? (
                <Button value={id} onClick={onEditClick}>
                  <EditIcon />
                </Button>
              ) : null}
              {isDeleteAllow ? (
                <Button value={id} onClick={onDeleteClick}>
                  <DeleteIcon />
                </Button>
              ) : null}
            </GridItem> */}



            <GridItem xs={1}>
              {hasReservationCompletedVerification(reservation, appConfig!) ? (
                <Typography variant="subtitle2" color="textSecondary" className={classes.subtitle}>
                  <CheckCircleIcon style={{ color: 'green' }} />
                </Typography>
              ) : (
                <Typography variant="subtitle2" color="textSecondary" className={classes.subtitle}>
                  <ErrorIcon style={{ color: 'orange' }} />
                </Typography>
              )}
            </GridItem>

              {/* Scan Document */}
            <GridItem xs={12}>
              {isEditAllow ? (
                <Button variant="contained" color="primary" value={id} onClick={onCaptureClick}>
                  {getVerbiage(verbiages, 'webbtn014')}
                </Button>
              ) : null}
            </GridItem>
            <GridItem xs={12}>
              {isEditAllow && hasReservationCompletedVerification(reservation, appConfig!) ? (
                <Button variant="contained" color="primary" value={id} onClick={onEditClick}>
                  {getVerbiage(verbiages, 'webbtn015')}
                </Button>
              ) : null}
            </GridItem>
          </GridContainer>
        </CardContent>
      </Card>
    </GridItem>
  );
};
