import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles'; //, Theme } from '@material-ui/core/styles';
import { defaultTheme, getThemes } from './themes';
import { CheckIn } from './pages/CheckIn';
import { Checkout } from './pages/Checkout';
import { Main } from './pages/Main';
import { Product } from './pages/Product';
import { Walletpass } from './pages/walletpass';
import { PageNotFound } from './pages/404';
import { RootState } from 'reducers';
import Favicon from "react-favicon";

function App() {
  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const [appTheme, setAppTheme] = React.useState(defaultTheme);

  React.useEffect(() => {
    if (appConfig?.themeConfig != undefined) {
      var serverTheme = getThemes({ ...appConfig.themeConfig });
      setAppTheme(serverTheme);
    }
  }, [appConfig]);

  return (
    <>
      <Favicon url={`${appConfig?.hotelConfig?.faviconUrl}`} />
      <MuiThemeProvider theme={appTheme}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="product" element={<Product />} />
          <Route path=":hotelCode" element={<Main />} />
          <Route path=":hotelCode/checkin" element={<CheckIn />} />
          <Route path=":hotelCode/checkout" element={<Checkout />} />
          <Route path=":hotelCode/walletpass" element={<Walletpass />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </MuiThemeProvider>
    </>
  );
}

export default App;
