import React from 'react';
// @material-ui/core components
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { Interweave } from 'interweave';
import { UrlMatcher, EmailMatcher } from 'interweave-autolink';

interface IProps extends TypographyProps {
  content: string
}

export const DynamicTypography: React.FC<IProps> = ({ content, ...rest }) => {
  // const classes = useStyles();

  return (
    <Typography {...rest}>
      <Interweave
        newWindow
        content={content}
        matchers={[new UrlMatcher('url'), new EmailMatcher('email')]}
      >
      </Interweave>
    </Typography>
  );
};
