import {
  CapturePreAuthRequestModel,
  CapturePreAuthResponseModel,
  CreateCheckoutPaymentSessionRequestModel,
  CreateCheckoutPaymentSessionResponseModel,
  CreatePreAuthPaymentRequestModel,
  CreatePreAuthPaymentResponseModel,
  GetPreAuthInfoRequestModel,
  UpdatePaymentRequestModel,
  UpdatePaymentResponseModel
} from 'models';
import { getApiKeyForHotel, getToken, privateHttpClient } from '../utils/httpClient';

export const createCheckoutPaymentSessionAPI = async (
  request: CreateCheckoutPaymentSessionRequestModel
): Promise<CreateCheckoutPaymentSessionResponseModel> => {
  const { data } = await privateHttpClient.post(
    '/api/Payment/adyen/PreAuth/CheckoutSession',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const createPreAuthPaymentAPI = async (
  request: CreatePreAuthPaymentRequestModel
): Promise<CreatePreAuthPaymentResponseModel> => {
  const { data } = await privateHttpClient.post(
    '/api/Payment/adyen/PreAuth',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const updatePaymentCompletionAPI = async (
  request: UpdatePaymentRequestModel
): Promise<UpdatePaymentResponseModel> => {
  const { data } = await privateHttpClient.post(
    '/api/Payment/adyen/PreAuth/Completion',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};

export const getPreAuthInfoAPI = async (request: GetPreAuthInfoRequestModel) => {
  const { data } = await privateHttpClient.get('/api/Payment/adyen/PreAuth', {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      ApiKey: getApiKeyForHotel()
    },
    params: {
      ...request
    }
  });

  return data;
};

export const capturePreAuthPaymentAPI = async (
  request: CapturePreAuthRequestModel
): Promise<CapturePreAuthResponseModel> => {
  const { data } = await privateHttpClient.post(
    '/api/Payment/adyen/PreAuth/Capture',
    {
      ...request
    },
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        ApiKey: getApiKeyForHotel()
      }
    }
  );

  return data;
};
