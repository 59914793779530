import React from 'react';
// import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RootState } from '../../store/state';

//Reducers
import {
  getHealthDeclarationForGuest,
  reservationPreRegistered,
  resetReservationState,
  selectReservation,
  updateSignedAcknowledgement
} from '../../reducers/reservation';
import { resetPaymentState } from 'reducers/payment';

//Component
import { GridContainer, GridItem, MainContainer, NavBar, Stepper } from '../../components';
import { ButtomNavBarButton } from '../../components/BottomNavBarButton';
import Typography from '@material-ui/core/Typography';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SearchReservation } from './searchReservation';
import { ReservationList } from './reservationList';
import { ReservationDetail } from './reservationDetail';
import { MarketingConsent } from './marketingConsent';
import { ProcessingPayment } from './processingPayment';
import { RegistrationCard } from './registrationCard';
import { SummaryCharges } from './summaryCharges';
import { RegistrationComplete } from './registrationComplete';

//Helper
import { getScreenTitle, getVerbiage } from '../../utils/stringHelper';
import {
  constructPreRegisterReservationModel,
  hasAllReservtionCompletedVerification,
  setupScreenFlow
} from 'utils/applicationHelper';
import {
  ScreenMarketingConsent,
  ScreenReservationDetail,
  ScreenReservationList,
  ScreenReservationPreAuthPayment,
  ScreenReservationPaymentProcessing,
  ScreenReservationRegistration,
  ScreenReservationRoomAssignment,
  ScreenSearchReservation,
  ScreenReservationCheckIn
} from 'utils/constantHelper';
import {  preRegisterAPI } from 'services/reservationService';
import { ARIA_RESERVATION_ALREADY_PREREGISTERED, ARIA_SuccessCode } from 'models';
import { ReservationStatusList } from 'models/backend/constant/Reservation';
import { RequestMobileKey } from './requestMobileKey';
import { postErrorLog } from 'services/errorLogService';

export const CheckIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  const { appConfig } = useSelector((state: RootState) => state.appConfigState);
  const { verbiages } = useSelector((state: RootState) => state.verbiages);
  const { selectedHotel } = useSelector((state: RootState) => state.hotelState);
  const { reservations, selectedReservation, completedMobileKeyIssue } = useSelector((state: RootState) => state.reservationState);
  const [activeStep, setActiveStep] = React.useState(0);

  const [isRegistrationCompleted, setIsRegistrationCompleted] = React.useState(false);
  const [isCreditCardInputValidated, setIsCreditCardInputValidated] = React.useState(false);
  const [submitPay, setSubmitPay] = React.useState(false);

  const [isProcessing, setIsProcessing] = React.useState(false);

  const [completedPayment, setCompletedPayment] = React.useState(false);
  const [errorPayment, setErrorPayment] = React.useState(false);

  const [totalScreenSteps, setTotalScreenSteps] = React.useState(0);
  const [screenFlow, setScreenFlow] = React.useState<string[]>([]);

  const [verifyPin, setVerifyPin] = React.useState(false);

  React.useEffect(() => {
    const updateScreenFlow = () => {
      if (appConfig !== undefined && screenFlow.length == 0) {
        console.log(appConfig.reservationConfig.checkInScreenFlow);
        setScreenFlow(appConfig.reservationConfig.checkInScreenFlow);
        setTotalScreenSteps(appConfig?.reservationConfig.checkInScreenFlow.length);
      }
    };

    updateScreenFlow();
  }, [appConfig]);

  const handleSearchReservationCompleted = async () => {
    console.log('handleSearchReservationCompleted');

    //Setup the screen flow
    if (
      selectedReservation != undefined &&
      selectedHotel != undefined &&
      reservations != undefined &&
      appConfig != undefined
    ) {
      var index = activeStep;

      //Found only 1 reservation.
      if (reservations.length == 1) {
        //Processing payment
        if (!selectedReservation.isPreRegistered && searchParams.get('ref')) {
          //need to look into this later!!!!!!
          index = screenFlow.findIndex((e) => e === ScreenReservationPaymentProcessing);
        } else {
          // Normal Search
          var _screenFlow = setupScreenFlow(
            selectedReservation,
            appConfig.reservationConfig.checkInScreenFlow,
            reservations
          );

          history.replaceState('', '', `?resKey=${selectedReservation.guid}`);

          dispatch(
            getHealthDeclarationForGuest({
              request: {
                mainConfirmationNo: selectedReservation.confirmationNo,
                profileId: selectedReservation.guest.profileId
              }
            })
          );

          setScreenFlow(_screenFlow);
          setTotalScreenSteps(_screenFlow.length);

          var index = _screenFlow.findIndex((e) => e === ScreenReservationDetail);
        }
      }
      // else {
      //   index = _screenFlow.findIndex((e) => e === ScreenReservationList);
      // }

      setActiveStep(index);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const onSelectingReservation = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (reservations != undefined && selectedHotel != undefined && reservations.length > 0) {
      dispatch(selectReservation(reservations[Number.parseInt(event.currentTarget.value)]));

      dispatch(
        getHealthDeclarationForGuest({
          request: {
            mainConfirmationNo: reservations[Number.parseInt(event.currentTarget.value)].confirmationNo,
            profileId: reservations[Number.parseInt(event.currentTarget.value)].guest.profileId
          }
        })
      );
      window.scrollTo(0, 0);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleNext = async () => {
    // console.log('handleNext');

    if (screenFlow[activeStep] === ScreenReservationRegistration) {
      dispatch(updateSignedAcknowledgement());

      //if already collected pre auth or 0 payment pre-register the guest.
      if (
        selectedHotel != undefined &&
        selectedReservation != undefined &&
        (selectedReservation.totalPrice <= 0 || selectedReservation.checkInPreAuthAccepted)
      ) {
        setIsProcessing(true);
        var preRegistrationRequest = constructPreRegisterReservationModel(selectedReservation!!, appConfig!!);

        preRegisterAPI(preRegistrationRequest)
          .then((response) => {
            if (
              response.resultCode == ARIA_SuccessCode ||
              response.resultCode == ARIA_RESERVATION_ALREADY_PREREGISTERED
            ) {
              dispatch(reservationPreRegistered(response.result));

              var index = screenFlow.findIndex((e) => e === ScreenReservationRoomAssignment);

              setActiveStep(index);
              window.scrollTo(0, 0);
            } else {
              alert(getVerbiage(verbiages, 'webmsg014'));
              // alert('Oposs... Something when wrong, please try again.');
            }
            setIsProcessing(false);
          })
          .catch((err) => {
            alert(getVerbiage(verbiages, 'webmsg014'));
            postErrorLog({
              errorMessage: JSON.stringify(err)
            })
            // alert('Oposs... Something when wrong, please try again.');
            setIsProcessing(false);
          });

        // const response = await preRegisterAPI({ hotelId: selectedHotel.id, reservations: _reservations })

        // await dispatch(preRegisterReservation({ request: { hotelId: selectedHotel.id, reservations: _reservations } }));
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo(0, 0);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      window.scrollTo(0, 0);
    }
  };

  const handlePay = () => {
    // console.log('handlePay');
    setSubmitPay(true);
  };

  const handleBack = () => {
    window.scrollTo(0, 0);

    if (selectedHotel != undefined) {
      //If currently on reservation list screen
      if (screenFlow[activeStep] === ScreenReservationList) {
        navigate(`/${selectedHotel.code}/checkin`);
        dispatch(resetReservationState());
        dispatch(resetPaymentState());
      }

      //If currently on reservation detail screen
      if (screenFlow[activeStep] === ScreenReservationDetail) {
        dispatch(selectReservation(undefined));
        dispatch(resetPaymentState());

        if (screenFlow[activeStep - 1] === ScreenSearchReservation) {
          dispatch(resetReservationState());
          navigate(`/${selectedHotel.code}/checkin`);
        }
      }

      //If currently on preAuth Payment screen
      if (screenFlow[activeStep] === ScreenReservationPreAuthPayment) dispatch(resetPaymentState());

      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <MainContainer>
      <NavBar />
      {/* Top part */}
      <GridContainer
        direction="column"
        spacing={1}
        alignItems="center"
        alignContent="center"
        justifyContent="flex-start"
        style={{ height: '90%' }}
      >
        <GridItem xs={12}>
          <LazyLoadImage
            delayMethod="throttle"
            alt="Hotel logo"
            height={96}
            src={appConfig?.hotelConfig.logoUrl}
            width={96}
          />
        </GridItem>
        <GridItem xs={12}>
          <Typography color="textPrimary" align="center" variant="h6">
            <b>{selectedHotel?.name}</b>
          </Typography>
        </GridItem>

        {/* {selectedReservation != undefined ? ( */}
        <GridItem xs={12}>
          <Stepper
            steps={totalScreenSteps}
            activeStep={activeStep}
            title={getScreenTitle(screenFlow[activeStep], verbiages)}
          />
        </GridItem>
        {/* ) : null} */}
      </GridContainer>

      {screenFlow[activeStep] === ScreenSearchReservation ? (
        <SearchReservation
          // confirmationNo={searchParams.get('confirmationNo')}
          // lastName={searchParams.get('lastname')}
          // email={searchParams.get('email')}
          guid={searchParams.get('resKey')}
          onCompleted={handleSearchReservationCompleted}
        />
      ) : null}

      {screenFlow[activeStep] === ScreenReservationList ? <ReservationList onClick={onSelectingReservation} /> : null}
      {screenFlow[activeStep] === ScreenReservationList ? (
        <ButtomNavBarButton
          leftButtonTitle={getVerbiage(verbiages, 'webbtn004')}
          leftButtonOnClick={handleBack}
        ></ButtomNavBarButton>
      ) : null}

      {screenFlow[activeStep] === ScreenReservationDetail ? <ReservationDetail /> : null}
      {screenFlow[activeStep] === ScreenReservationDetail &&
      (selectedReservation?.reservationStatus == ReservationStatusList[0].value ||
        selectedReservation?.reservationStatus == ReservationStatusList[5].value) ? (
        <ButtomNavBarButton
          leftButtonTitle={getVerbiage(verbiages, 'webbtn004')}
          leftButtonOnClick={handleBack}
          rightButtonTitle={getVerbiage(verbiages, 'webbtn005')}
          disableRightButton={!hasAllReservtionCompletedVerification(selectedReservation!, appConfig!)}
          rightButtonOnClick={handleNext}
        ></ButtomNavBarButton>
      ) : null}

      {screenFlow[activeStep] === ScreenMarketingConsent ? (
        <MarketingConsent onCompleted={(isCompleted) => console.log(isCompleted)} />
      ) : null}
      {screenFlow[activeStep] === ScreenMarketingConsent ? (
        <ButtomNavBarButton
          leftButtonTitle={getVerbiage(verbiages, 'webbtn004')}
          leftButtonOnClick={handleBack}
          rightButtonTitle={getVerbiage(verbiages, 'webbtn005')}
          rightButtonOnClick={handleNext}
        ></ButtomNavBarButton>
      ) : null}

      {screenFlow[activeStep] === ScreenReservationRegistration ? (
        <RegistrationCard onCompleted={(isCompleted) => setIsRegistrationCompleted(isCompleted)} />
      ) : null}
      {screenFlow[activeStep] === ScreenReservationRegistration ? (
        <ButtomNavBarButton
          leftButtonTitle={getVerbiage(verbiages, 'webbtn004')}
          disableLeftButton={isProcessing}
          leftButtonOnClick={handleBack}
          rightButtonTitle={getVerbiage(verbiages, 'webbtn005')}
          disableRightButton={!isRegistrationCompleted || isProcessing}
          rightButtonOnClick={handleNext}
        ></ButtomNavBarButton>
      ) : null}

      {screenFlow[activeStep] === ScreenReservationPreAuthPayment ? (
        <SummaryCharges
          submitPay={submitPay}
          onResetPayment={() => setSubmitPay(false)}
          onValidationCompleted={(v) => setIsCreditCardInputValidated(v)}
          onPaymentCompleted={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
        />
      ) : null}
      {screenFlow[activeStep] === ScreenReservationPreAuthPayment ? (
        <ButtomNavBarButton
          disableLeftButton={submitPay}
          leftButtonTitle={getVerbiage(verbiages, 'webbtn004')}
          leftButtonOnClick={handleBack}
          disableRightButton={!isCreditCardInputValidated || submitPay}
          rightButtonTitle={getVerbiage(verbiages, 'webbtn006')}
          rightButtonOnClick={handlePay}
        ></ButtomNavBarButton>
      ) : null}

      {screenFlow[activeStep] === ScreenReservationPaymentProcessing ? (
        <ProcessingPayment onCompleted={(c) => setCompletedPayment(c)} onError={(e) => setErrorPayment(e)} />
      ) : null}
      {screenFlow[activeStep] === ScreenReservationPaymentProcessing && completedPayment ? (
        <ButtomNavBarButton
          rightButtonTitle={getVerbiage(verbiages, 'webbtn005')}
          disableRightButton={!completedPayment}
          rightButtonOnClick={handleNext}
        ></ButtomNavBarButton>
      ) : screenFlow[activeStep] === ScreenReservationPaymentProcessing && errorPayment ? (
        <ButtomNavBarButton
          leftButtonTitle={getVerbiage(verbiages, 'webbtn004')}
          disableLeftButton={!errorPayment}
          leftButtonOnClick={handleBack}
        ></ButtomNavBarButton>
      ) : null}

      {screenFlow[activeStep] === ScreenReservationRoomAssignment ? (
        <RegistrationComplete onRequestMobileKey={handleNext} />
      ) : null}

      {/* {screenFlow[activeStep] === ScreenReservationRoomAssignment && getApplicationMode() == MobileApplicationMode ?  (
        <ButtomNavBarButton
          disableLeftButton={submitPay}
          leftButtonTitle={getVerbiage(verbiages, 'webbtn004')}
          leftButtonOnClick={handleBack}
          disableRightButton={!isCreditCardInputValidated || submitPay}
          rightButtonTitle={getVerbiage(verbiages, 'weblbl086')}
          rightButtonOnClick={handlePay}
        ></ButtomNavBarButton>
      ) : null} */}

      {screenFlow[activeStep] === ScreenReservationCheckIn ? (
        <RequestMobileKey verifyPin={verifyPin} />
      ) : null}
      {screenFlow[activeStep] === ScreenReservationCheckIn  && !completedMobileKeyIssue ? (
        <ButtomNavBarButton
          leftButtonTitle={getVerbiage(verbiages, 'webbtn004')}
          leftButtonOnClick={handleBack}
          disableLeftButton={verifyPin}
          rightButtonTitle={getVerbiage(verbiages, 'webbtn018')}
          rightButtonOnClick={() => setVerifyPin(true)}
          disableRightButton={verifyPin}
        ></ButtomNavBarButton>
      ) : null}
    </MainContainer>
  );
};
