import {
    CreateDigitalKeyRequestModel,
    CreateDigitalKeyResponse,
  } from 'models';
  import { getApiKeyForHotel, getToken, privateHttpClient } from 'utils/httpClient';
  
  export const createDigitalKeyAPI = async (request: CreateDigitalKeyRequestModel): Promise<CreateDigitalKeyResponse> => {
    const { data } = await privateHttpClient.post(
      '/api/DigitalKey',
      { ...request },
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
          ApiKey: getApiKeyForHotel()
        }
      }
    );
  
    return data;
  };