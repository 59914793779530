import { getToken, privateHttpClient } from '../utils/httpClient';

export const getHotelDetailsByOrigin = async () => {
  const { data } = await privateHttpClient.get('/api/hotel/config', {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      'Uri': window.location.hostname
    },
  });

  return data;
};
