import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/state';

//Component
import { GridContainer, GridItem, ReservationCardList } from '../../components';
// import Typography from '@material-ui/core/Typography';

//Helper
// import { getVerbiage } from '../../utils/stringHelper';

interface IProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const ReservationList: React.FC<IProps> = ({ onClick }) => {
  // const classes = useStyles();
  const { reservations } = useSelector((state: RootState) => state.reservationState);
  // const { verbiages } = useSelector((state: RootState) => state.verbiages);

  return (
    <>
      {reservations !== null ? (
        <GridContainer
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="flex-start"
          style={{ marginTop: '18px' }}
        >
          <GridItem xs={12}>
            {/* <Typography color="textPrimary" align="center" variant="h5">
              {getVerbiage(verbiages, 'lbl007')}
            </Typography> */}
          </GridItem>
          <GridItem>
            <ReservationCardList onClick={onClick}/>
          </GridItem>
        </GridContainer>
      ) : null}
    </>
  );
};
